import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useWallet } from "@solana/wallet-adapter-react";
import ReactDatePicker from "react-datepicker";
import { FiRefreshCcw } from "react-icons/fi";
import { IoCalendar } from "react-icons/io5";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FadeLoader } from 'react-spinners';
import MyOfferTable from "../components/MyOfferTable";
import MyOfferTableMobile from "../components/MyOfferTableMobile";
import Navbar from "../components/Navbar";
import SelectComponent from "../components/SelectComponent";
import { CRYPTO_FILTER_VALUES, STATUS_VALUES } from "../constants/offers";
import { ETH_CRYPTO_FILTER_VALUES } from "../constants/eth/offers";
import useMyOffer from "../utils/myoffers";
import useEthMyOffer from "../utils/eth/myoffers";
import { AppContext } from "../utils/AppContext";

const MyOfferListWrapper = () => {
    const { chainID, chainState } = useContext(AppContext);
    const { isLoading, myOffers, refresh, setRefresh, discontinueOffer } = useMyOffer();
    const { ethIsLoading, myEthOffers, ethRefresh, setEthRefresh, discontinueEthOffer} = useEthMyOffer();
    const wallet  = useWallet();

    return (
        <MyOfferList 
            walletAddress={!chainID ? wallet.publicKey : chainState.address}
            cryptoFilterValues={!chainID ? CRYPTO_FILTER_VALUES : ETH_CRYPTO_FILTER_VALUES[chainID]}
            discontinueOffer={!chainID ? discontinueOffer : discontinueEthOffer}
            isLoading={!chainID ? isLoading : ethIsLoading}
            myOffers={!chainID ? myOffers : myEthOffers}
            refresh={!chainID ? refresh : ethRefresh}
            setRefresh={!chainID ? setRefresh : setEthRefresh} />
    )
};

const MyOfferList = ({cryptoFilterValues, isLoading, myOffers, refresh, setRefresh, walletAddress, discontinueOffer}) => {
    const date = new Date();
    const { t } = useTranslation();
    const yesterday = date.setDate(date.getDate() - 30);
    const [selectedCryptoIndex, setSelectedCryptoIndex] = useState(0);
    const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);
    const [myOfferList, setOfferList] = useState([]);
    const [pageRange, setPageRage] = useState(0);
    const [startDate, setStartDate] = useState(yesterday);
    const [endDate, setEndDate] = useState(new Date());
    const [pageNum, setPageNum] = useState(0);

    const handlePage = (e) => {
        setPageNum(e.selected);
    }

    const handleRefresh = () => {
        setRefresh(true);
    }

    useEffect(() => {
        setOfferList(myOffers);
        const newPageRange = Math.floor(myOffers.length / 10) + 1;
        setPageRage(newPageRange);
    }, [myOffers]);

    useEffect(() => {
        let filterOffers = [...myOffers];
        if (selectedCryptoIndex !== 0) {
            if (selectedCryptoIndex === 1) {
                filterOffers = filterOffers.filter(item => item.main);
            } else {
                filterOffers = filterOffers.filter(item => (item.token.toString() === cryptoFilterValues[selectedCryptoIndex].value) && !item.main);
            }
        }
        if (selectedStatusIndex !== 0) {
            filterOffers = filterOffers.filter(item => item.status === STATUS_VALUES[selectedStatusIndex].value);
        }
        const startUnixDate = Math.floor(new Date(startDate) / 1000);
        const endUnixDate = Math.floor(new Date(endDate) / 1000);
        filterOffers = filterOffers.filter(item => item.unixTime > startUnixDate);
        filterOffers = filterOffers.filter(item => item.unixTime < endUnixDate);
        filterOffers = filterOffers.filter((_, index) => index >= 10 * pageNum && index < (pageNum + 1) * 10);
        setOfferList(filterOffers);
    }, [selectedCryptoIndex, selectedStatusIndex, myOffers, pageNum, startDate, endDate])

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 text-white">
                <div className="text-2xl font-bold mt-12 mb-2 text-left">{t("My Offers")}</div>
                <div className="text-sm text-second-theme mb-10 text-left">{t("My created offers")}</div>
                {/* <div className="flex border-b-2 border-first-theme text-sm mb-10">
                    <div className="border-b-2 green-border pb-2 mr-4">Active offer</div>
                    <div className="">Discontinued offer</div>
                </div> */}
                <div className="flex justify-between mb-16">
                    <div className="flex flex-wrap">
                        <SelectComponent title={t("Filter by Crypto")} values={cryptoFilterValues} value={selectedCryptoIndex} onChange={setSelectedCryptoIndex} search />
                        <SelectComponent title={t("Status")} values={STATUS_VALUES} value={selectedStatusIndex} onChange={setSelectedStatusIndex} />
                        <div className="flex flex-col">
                            <div className="text-sm text-second-theme mb-2 text-left">{t("Date Range")}</div>
                            <div className="px-4 py-2 bg-first-theme rounded-lg flex items-center justify-between">
                                <div className="flex items-center w-32">
                                    <IoCalendar className="text-second-theme mr-2" />
                                    <ReactDatePicker 
                                        className="bg-transparent w-full" 
                                        dateFormat="dd-MM-yyyy" 
                                        selected={startDate} 
                                        onChange={(date) => setStartDate(date)} />
                                </div>
                                <span className="px-2 text-second-theme">-</span>
                                <div className="flex items-center w-32">
                                    <IoCalendar className="text-second-theme mr-2" />
                                    <ReactDatePicker 
                                        className="bg-transparent w-full" 
                                        dateFormat="dd-MM-yyyy" 
                                        selected={endDate} 
                                        minDate={startDate}
                                        onChange={(date) => setEndDate(date)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col cursor-pointer" onClick={handleRefresh}>
                        <div className="text-sm text-second-theme mb-2 text-left">{t("Refresh")}</div>
                        <div className="px-4 py-2 border-2 border-first-theme rounded-lg flex items-center justify-between">
                            <FiRefreshCcw className={`${refresh && "animate-spin"}`} size={24} />
                        </div>
                    </div>
                </div>
                {
                    isLoading   
                        ? <div className="w-full flex justify-center">
                            <FadeLoader className="" loading color='white' />
                        </div>
                        : walletAddress
                            ? <>
                                <div className="hidden lg:block">
                                    <MyOfferTable isLoading={isLoading} datas={myOfferList} setOfferList={setOfferList} discontinueOffer={discontinueOffer} />
                                </div>
                                <div className="block lg:hidden">
                                    <MyOfferTableMobile datas={myOfferList} isLoading={isLoading} setOfferList={setOfferList} discontinueOffer={discontinueOffer} />
                                </div>
                            </>   
                            : <div className="py-16 border-2 border-first-theme rounded-lg text-2xl">
                                {t("Please connect your wallet")}
                            </div>
                }
                {
                    myOfferList.length > 0 &&
                        <div className="pagination mb-8">
                            <ReactPaginate 
                                breakLabel="..."
                                nextLabel={<AiOutlineRight size={24} />}
                                onPageChange={handlePage}
                                pageRangeDisplayed={3}
                                pageCount={pageRange}
                                previousLabel={<AiOutlineLeft size={24} />}
                                renderOnZeroPageCount={null} />
                        </div>
                }
            </div>
        </>
    );
}

export default MyOfferListWrapper;