import { useTranslation } from "react-i18next";
import { MdThumbUp, MdThumbDown } from "react-icons/md";
import { BsChevronCompactRight } from "react-icons/bs";
import { AiOutlineArrowLeft, AiFillCheckCircle } from "react-icons/ai";
import { PAYMENT_OFFER_VALUES } from "../../../constants/offers";

const CompleteContainer = (props) => {
    const { orderData, handleThumb } = props;
    const { t } = useTranslation();

    return (
        <>
            <div className="flex flex-col mb-10">
                <div className="flex justify-between">
                    <a href="/orders" className="text-2xl flex items-center mb-2"><AiOutlineArrowLeft className="mr-4"/>{t("Order")} {orderData.id}</a>
                    <div className="p-2 rounded-lg bg-first-theme green-font flex items-center"><AiFillCheckCircle className="mr-2" />{t("Completed")}</div>
                </div>
                <div className="flex justify-between">
                    <div className="text-sm text-second-theme">{t("The order has been marked as completed")}</div>
                    <div className="text-sm text-second-theme"></div>
                </div>
            </div>
            <div className="flex items-center mb-8 md:mb-16 text-second-theme bg-first-theme rounded-tr-lg rounded-tl-lg">
                <div className="flex-1 border-b-2 border-gray-500 py-2">{t("Waiting for payment")}</div>
                <BsChevronCompactRight size={24} />
                <div className="flex-1 border-b-2 border-gray-500 py-2">{t("Release crypto")}</div>
                <BsChevronCompactRight size={24} />
                <div className="flex-1 border-b-2 green-border py-2 text-white">{t("Completed")}</div>
            </div>
            <div className="mb-8">
                <div className="text-left mb-4 md:mb-8">{t("Trade Information")}</div>
                <div className="flex flex-wrap justify-between text-left py-4 md:py-8 border-t-2 border-b-2 border-first-theme mb-8">
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Amount to receive")}</div>
                        <div className="">{orderData.fiatAmount.toLocaleString("en-US")}</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Rate")}</div>
                        <div className="">{orderData.rate.toLocaleString("en-US")} {orderData.fiat.toUpperCase()}</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Transaction fee %")}</div>
                        <div className="">{orderData.fee}%</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Transaction fee amount")}</div>
                        <div className="">{orderData.moneyFee} {orderData.tokenName.toUpperCase()}</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Crypto to send")}</div>
                        <div className="">{orderData.sellAmount.toLocaleString("en-US")} {orderData.tokenName.toUpperCase()}</div>
                    </div>
                </div>
            </div>
            <div className="text-left">
                <div className="mb-2">{t("Your Payment Information")}</div>
                <div className="text-sm text-second-theme mb-4">{t("This is your selected payment method")}</div>
                <div className="p-4 rounded-lg bg-first-theme flex flex-wrap mb-4">
                    <div className="text-sm w-6/12 py-2">
                        <div className="text-second-theme mb-2">Seller's {PAYMENT_OFFER_VALUES[orderData.paymentOption].title} Email</div>
                        <div className="">{orderData.emailAddress}</div>
                    </div>
                    <div className="text-sm w-6/12 py-2">
                        <div className="text-second-theme mb-2">{t("Account Name")}</div>
                        <div className="">{orderData.accountName}</div>
                    </div>
                    <div className="text-sm w-6/12 py-2">
                        <div className="text-second-theme mb-2">{t("Reference Message")}</div>
                        <div className="">{orderData.fullId}</div>
                    </div>
                </div>
                {
                    !orderData.feedback &&
                        <>
                            <div className="mb-2">{t("Rate Buyer")}</div>
                            <div className="text-sm text-second-theme mb-4">{t("How was your experience trading with this buyer?")}</div>
                            <div className="flex mb-8">
                                <button 
                                    className={`px-8 md:px-12 py-4 bg-black cursor-pointer rounded-xl border-2 border-first-theme text-second-theme flex items-center mr-4`}
                                    onClick={() => handleThumb(true)}
                                    >
                                    <MdThumbUp className="mr-2"/>
                                    {t("Positive")}
                                </button>
                                <button 
                                    className={`px-8 md:px-12 py-4 bg-black cursor-pointer rounded-xl border-2 border-first-theme text-second-theme flex items-center`}
                                    onClick={() => handleThumb(false)}
                                >
                                    <MdThumbDown className="mr-2"/>
                                    {t("Negative")}
                                </button>
                            </div>
                        </>
                }
            </div>
        </>
    );
}

export default CompleteContainer;