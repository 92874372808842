import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import { RiThumbUpLine, RiThumbDownLine } from "react-icons/ri";
import { HiBadgeCheck } from "react-icons/hi";
import { FadeLoader } from 'react-spinners';
import GradButton from "./GradButton";
import { PAYMENT_OFFER_VALUES } from "../constants/offers";

const OfferTable = (props) => {
    const { datas, isLoading, walletAddress, chainID } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = (offerIndex) => {
        navigate(`/offer/seller/${offerIndex}`);
    }

    return (
        <div className="border-2 rounded-lg border-first-theme mb-16">
            <table className="w-full text-left ">
                <thead >
                    <tr className="font-thin text-sm text-second-theme">
                        <th className="border-b-2 rounded-t-lg border-first-theme py-2 pl-8">{t("Venders")}</th>
                        <th className="border-b-2 border-first-theme py-2">{t("Rate")}</th>
                        <th className="border-b-2 border-first-theme py-2">{t("Payment Method")}</th>
                        <th className="border-b-2 border-first-theme py-2">{t("Limit / Available")}</th>
                        <th className="border-b-2 border-first-theme py-2 pr-8">{t("Trade")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading
                            ? <tr><td colSpan="5"><div className="p-4 w-full flex items-center justify-center">
                                <FadeLoader className="" loading color='white' />
                            </div></td></tr>
                            : datas.length === 0
                                ? <tr><td colSpan="5"><div className="p-4 w-full flex items-center justify-center">
                                    {t("There is no data.")}
                                </div></td></tr>
                                : datas.map((data, index) =>
                                <tr key={index}>
                                    <td className={`pl-8 py-4  ${index !== (datas.length - 1) && "border-b-2 border-first-theme"}`}>
                                        <div className="flex flex-col ">
                                            <div className="mb-2 flex items-center">
                                                {data.owner.toString().slice(0, 4) + "..." + data.owner.toString().substring(data.owner.toString().length - 4)} 
                                                {
                                                    data.verified && <HiBadgeCheck color="#68CEC7" className="ml-2" />
                                                }
                                            </div>
                                            <div className="mb-2 flex">
                                                <div className="flex items-center mr-4"><RiThumbUpLine color="#68CEC7" className="mr-2" />{data.thumbsUp}</div>
                                                <div className="flex items-center"><RiThumbDownLine color="#E03B3B" className="mr-2" />{data.thumbsDown}</div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className={`w-2 h-2 rounded-full ${data.onlineStatus ? "green-bg" : "bg-red-500"} mr-2`}></div>
                                                <div className="text-xs">{t(`Seen ${data.lastSeen} ago`)}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`${index !== (datas.length - 1) && "border-b-2 border-first-theme"}`}>
                                        {data.rate.toLocaleString("en-US")} {data.fiat.toString().toUpperCase()}
                                    </td>
                                    <td className={`${index !== (datas.length - 1) && "border-b-2 border-first-theme"}`}>
                                        <div className="flex flex-wrap">
                                            {
                                                data.paymentOptions.toString().split(",").map((item, index) => 
                                                    item * 1 >= 0 && <div key={index} className="text-xs bg-first-theme rounded-lg green-font p-2 mr-2 mb-1">{PAYMENT_OFFER_VALUES[item * 1].title}</div>
                                                )
                                            }
                                        </div>
                                    </td>
                                    <td className={`${index !== (datas.length - 1) && "border-b-2 border-first-theme"}`}>
                                        <div className="flex">
                                            <div className="flex flex-col mr-2">
                                                <span>{t("Buying")}</span>
                                                <span>{t("Limit")}</span>
                                            </div>
                                            <div className="flex flex-col">
                                                <div className="">{data.tokenName.toUpperCase()} {data.tokenAmount.toLocaleString("en-US")}</div>
                                                <div className="">{data.minLimit.toLocaleString("en-US")} - {data.maxLimit.toLocaleString("en-US")}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`pr-8 ${index !== (datas.length - 1) && "border-b-2 border-first-theme"} `}>
                                        {
                                            walletAddress && walletAddress.toString() !== data.owner.toString() 
                                                ? <a onClick={() => handleClick(!chainID ? data.offer.toString() : data.offer_index)}>
                                                    <GradButton cssClass="w-full" title={`${t("Sell")} ${data.tokenName.toUpperCase()}`} />
                                                </a>
                                                : <div className="w-full px-6 py-2 text-white rounded-lg bg-gray-500 opacity-40 text-center">
                                                    {t("Sell")} {data.tokenName.toUpperCase()}
                                                </div>
                                        }
                                    </td>
                                </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}

export default OfferTable;