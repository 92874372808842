import { useState, useMemo, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import useCookies from "react-cookie/cjs/useCookies";
import { 
  DialectUiManagementProvider, 
  DialectContextProvider, 
  DialectThemeProvider, 
  Backend } from '@dialectlabs/react-ui';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { AppContext } from './utils/AppContext';
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { Web3Auth } from "@web3auth/web3auth";
import { getChainData } from "./utils/getChainData";
import { WalletBalanceProvider } from './hooks/use-wallet-balance';
import { Toaster } from 'react-hot-toast';
import { NEXT_PUBLIC_SOLANA_NETWORK } from './constants/env';
import MyRoutes from './routes';
import i18n from "./translation/i18n";
import { JSEncrypt } from "jsencrypt";
import MobileLogoImg from "./assets/images/logo-mobile.png";
import './translation/i18n.js';
import "@solana/wallet-adapter-react-ui/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import '@dialectlabs/react-ui/lib/index.css';
import './App.css';

const INITIAL_STATE = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: 5,
  networkId: 5,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null
};

const convertWalletForDialect = (wallet) => ({...wallet});

const DialectProviders = ({children}) => {
  const wallet = useWallet();
  // We need to create an adapter for Dialect to support any type of wallet
  // `convertWalletForDialect` is a function that needs to be implemented to convert `WalletContextState` to `DialectWalletAdapter` type.
  // Please navigate to any example in `examples` folder and find an example implementation there.
  const dialectWallet = useMemo(() => convertWalletForDialect(wallet), [wallet]);

  if (!localStorage.getItem("publicKey")) {
    const crypt = new JSEncrypt({default_key_size: 400});
      const privateKey = crypt.getPrivateKey();
      const publicKey = crypt.getPublicKey();
      localStorage.setItem("publicKey", publicKey);
      localStorage.setItem("privateKey", privateKey);
  }


  // Basic configuration for dialect. Target mainnet-beta and dialect cloud production environment 
  const dialectConfig = useMemo(
    () => ({
      backends: [Backend.DialectCloud, Backend.Solana],
      environment: 'production',
    }),
    []
  );

  return (
    <DialectContextProvider config={dialectConfig} wallet={dialectWallet}>
      {/* 'dark' | 'light' */}
      <DialectThemeProvider theme="dark"> 
        <DialectUiManagementProvider>
          {children}
        </DialectUiManagementProvider>
      </DialectThemeProvider>
    </DialectContextProvider>
  );
}

let WALLETS = {
  getPhantomWallet: () => ({ name: "Phantom" }),
  getSolflareWallet: () => ({ name: "Solflare" }),
  getSolletWallet: () => ({ name: "Sollet" }),
  getLedgerWallet: () => ({ name: "Ledger" }),
  getSlopeWallet: () => ({ name: "Slope" }),
  getSolletExtensionWallet: () => ({ name: "SolletExtension" })
}

if (typeof window !== "undefined") {
  WALLETS = require("@solana/wallet-adapter-wallets");
}

function App() {
  const [ cookies ] = useCookies(['solcash']);
  const [chainState, setChainState] = useState(INITIAL_STATE);
  const [chainID, setChainID] = useState(cookies.chainID * 1);
  const [web3Modal, setWeb3Modal] = useState();

  localStorage.setItem("connected", true);
  i18n.changeLanguage(cookies.language);
  const endPoint = useMemo(() => "https://solana-api.projectserum.com", []);

  const wallets = useMemo(() => [
    WALLETS.getPhantomWallet(),
    WALLETS.getSolflareWallet(),
    WALLETS.getSolletWallet({ NEXT_PUBLIC_SOLANA_NETWORK }),
    WALLETS.getLedgerWallet(),
    WALLETS.getSlopeWallet(),
    WALLETS.getSolletExtensionWallet({ NEXT_PUBLIC_SOLANA_NETWORK }),
  ], []);

  const getProviderOptions = () => {
    return {
      walletconnect: {
        package: WalletConnect,
        options: {
          appName: "Web3Modal Example App",
          infuraId: "9aa3d95b3bc440fa88ea12eaa4456161"
        }
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: "Web3Modal Example App",
          infuraId: "9aa3d95b3bc440fa88ea12eaa4456161"
        }
      },
      web3auth: {
        package: Web3Auth,
        infuraId: "9aa3d95b3bc440fa88ea12eaa4456161"
      }
    }
  }

  useEffect(() => {
    const newWeb3Modal = new Web3Modal({
        network: getChainData(chainState.chainId).network,
        cacheProvider: false,
        providerOptions: getProviderOptions()
    });

    setWeb3Modal(newWeb3Modal);
  }, []);

  return (
    <ConnectionProvider endpoint={endPoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <DialectProviders>
          <WalletModalProvider logo={<img src={MobileLogoImg} alt="logo" />}>
            <WalletBalanceProvider>
              <AppContext.Provider value={{chainState, setChainState, web3Modal, chainID, setChainID}}>
                <div className="App w-full flex flex-col min-h-screen">
                  <MyRoutes/>
                  <Toaster />
                </div>
              </AppContext.Provider>
            </WalletBalanceProvider>
          </WalletModalProvider>
        </DialectProviders>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
