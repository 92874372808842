import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiBadgeCheck } from "react-icons/hi";
import GradButton from "./GradButton";

const MyOrderTable = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { datas, isLoading } = props;
    return (
        <div className="border-2 rounded-lg border-first-theme mb-16">
            <table className="w-full text-left ">
                <thead >
                    <tr className="font-thin text-sm text-second-theme">
                        <th className="border-b-2 rounded-t-lg border-first-theme py-2 pl-8">{t("Type/Vendors")}</th>
                        <th className="border-b-2 border-first-theme py-2">{t("Fiat Amount")}</th>
                        <th className="border-b-2 border-first-theme py-2">{t("Rate")}</th>
                        <th className="border-b-2 border-first-theme py-2">{t("Crypto Amount")}</th>
                        <th className="border-b-2 border-first-theme py-2 pr-8">{t("Status")}</th>
                        <th className="border-b-2 border-first-theme py-2 pr-8">{t("View")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        datas.length === 0
                            ? <tr><td colSpan="6"><div className="p-4 w-full flex items-center justify-center">
                                {t("There is no data.")}
                            </div></td></tr>
                            : datas.map((data, index) =>
                            <>
                                <tr key={index}>
                                    <td className={`pl-8 py-4 w-3/12`}>
                                        <div className="flex items-start">
                                            <div className="text-xs bg-first-theme rounded-lg green-font px-2 py-1 mr-2">{data.type}</div>
                                            <div className="mb-2 flex items-center">
                                                {data.name.slice(0, 4) + "..." + data.name.substring(data.name.length - 4)} 
                                                {
                                                    data.verified && <HiBadgeCheck color="#68CEC7" className="ml-2" />
                                                }
                                            </div>
                                        </div>
                                    </td>
                                    <td className="w-2/12">
                                        {data.fiatAmount.toLocaleString("en-US")} {data.fiat.toUpperCase()}
                                    </td>
                                    <td className="w-2/12">
                                        {data.rate.toLocaleString("en-US")} {data.fiat.toUpperCase()}
                                    </td>
                                    <td>
                                        {data.cryptoAmount.toLocaleString("en-US")} {data.tokenName.toUpperCase()}
                                    </td>
                                    <td >
                                        {
                                            data.status * 1 === 1
                                                ? <div className="flex items-center"><div className="w-2 h-2 green-bg rounded-full mr-2" />{t("Completed")}</div>
                                                : data.status * 1 === 0
                                                    ? <div className="flex items-center"><div className="w-2 h-2 bg-gray-500 rounded-full mr-2" />{t("Pending")}</div>
                                                    : <div className="flex items-center"><div className="w-2 h-2 bg-red-500 rounded-full mr-2" />{t("Cancelled")}</div>
                                        }
                                    </td>
                                    <td className={`pr-8`}>
                                        <a onClick={() => navigate(`/order/${data.type.toLowerCase()}/${data.id}`)}>
                                            <GradButton title={t("View")} cssClass="w-full" />
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`pl-8 pb-4 text-sm ${index !== (datas.length - 1) && "border-b-2 border-first-theme  "}`} colSpan={6}>
                                        <span className="text-second-theme">ID</span> <span>{data.id}</span> | <span className="text-second-theme">{data.createdAt}</span>
                                    </td>
                                </tr>
                            </>
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}

export default MyOrderTable;