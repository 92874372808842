import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import GrayButton from './GrayButton';
import GreenButton from './GreenButton';

const PaymentModal = (props) => {
    const { isShown, setShown, setAccountName, setEmailAddress, setCheck, accountName, check, emailAddress, paymentMethod } = props;
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [checkStatus, setCheckStatus] = useState(false);
    const cancelButtonRef = useRef(null);
    const { t } = useTranslation();

    const handleAdd = () => {
        setAccountName(name);
        setEmailAddress(email);
        setCheck(checkStatus);
        localStorage.setItem(`${paymentMethod}-name`, name);
        localStorage.setItem(`${paymentMethod}-email`, email);
        localStorage.setItem(`${paymentMethod}-remember`, checkStatus);
        setShown(false);
    };

    useEffect(() => {
        setName(accountName);
        setEmail(emailAddress);
        setCheckStatus(check);
    }, [accountName, emailAddress, check]);
    console.log(typeof checkStatus)

    return (
        <Transition.Root show={isShown} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShown}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-first-theme text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-first-theme px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-white relative">
                                    <div className='text-center green-font text-3xl mb-8'>
                                    {t("Add Payment Methods")}
                                    </div>
                                    <div className='text-second-theme text-center mb-8'>
                                    {t("Add")} {paymentMethod} {t("Account Details")}
                                    </div>
                                    <div className='mb-4'>
                                        <div className='text-sm text-second-theme mb-2 text-left'>{t("Account Name")}</div>
                                        <input 
                                            value={name}
                                            className='w-full p-4 bg-black rounded-lg' 
                                            placeholder={t('Enter your full name')}
                                            onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className='mb-4'>
                                        <div className='text-sm text-second-theme mb-2 text-left'>{t("Email Address")}</div>
                                        <input 
                                            type="email" 
                                            value={email}
                                            className='w-full p-4 bg-black rounded-lg' 
                                            placeholder={t('Enter your email address')}
                                            onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <p className='text-second-theme text-sm mb-4'>{t("I confirm the above payment details is correct. I agree this information will be visible to the buyer during the transaction for fiat payment.")}</p>
                                    <label className="flex items-center mb-8">
                                        <input 
                                            type="checkbox" 
                                            checked={(typeof checkStatus) === 'boolean' ? checkStatus : (checkStatus === "true")} 
                                            onChange={(e) => setCheckStatus(e.target.checked)} 
                                            className="green-font w-4 h-4 green-checkbox border border-gray-300 rounded" />
                                        <span htmlFor="default-checkbox" className="ml-2 text-second-theme">Remember me</span>
                                    </label>
                                    <div className='flex justify-between'>
                                        <GrayButton cssClass="w-5/12" title="Cancel" size="lg" onClick={() => setShown(false)} />
                                        <GreenButton cssClass="w-5/12" title="Add" size="lg" onClick={handleAdd} active={name && email} />
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default PaymentModal;