import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Navbar from "../components/Navbar";
import NotifyList from "../components/NotifyList";
import useNotification from "../utils/notifications";
import useEthNotification from "../utils/eth/notifications";
import ReactPaginate from "react-paginate";
import { AppContext } from "../utils/AppContext";

const NotificationsWrapper = () => {
    const { chainID } = useContext(AppContext);
    const { isLoading, allNotification } = useNotification();
    const { isEthLoading, allEthNotification } = useEthNotification();

    return (
        <Notifications 
            isLoading={!chainID ? isLoading : isEthLoading}
            allNotification={!chainID ? allNotification : allEthNotification} />
    );
}

const Notifications = ({isLoading, allNotification}) => {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [pageRange, setPageRage] = useState(0);
    
    const handlePage = (e) => {
        setPageNum(e.selected);
    }

    useEffect(() => {
        setNotifications(allNotification);
        const newPageRange = Math.floor(allNotification.length / 10) + 1;
        setPageRage(newPageRange);
    }, [allNotification])

    useEffect(() => {
        const filterNotifications = allNotification.filter((item, index) => index >= 10 * pageNum && index < (pageNum + 1) * 10);
        setNotifications(filterNotifications);
    }, [pageNum, allNotification])

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 text-white">
                <div className="text-2xl font-bold mt-12 mb-2 text-left">{t("Notifications")}</div>
                <div className="mb-10 text-left text-second-theme">{t("All access to your notifications")}</div>
                <NotifyList datas={notifications} isLoading={isLoading} />
                {
                    notifications.length > 0 &&
                        <div className="pagination mb-8">
                            <ReactPaginate 
                                breakLabel="..."
                                nextLabel={<AiOutlineRight size={24} />}
                                onPageChange={handlePage}
                                pageRangeDisplayed={3}
                                pageCount={pageRange}
                                previousLabel={<AiOutlineLeft size={24} />}
                                renderOnZeroPageCount={null} />
                        </div>
                }
            </div>
        </>
    );
}

export default NotificationsWrapper;