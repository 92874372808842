import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HiBadgeCheck } from "react-icons/hi";
import { FadeLoader } from 'react-spinners';
import GradButton from "./GradButton";

const MyOrderTableMobile = (props) => {
    const { datas, isLoading } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="mb-16 border-2 border-first-theme rounded-lg">
            {
                datas.length === 0
                    ? <div className="text-2xl mt-16 border-2 border-first-theme rounded-lg py-16">{t("There is no data.")}</div>
                    : datas.map((data, index) =>
                        <div key={index} className={`flex flex-col p-2 text-left ${datas.length - 1 !== index ? "border-b-2 border-first-theme mb-2" : ""} mb-2`}>
                            <div className="flex justify-between mb-2">
                                <div className="flex items-center">
                                    <div className="text-xs bg-first-theme rounded-lg green-font px-2 py-1 mr-2">{data.type}</div>
                                    <span className="ml-2">{data.name.slice(0, 4) + "..." + data.name.substring(data.name.length - 4)}</span>
                                    {
                                        data.verified && <HiBadgeCheck color="#68CEC7" className="ml-2" />
                                    }
                                </div>
                                {
                                    data.status * 1 === 1
                                    ? <div className="flex items-center"><div className="w-2 h-2 green-bg rounded-full mr-2" />{t("Completed")}</div>
                                    : data.status * 1 === 0
                                        ? <div className="flex items-center"><div className="w-2 h-2 bg-gray-500 rounded-full mr-2" />{t("Pending")}</div>
                                        : <div className="flex items-center"><div className="w-2 h-2 bg-red-500 rounded-full mr-2" />{t("Cancelled")}</div>
                                }
                            </div>
                            <div className="mb-2 flex justify-between flex-wrap">
                                <div className="mr-2">
                                    <div className="mb-1 text-sm text-second-theme">{t("Rate")}</div>
                                    <div className="">{data.rate.toLocaleString("en-US")} {data.fiat.toUpperCase()}</div>
                                </div>
                                <div className="mr-2">
                                    <div className="mb-1 text-sm text-second-theme">{t("Fiat Amount")}</div>
                                    <div className="">{data.rate.toLocaleString("en-US")} {data.fiat.toUpperCase()}</div>
                                </div>
                                <div className="mr-2">
                                    <div className="mb-1 text-sm text-second-theme">{t("Crypto Amount")}</div>
                                    <div className="">{data.cryptoAmount.toLocaleString("en-US")} {data.tokenName.toUpperCase()}</div>
                                </div>
                            </div>
                            <div className="mb-2 flex flex-wrap justify-between"><span className="break-all">ID: {data.id}</span><span className="text-second-theme">{data.createdAt}</span></div>
                            <a onClick={() => navigate(`/order/${data.type.toLowerCase()}/${data.id}`)}>
                                <GradButton cssClass="w-full" title={t("View")} />
                            </a>
                        </div>
                    )
            }
        </div>
    );
}

export default MyOrderTableMobile;