import MainContainer from "../containers/Home/MainContainer";
import InfoContainer from "../containers/Home/InfoContainer";
import CashContainer from "../containers/Home/CashContainer";
import FeatureContainer from "../containers/Home/FeatureContainer";
import FaqContainer from "../containers/Home/FaqContainer";
import Footer from '../components/Footer';
import GuideComponent from "../containers/GuideComponent";

const Home = () => {
    return (
        <div className="flex flex-col text-white">
            <MainContainer />
            <InfoContainer />
            <CashContainer />
            <FeatureContainer />
            <GuideComponent />
            <FaqContainer />
            <Footer />
        </div>
    )
}

export default Home;