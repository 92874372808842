import { useNavigate } from "react-router-dom";
import { RiThumbUpLine, RiThumbDownLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { HiBadgeCheck } from "react-icons/hi";
import { FadeLoader } from 'react-spinners';
import GradButton from "./GradButton";
import { PAYMENT_OFFER_VALUES } from "../constants/offers";

const OfferTableMobile = (props) => {
    const { datas, isLoading, walletAddress, chainID } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="mb-16 border-2 border-first-theme rounded-lg">
            {
                isLoading
                    ? <div className="p-2 w-full flex justify-center"><FadeLoader loading color='white'/></div>
                    : datas.length === 0
                        ? <div className="text-2xl py-16">{t("There is no data.")}</div>
                        : datas.map((data, index) =>
                            <div key={index} className={`flex flex-col p-2 text-left ${(datas.length - 1) !== index ? "border-b-2 border-first-theme mb-2" : ""}`}>
                                <div className="flex justify-between mb-2">
                                    <div className="flex items-center">
                                        {data.owner.toString().slice(0, 4) + "..." + data.owner.toString().substring(data.owner.toString().length - 4)} 
                                        {
                                            data.verified && <HiBadgeCheck color="#68CEC7" className="ml-2" />
                                        }
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex">
                                            <div className="flex items-center mr-4"><RiThumbUpLine color="#68CEC7" className="mr-2" />{data.thumbsUp}</div>
                                            <div className="flex items-center"><RiThumbDownLine color="#E03B3B" className="mr-2" />{data.thumbsDown}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2 flex flex-col">
                                    <span className="mb-1 text-sm text-second-theme">{t("Rate")}</span>
                                    <span>{data.rate.toLocaleString("en-US")} {data.fiat.toString().toUpperCase()}</span>
                                </div>
                                <div className="flex mb-2 justify-between">
                                    <div className="flex ">
                                        <div className="flex flex-col mr-2">
                                            <span>{t("Buying")}</span>
                                            <span>{t("Limit")}</span>
                                        </div>
                                        <div className="flex flex-col mb-2">
                                            <div className="">{data.tokenName.toUpperCase()} {data.tokenAmount.toLocaleString("en-US")}</div>
                                            <div className="">{data.minLimit.toLocaleString("en-US")} - {data.maxLimit.toLocaleString("en-US")}</div>
                                        </div>
                                    </div>
                                    <div className="">
                                        {
                                            walletAddress && walletAddress.toString() !== data.owner.toString()
                                                ? <a onClick={() => navigate(`/offer/seller/${!chainID ? data.offer.toString() : data.offer_index}`)}>
                                                    <GradButton cssClass="w-full" title={`${t("Sell")} ${data.tokenName.toUpperCase()}`} />
                                                </a>
                                                : <div className="flex px-6 py-2 text-white rounded-lg bg-gray-500 opacity-40 text-center">
                                                    {t("Sell")} {data.tokenName.toUpperCase()}
                                                </div>
                                        }
                                    </div>
                                    
                                </div>
                                <div className="flex flex-wrap mb-4">
                                    {
                                        data.paymentOptions.toString().split(",").map((item, index) => 
                                            item * 1 >= 0 && <div key={index} className="text-xs bg-first-theme rounded-lg green-font p-2 mr-2">{PAYMENT_OFFER_VALUES[item * 1].title}</div>
                                        )
                                    }
                                </div>
                                <div className="flex items-center">
                                    <div className={`w-2 h-2 rounded-full ${data.onlineStatus ? "green-bg" : "bg-red-500"} mr-2`}></div>
                                    <div className="text-xs">{t(`Seen ${data.lastSeen} ago`)}</div>
                                </div>
                            </div>
                        )
            }
        </div>
    );
}

export default OfferTableMobile;