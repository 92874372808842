import { useEffect, useState } from 'react';
import * as anchor from "@project-serum/anchor";
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import toast from 'react-hot-toast';
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import useWalletBalance from '../hooks/use-wallet-balance';
import {
    PublicKey,
    Transaction,
    LAMPORTS_PER_SOL,
} from "@solana/web3.js";

import { OFFERDATA_SIZE, CONTRACT_IDL, USERINFO_SIZE } from '../constants/contract';
import { NEXT_PUBLIC_SOLANA_RPC_HOST, NEXT_PUBLIC_CONTRACT_ID, NEXT_PUBLIC_POOL_ID } from '../constants/env';
import { CRYPTO_VALUES } from '../constants/offers';

const rpcHost = NEXT_PUBLIC_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);
const programId = new PublicKey(NEXT_PUBLIC_CONTRACT_ID);
const pool = new PublicKey(NEXT_PUBLIC_POOL_ID);
const idl = CONTRACT_IDL;
const confirmOption = {
    commitment : 'finalized',
    preflightCommitment : 'finalized',
    skipPreflight : false
}

async function sendAllTransaction(transactions, wallet, conn, signers){
    try {
        let commitment = "max"
        let unsignedTxns = []
        let block = await conn.getRecentBlockhash(commitment);
        for(let i = 0; i < transactions.length; i++){
            let transaction = transactions[i]
            transaction.recentBlockhash = block.blockhash;
            transaction.setSigners(wallet.publicKey, ...signers.map(s => s.publicKey));
            if(signers.length !== 0) await transaction.partialSign(...signers);
            unsignedTxns.push(transaction);
        }
        const signedTxns = await wallet.signAllTransactions(unsignedTxns)
        for(let i=0;i<signedTxns.length;i++){
            try {
              console.log(i)
              let hash = await conn.sendRawTransaction(await signedTxns[i].serialize())
              await conn.confirmTransaction(hash)
              // console.log(hash)
            } catch(error) {
              console.log(error)
              return {result: false, number: i, kind: 1}
            }
        }
        toast.success('Transaction succeed.');
        return {result: true, number: 0, kind: 0}
    } catch (error) {
        console.log(error);
        toast.error('Transaction failed. Please try again.');
        return {result: false};
    }
}

const _discontinueOffer = async (offerAccount, wallet) => {
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let transactionSet = [];
    let transaction = [];
    let signers = [];

    transaction.push(
        program.instruction.cancelOffer(
            {
            accounts:{
                owner : wallet.publicKey,
                offerData: offerAccount,
                tokenProgram : TOKEN_PROGRAM_ID,
                systemProgram : anchor.web3.SystemProgram.programId,
            }
        })
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
            console.log(bigTx)
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }

    await sendAllTransaction(transactionSet, wallet, connection, signers)
}

const useMyOffer = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [myOffers, setMyOffers] = useState([]);
    const [balance, setBalance] = useWalletBalance();
    const [refresh, setRefresh] = useState(false);
    const anchorWallet = useAnchorWallet();

    const updateBalance = async (wallet) => {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
    }

    const getOffers = async (wallet, own) => {
        let provider = wallet 
            ? new anchor.Provider(connection, wallet, anchor.Provider.defaultOptions())
            : new anchor.Provider(connection, anchor.Provider.defaultOptions())
        const program = new anchor.Program(idl, programId, provider);
        const allOffers = [];
        try {
            let filterOpt = [
                {
                    dataSize: OFFERDATA_SIZE
                },
                {
                    memcmp: {
                        offset: 40,
                        bytes: pool.toBase58()
                    }
                }
            ]

            if (own) {
                filterOpt.push({
                    memcmp:{
                        offset: 8,
                        bytes: wallet.publicKey.toBase58()
                    }
                })
            }
            
            let resp = await connection.getProgramAccounts(programId,
                {
                    dataSlice: {
                        length: 0, 
                        offset: 0
                    },
                    filters: filterOpt
                }
            );
            for(let offerAccount of resp){
                let offer = await program.account.offerData.fetch(offerAccount.pubkey);
                if (!own && (wallet && (!offer.status || offer.owner.toString() === wallet.publicKey.toString()))) continue;
                const userResp = await connection.getProgramAccounts(programId,
                    {
                        dataSlice: {
                            length: 0, 
                            offset: 0
                        },
                        filters: [
                            {
                                dataSize: USERINFO_SIZE
                            },
                            {
                                memcmp: {
                                    offset: 8,
                                    bytes: offer.owner.toString()
                                }
                            },
                            {
                                memcmp: {
                                    offset: 40,
                                    bytes: pool.toBase58()
                                }
                            }
                        ]
                    }
                );
                let userInfo = await program.account.userInfo.fetch(userResp[0].pubkey);
                const tokenName = CRYPTO_VALUES.filter(item => item.value === offer.token.toString());
                const date = new Date(offer.createdTime.toNumber() * 1000);
                allOffers.push({
                    ...offer, 
                    main: offer.sol ? true : false,
                    tokenAmount: Number.parseInt(offer.tokenAmount.toString()) / LAMPORTS_PER_SOL, 
                    tokenName: offer.sol ? "SOL" : tokenName[0].title,
                    offer: offerAccount.pubkey, 
                    thumbsUp: userInfo.thumbsUp, 
                    minLimit: offer.minLimit / LAMPORTS_PER_SOL,
                    maxLimit: offer.maxLimit / LAMPORTS_PER_SOL,
                    thumbsDown: userInfo.thumbsDown,
                    bought: offer.bought.toNumber() / LAMPORTS_PER_SOL,
                    unixTime: offer.createdTime.toNumber(),
                    createdAt: date.toDateString() + " " + date.toLocaleTimeString(),
                    verified: userInfo.verified,
                });
            }
        } catch (e) {
            console.log(e);
        }
        return allOffers;
    }

    const getMyOffers = async () => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);

        const useMyOffers = await getOffers(anchorWallet, true);
        await updateBalance(anchorWallet);

        setIsLoading(false);

        return useMyOffers;
    }

    const discontinueOffer = async (offerAccount) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);

        await _discontinueOffer(offerAccount, anchorWallet);
        const newMyOffers = await getOffers(anchorWallet, true);

        setIsLoading(false);

        return newMyOffers;
    }

    const getOfferData = async (offerAccount) => {
        setIsLoading(true);
        const provider = new anchor.Provider(connection, anchor.Provider.defaultOptions());
        const program = new anchor.Program(idl, programId, provider);
        let offer = await program.account.offerData.fetch(offerAccount);
        console.log(offer)
        const userResp = await connection.getProgramAccounts(programId,
            {
                dataSlice: {
                    length: 0, 
                    offset: 0
                },
                filters: [
                    {
                        dataSize: USERINFO_SIZE
                    },
                    {
                        memcmp: {
                            offset: 8,
                            bytes: offer.owner.toString()
                        }
                    },
                    {
                        memcmp: {
                            offset: 40,
                            bytes: pool.toBase58()
                        }
                    }
                ]
            }
        );
        let userInfo = await program.account.userInfo.fetch(userResp[0].pubkey);
        const tokenName = CRYPTO_VALUES.filter(item => item.value === offer.token.toString());
        setIsLoading(false);
        return {
            ...offer,
            tokenName: offer.sol ? "SOL" : tokenName[0].title,
            thumbsUp: userInfo.thumbsUp.toNumber(),
            thumbsDown: userInfo.thumbsDown.toNumber(),
            minLimit: offer.minLimit / LAMPORTS_PER_SOL,
            maxLimit: offer.maxLimit / LAMPORTS_PER_SOL,
            timeLimit: offer.timeLimit.toNumber(),
            offer: offerAccount.pubkey, 
            buyer: offer.owner.toString(),
            fee: 1,
            rate: offer.rate * 1,
        }
    }

    useEffect(() => {
        (async () => {
            await new Promise(r => setTimeout(r, 1500));
            if (
                !anchorWallet ||
                !anchorWallet.publicKey ||
                !anchorWallet.signAllTransactions ||
                !anchorWallet.signTransaction
            ) {
                setIsLoading(false);
                return;
            }
            const data = localStorage.getItem(`${anchorWallet.publicKey.toBase58()}-offers`);
            if (data) {
                setMyOffers(JSON.parse(data));
                setIsLoading(false)
            } 
            if (!data || refresh) {
                console.log("loading")
                setIsLoading(true);
            }
            const newMyOffers = await getOffers(anchorWallet, true);
            localStorage.setItem(`${anchorWallet.publicKey.toBase58()}-offers`, JSON.stringify(newMyOffers));
            setMyOffers(newMyOffers);
            setIsLoading(false);
            console.log("here")
            setRefresh(false);
        })();
    }, [anchorWallet, balance, refresh]);

    return { isLoading, myOffers, refresh, setRefresh, getMyOffers, getOfferData, discontinueOffer };
}


export default useMyOffer;