import { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { FadeLoader } from 'react-spinners';
import { AiFillCheckCircle } from 'react-icons/ai';
import { REGIONS } from '../constants/account';
import GrayButton from './GrayButton';
import GreenButton from './GreenButton';
import SelectWrapper from './SelectWrapper';

const RegionModal = (props) => {
    const { t } = useTranslation();
    const { isShown, setShown, handleModal, region, setRegion, isLoading} = props;
    const cancelButtonRef = useRef(null);

    const outSideClickFunc = () => {
        setShown(false)
    }

    const handleCheck = (index) => {
        setRegion(index + 1);
    }

    return (
        <Transition.Root show={isShown} >
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <SelectWrapper outSideClickFunc={outSideClickFunc}>
                                <div className="bg-first-theme px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-white relative flex flex-col">
                                    <div className='text-center green-font text-3xl mb-8'>
                                    {t("Change Region")}
                                    </div>
                                    <div className='text-second-theme text-center mb-8'>
                                    {t("Select your prefered region from the list")}
                                    </div>
                                    <div className='bg-black rounded-lg p-4 mb-8 max-h-96 overflow-auto overflow-ios' >
                                        {
                                            REGIONS.map((item, index) =>
                                                <div 
                                                    key={index}
                                                    className='flex items-center justify-between p-2 rounded-lg hover:bg-gray-500 border-b-2 border-first-theme' 
                                                    onClick={() => handleCheck(index)}
                                                >
                                                    <span>{item}</span>
                                                    <span className='green-font'>
                                                        {
                                                            region === index + 1
                                                                ? <AiFillCheckCircle />
                                                                : ""
                                                        }
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='flex justify-between'>
                                        <GrayButton cssClass="w-5/12" title={t("Cancel")} onClick={() => setShown(false)} />
                                        <GreenButton cssClass="w-5/12" title={t("Change Region")} onClick={handleModal} active={region > 0} />
                                    </div>
                                    {
                                        isLoading && 
                                            <div className='w-full h-full z-50 absolute top-0 left-0 flex items-center justify-center bg-opacity-40 bg-gray-400'>
                                                <FadeLoader loading color='white' />
                                            </div>
                                    }
                                </div>
                            </SelectWrapper>
                        </Transition.Child>
                    </div>
                </div>
        </Transition.Root>
    )
}

export default RegionModal;