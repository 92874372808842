const GreenButton = (props) => {
    const { title, size, cssClass, onClick, active } = props;
    return (
        <button 
            className={`${cssClass} ${size === "lg" ? "px-12 py-4" : "px-6 py-2"} ${active ? "green-bg green-border" : "bg-gray-500 border-first-theme"} border-2 text-black font-bold rounded-lg`}
            onClick={active ? onClick : () => {}}
        >
            {title}
        </button>
    );
}

export default GreenButton;