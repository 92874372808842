const TermCondition = () => {
    return (
        <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex flex-col text-second-theme">
            <div className="font-extrabold text-white text-4xl mb-8">MansaTrade</div>
            <div className="text-white text-2xl mb-4">Terms & Conditions</div>
            <div className="mb-4 text-left">
                <div className="font-bold text-white text-2xl mb-4">Regulatory Landscape</div>
                <p className="mb-2">
                By accessing our website, you are agreeing to be bound by the website’s Terms and Conditions and agree that you are responsible for compliance with any and all applicable local laws. You agree and represent that you have read and understood all of the provisions set forth herein and that you entirely agree to them. If you do not agree with any of these terms, you are prohibited from using or accessing this website. You must not use this website if you disagree with our Terms and Conditions and our Privacy Policy.
                </p>
                <p className="mb-2">
                Furthermore, the “Token Offering Terms & Conditions” is hereby concluded between MansaTrade, and any person or entity that fulfils the criteria set out in these T&Cs and that accepts the terms provided herein (hereinafter “Participant”, “Token Holder”, “you”). Parties to the T&Cs are herein referred to individually as a “Party” and collectively as the “Parties”. The Participant provides payments to MansaTrade in connection with the Token Sale (“Token Offering”) of cryptographic tokens existent on the blockchain that can enable usage of and interactions with certain services (“Services”) to be implemented by MansaTrade on the website after having closed the Token Offering. The cryptographic tokens are hereinafter referred to as "SOLCH" or “Token“.
                </p>
                <p className="">
                For the avoidance of doubt, this document does not represent an offering of any kind, or any other contractual form of capital investment product, and is not a solicitation for investment.
                </p>
            </div>
            <div className="mb-4 text-left">
                <div className="font-bold text-white text-2xl mb-4">Disclaimer</div>
                <p className="mb-2">
                We do not provide legal or financial advice or recommendations to buy or sell any security, product, service or investment. The information provided on this site or in any communication containing a link to this site is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject MansaTrade or its affiliates to any registration requirement within such jurisdiction or country.
                </p>
                <p className="mb-2">
                SOLCH is an innovative decentralized cross-chain global payment system that aims to accelerate the adoption of the Solana network around the world by adding more transaction capacity to the network backed by performing of crypto trading technology. MansaTrade does not make any guarantee or other promise as to any results that may be obtained from using our products and services. No one should make any investment decision without first consulting his or her own financial advisor and conducting his or her own research and due diligence. Getting involved in cryptocurrency and blockchain projects can be risky.
                </p>
                <div className="mb-2">To the maximum extent permitted by applicable law, nothing in this document will:</div>
                <ul className="pl-4 mb-2 list-disc">
                    <li>limit or exclude your liability or misinterpretation of the information presented on our website, in the Whitepaper, Litepaper, or Pitch Deck (as the case may be).</li>
                    <li>limit any of your liabilities in any way that is not permitted under applicable law.</li>
                </ul>
                <div className="mb-2">The limitations and exclusions of liability set out in this section and elsewhere in this disclaimer:</div>
                <ul className="pl-4 mb-2 list-disc">
                    <li>(a) are subject to the preceding paragraph.</li>
                    <li>(b) will govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer.</li>
                </ul>
                <p className="mb-2">
                By purchasing SOLCH, and to the extent permitted by law, you are agreeing not to hold any of the Company and its respective past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns liable for any losses or any special, incidental, or consequential damages arising from, or in any way connected, to the sale of SOLCH, including losses associated with the terms set forth below.
                </p>
                <p className="mb-2">
                Prior to purchasing SOLCH, you should carefully consider the terms listed below and, to the extent necessary, consult an appropriate lawyer, accountant, or tax professional. If any of the following terms are unacceptable to you, you should not purchase SOLCH.
                </p>
                <p className="mb-4">
                Purchases of SOLCH should be undertaken only by individuals, entities, or companies that have significant experience with, and understanding of, the usage and intricacies of cryptographic tokens, and blockchain based software systems. Purchasers should have a functional understanding of storage and transmission mechanisms associated with other cryptographic tokens. While the Company will be available to assist purchasers of SOLCH during the sale, it will not be responsible in any way for loss of any funds or crypto assets, including SOLCH, that result from actions taken by, or omitted by purchasers. If you do not have such experience or expertise, then you should not purchase SOLCH or participate in the sale of SOLCH. Your participation in SOLCH sale is deemed to be your undertaking that you satisfy the requirements mentioned in this paragraph.
                </p>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">1 - SOLCH Sale and Service Description</div>
                    <p className="mb-2">
                    The SOLCH Token Offering represents a voluntary contribution towards the implementation of crypto services in the ecosystem. These Services, the Token Offering and the usage of SOLCH are described in the Whitepaper (or as stated on the website). For the redemption of SOLCH for Services as well as for the usage of these Terms & Conditions apply (as stated on the website and after availability of the Services in the relevant locations in the ecosystem). The website (and the Whitepaper) and any other present or future are herein jointly referred to as the “Accompanying Documents”.
                    </p>
                    <p className="mb-2">
                    The SOLCH Token does not grant equity stake nor profit sharing. It does not represent an ownership right or direct claim in the SOLCH Ecosystem, revenues, profits or intellectual property, either present or future. Despite SOLCH team’s best efforts and diligence to bring this project forward, all Participants should be aware that their contributions are not refundable and accept the inherent risk (as outlined below) of project failure at any stage of development. This implicit risk is associated with any and all uncertainty of backing technologically-focused entrepreneurial projects, and can be affected by either internal or external factors that are out of the control scope of SOLCH. Additionally, Participants may be subject to any applicable compliance regulations (including KYC, AML and others where applicable).
                    </p>
                    <p className="mb-2">
                    SOLCH can be used in the Ecosystem with a corresponding utility value and will not provide any redemption rights. We believe that SOLCH do not qualify as a security or any other form of capital investment product and do not provide a Participant equity share in the legal structure of SOLCH.
                    </p>
                    <p className="mb-2">
                    SOLCH can only be used for purchases within the SOLCH Ecosystem and will not be accepted by any third party. Hence, SOLCH do not represent any unit of account or virtual currency.
                    </p>
                    <p className="">
                    SOLCH offers cryptocurrencies as method of payment. SOLCH may require a minimum or maximum amount for individual payment methods to manage payment costs and risks.
                    </p>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">2 - Scope of Terms</div>
                    <p className="mb-2">
                    Unless otherwise stated herein, these T&Cs only govern your purchase of SOLCH from MansaTrade during the Pre-Sale and Main Sale as defined in the Whitepaper.
                    </p>
                    <p className="">
                    Any use of SOLCH in connection with providing or receiving Services on the Ecosystem may be governed by other applicable terms and conditions and policies.
                    </p>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">3 - Participation requirements</div>
                    <p className="mb-2">
                    If you want to buy SOLCH with cryptocurrencies, you can send your Token units from any major cryptocurrency wallet, or you can buy with FIAT, following the instructions on the platform.
                    </p>
                    <p className="mb-2">
                    During the sale process you will be prompted to insert a new address of a Solana standard compatible wallet, as provided on the website, in order to receive any Tokens, you purchase from us. We reserve the right to prescribe additional guidance regarding specific wallet requirements. Please follow the website details carefully.
                    </p>
                    <p className="mb-2">
                    You are responsible for implementing reasonable measures for securing your wallet, vault, or other storage mechanism you use to receive and hold SOLCH purchased from us, including any requisite private key(s) or other credentials necessary to access such storage mechanism(s). If your private key(s) or other access credentials are lost, you may lose access to your Tokens. We are not responsible for any losses, costs, or expenses relating to lost access credentials.
                    </p>
                    <p className="">
                    We may determine, in our sole discretion, that it is necessary to obtain certain information about you in order to comply with applicable laws (e.g. KYC/AML) or regulations in connection with selling SOLCH to you. You agree to provide us such information promptly upon request and acknowledge that we may refuse to sell SOLCH to you until you provide such requested information and we have determined that it is permissible to sell you SOLCH under applicable laws or regulations.
                    </p>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">4 - Cancellation: Refusal of Purchase Requests / Refunds</div>
                    <p className="mb-2">
                    All purchases of SOLCH from us during the Pre-Sale and Main Sale are final, and there are no refunds or cancellations except as set forth herein or as may be required by applicable law or regulation. We reserve the right to refuse or cancel SOLCH purchase requests at any time in our sole discretion.
                    </p>
                    <p className="mb-2">
                    SOLCH may pause the SOLCH sale during the Pre-Sale or Main Sale periods in our sole discretion. Reasons for such a pause among others can be: material increase in Bitcoin or Solana blockchain price volatility (e.g. Bitcoin price crash), news that might impact the price volatility, suspicion of systematic fraud when purchasing SOLCH, or significant higher load of IT systems than expected.
                    </p>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">5 - Possible Migration of Tokens</div>
                    <p className="mb-2">
                    SOLCH is being created as a Solana blockchain compatible token on the Solana ecosystem. We reserve the right to migrate the Solana compatible-based SOLCH (the “Pre-existing Tokens”) to another protocol and to generate replacement Tokens on the new protocol (the “Replacement Tokens”) in the future, should we determine, in our sole discretion, that doing so is necessary or useful to the operation of our Ecosystem.
                    </p>
                    <p className="">
                    Should we decide to migrate the Tokens, we may no longer provide support for the Pre- existing Tokens relating to the Ecosystem, the Services, or any other operational matters, except with respect to the migration process. Although SOLCH does not at this time anticipate that it will require any Pre-existing Token holders to convert their Pre-existing Tokens to Replacement Tokens, SOLCH anticipates there will be significant incentives for Pre-existing Token owners to do so, since the practical utility of Pre-existing Tokens will likely diminish rapidly once the Replacement Tokens are created and in use by a significant portion of Ecosystem participants. Accordingly, by accepting these T&Cs you acknowledge and agree that in order for you to continue to participate on the Ecosystem or obtain utility from the Tokens you may need to convert the Tokens you purchase during the Token Sale to Replacement Tokens in the future.
                    </p>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">6 - Taxation</div>
                    <p className="">
                    Any amounts that you pay for SOLCH are exclusive of all applicable taxes. You are responsible for determining what, if any, taxes apply to your purchase of SOLCH, including, for example, sales, use, value added, and similar taxes. It is also your responsibility to withhold, collect, report and remit the correct taxes to the appropriate tax authorities. We are not responsible for withholding, collecting, reporting, or remitting any sales, use, value added, or similar tax arising from your purchase of SOLCH. However, in case we are obliged to withhold tax, this will be shown separately in our invoice.
                    </p>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">7 - Acknowledgment and Assumption of Risks</div>
                    <p className="">
                    You acknowledge and agree that there are risks associated with purchasing, owning, and using SOLCH for the provision or receipt of Services on the Ecosystem, as disclosed and explained. BY PURCHASING TOKENS, YOU EXPRESSLY ACKNOWLEDGE AND ASSUME THESE RISKS.
                    </p>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">8 - Warranties</div>
                    <div className="mb-2">When purchasing SOLCH from us, you represent and warrant us that:</div>
                    <ul className="pl-4 mb-2 list-disc">
                        <li>You have read and understand these T&Cs (including all eventual Annexes and Accompanying Documents).</li>
                        <li>You agree and acknowledge that SOLCH do not constitute securities in any form or any other form of capital investment product in any jurisdiction.</li>
                        <li>
                        You agree and acknowledge that these T&Cs and any Accompanying Documents do not constitute a prospectus or offer document of any sort and is not intended to constitute an offer of securities or any other form of capital investment product in any jurisdiction or a solicitation for investment in securities and you are not bound to enter into any contract or binding legal commitment and no cryptocurrency or other form of payment have to be accepted on the basis of these T&Cs and any Accompanying Documents.
                        </li>
                        <li>
                        You acknowledge and agree that there are risks associated with purchasing Tokens, owning Tokens, and using Tokens for the provision or receipt of Services including (but not necessarily limited to) the risks described in the Annex 1.
                        </li>
                        <li>
                        You have sufficient understanding of technical, financial and business matters (including those that relate to the Services and Ecosystem), cryptographic tokens, token storage mechanisms (such as token wallets), and blockchain technology to understand these T&Cs and any Accompanying Documents and to appreciate the risks and implications of purchasing Tokens.
                        </li>
                        <li>
                        You understand the restrictions and risks associated with the creation of Tokens as set forth herein and in the Accompanying Documents, and acknowledge and assume all such risks.
                        </li>
                        <li>
                        You have obtained sufficient information about the Tokens, the Services and the Ecosystem to make an informed decision to purchase Tokens.
                        </li>
                        <li>
                        You understand that the Tokens confer only the right to provide and receive Services on the Ecosystem (and potentially contribute to the technical development of the Ecosystem), and confer no other rights of any form with respect to the Ecosystem or SOLCH, including, but not limited to, any ownership, distribution, redemption, liquidation, proprietary (including all forms of intellectual property), or other financial or legal rights, except as stated in these T&Cs.
                        </li>
                        <li>
                        You are purchasing SOLCH solely for the purpose of receiving Services, and supporting the development, testing, deployment and operation of the Ecosystem, being aware of the commercial risks associated with SOLCH and the Ecosystem. You are not purchasing Tokens for any other purposes, including, but not limited to, any investment, speculative or financial purpose.
                        </li>
                        <li>
                        Your purchase of Tokens complies with applicable laws and regulations in your jurisdiction, including, but not limited to, (i) legal capacity and any other threshold requirements in your jurisdiction for the purchase of the Tokens and entering into contracts with SOLCH, (ii) any foreign exchange or regulatory restrictions applicable to such purchase, and (iii) any governmental or other consents that may need to be obtained.
                        </li>
                        <li>
                        You will comply with any applicable tax obligations in your jurisdiction arising from your purchase of Tokens.
                        </li>
                        <li>
                        If you are purchasing Tokens on behalf of any entity, you are authorized to accept these T&Cs on such entity’s behalf and that such entity will be responsible for breach of these T&Cs by you or any other employee or agent of such entity (references to “you” in these T&Cs refer to you and such entity, jointly).
                        </li>
                        <li>
                        You are neither a citizen or permanent resident of the Peoples Republic of China, a resident of a country where American embargoes and sanctions are in force, namely Iran, North Korea, Syria, Sudan, or a resident of any other country where crypto assets are considered to be unlawful.
                        </li>
                    </ul>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">9 - Limitation of Liability</div>
                    <p className="mb-2">
                    To the fullest extent permitted by applicable law (i) in no event will SOLCH or any of the company parties be liable for any indirect, special, incidental, consequential, or exemplary damages of any kind (including, but not limited to, where related to loss of revenue, income or profits, loss of use or data, or damages for business interruption) arising out of or in any way related to the sale or use of SOLCH or otherwise related to these terms, regardless of the form of action, whether based in contract, tort (including, but not limited to, simple negligence, whether active, passive or imputed), or any other legal or equitable theory (even if the party has been advised of the possibility of such damages and regardless of whether such damages were foreseeable), and (ii) in no event will the aggregate liability of SOLCH and the company parties (jointly), whether in contract, warranty, tort (including negligence, whether active, passive or imputed), or other theory, arising out of or relating to these terms or the use of or inability to use SOLCH, exceed the amount you pay to us for SOLCH.
                    </p>
                    <p className="mb-2">
                    The limitations set forth in these terms, in particular, in the previous paragraph will not limit or exclude the liability for the gross negligence, fraud or intentional, willful or reckless misconduct of the company or its representatives, nor the liability for damage from breaches of fundamental contractual obligation, injury to life, body or health by intention or negligence of the company or its representatives.
                    </p>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">10 - Disclaimers and waivers IMPORTANT NOTICE</div>
                    <p className="mb-2">
                    The SOLCH are not intended to constitute securities, financial instruments or any other form of capital investment product in any jurisdiction. These T&Cs and any Accompanying Documents do not constitute a prospectus or offer document of any sort and is not intended to constitute an offer of securities or any other form of capital investment product or a solicitation for investment in securities or any other form of capital investment product in any jurisdiction.
                    </p>
                    <p className="mb-2">
                    These T&Cs and any Accompanying Documents do not constitute or form part of any opinion on any advice to sell, or any solicitation of any offer by the seller of the SOLCH to purchase any SOLCH nor shall it or any part of it nor the fact of its presentation form the basis of, or be relied upon in connection with, any contract or investment decision.
                    </p>
                    <p className="mb-2">
                    No person is bound to enter into any contract or binding legal commitment in relation to the sale and purchase of the SOLCH and no cryptocurrency or other form of SOLCH is to be accepted on the basis of these T&Cs.
                    </p>
                    <p className="mb-2">
                    In the event of any inconsistencies between these T&Cs and the Whitepaper or any other Accompanying Documents, the Whitepaper shall prevai
                    </p>
                    <p className="mb-2">
                    No regulatory authority has examined or approved of any of the information set out in these T&Cs. No such action has been or will be taken under the laws, regulatory requirements or rules of any jurisdiction. The publication, distribution or dissemination of these T&Cs and any Accompanying Documents do not imply that the applicable laws, regulatory requirements or rules have been complied with.
                    </p>
                    <p className="mb-2">
                    There are risks and uncertainties associated with SOLCH and its respective businesses and operations, the SOLCH, the Token Offering and the Services (each as referred to in these T&Cs and any Accompanying Documents). These T&Cs and any Accompanying Documents, any part thereof and any copy thereof must not be taken or transmitted to any country where distribution or dissemination of these T&Cs and any Accompanying Documents is prohibited or restricted.
                    </p>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">10.1 - No representations and warranties by SOLCH</div>
                        <p className="mb-2">
                        SOLCH does not make or purport to make, and hereby disclaims, any representation, warranty or undertaking in any form whatsoever to any entity or person, including any representation, warranty or undertaking in relation to the truth, accuracy and completeness of any of the information set out in these T&Cs and any Accompanying Documents.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">10.2 - Cautionary note on forward-looking statements</div>
                        <p className="mb-2">
                        All statements contained in these T&Cs and any Accompanying Documents, statements made in press releases or in any place accessible by the public and oral statements that may be made by SOLCH or their respective directors, executive officers or employees acting on behalf of SOLCH (as the case may be), that are not statements of historical fact, constitute “forward-looking statements”. Some of these statements can be identified by forward-looking terms such as “aim”, “target”, “anticipate”, “believe”, “could”, “estimate”, “expect”, “if”, “intend”, “may”, “plan”, “possible”, “probable”, “project”, “should”, “would”, “will” or other similar terms. However, these terms are not the exclusive means of identifying forward-looking statements. All statements regarding SOLCH’s financial position, business strategies, plans and prospects and the future prospects of the industry which SOLCH is in are forward-looking statements. These forward-looking statements, including but not limited to statements as to SOLCH’s revenue and profitability, prospects, future plans, other expected industry trends and other matters discussed in these T&Cs and any Accompanying Documents regarding SOLCH are matters that are not historical facts, but only predictions.
                        </p>
                        <p className="mb-2">
                        These forward-looking statements involve known and unknown risks, uncertainties and other factors that may cause the actual future results, performance or achievements of SOLCH to be materially different from any future results, performance or achievements expected, expressed or implied by such forward-looking statements. These factors include, amongst others:
                        </p>
                        <ul className="pl-4 mb-2 list-disc">
                            <li>(a) changes in political, social, economic and stock or cryptocurrency market conditions, and the regulatory environment in the countries in which SOLCH conducts its businesses and operations.</li>
                            <li>(b) the risk that SOLCH may be unable or execute or implement its business strategies and future plans.</li>
                            <li>(c) changes in interest rates and exchange rates of fiat currencies and cryptocurrencies.</li>
                            <li>(d) changes in the anticipated growth strategies and expected internal growth of SOLCH.</li>
                            <li>(e) changes in the availability and fees of SOLCH in connection with their respective businesses and operations.</li>
                            <li>(f) changes in the availability and salaries of employees who are required by SOLCH to operate its businesses and operations.</li>
                            <li>(g) changes in preferences of customers of SOLCH.</li>
                            <li>(h) changes in competitive conditions under which SOLCH operate, and the ability of SOLCH to compete under such conditions.</li>
                            <li>(i) changes in the future capital needs of SOLCH and the availability of financing and capital to fund such needs.</li>
                            <li>(j) war or acts of international or domestic terrorism.</li>
                            <li>(k) occurrences of catastrophic events, natural disasters and acts of God that affect the businesses and/or operations of SOLCH.</li>
                            <li>(l) other factors beyond the control of SOLCH.</li>
                            <li>(m) any risk and uncertainties associated with SOLCH and its businesses and operations, the SOLCH and the Token Offering (each as referred to in the T&Cs and any Accompanying Documents).</li>
                        </ul>
                        <p className="mb-2">
                        All forward-looking statements made by or attributable to SOLCH or persons acting on behalf of SOLCH are expressly qualified in their entirety by such factors. Given that risks and uncertainties that may cause the actual future results, performance or achievements of SOLCH to be materially different from that expected, expressed or implied by the forward-looking statements in these T&Cs and any Accompanying Documents, undue reliance must not be placed on these statements. These forward- looking statements are applicable only as of the date of this Whitepaper.
                        </p>
                        <p className="mb-2">
                        Neither SOLCH nor any other person represents, warrants and/or undertakes that the actual future results, performance or achievements of SOLCH will be as discussed in those forward-looking statements. The actual results, performance or achievements of SOLCH may differ materially from those anticipated in these forward-looking statements. Nothing contained in these T&Cs and any Accompanying Documents is or may be relied upon as a promise, representation or undertaking as to the future performance or policies of SOLCH. Further, SOLCH disclaims any responsibility to update any of those forward- looking statements or publicly announce any revisions to those forward-looking statements to reflect future developments, events or circumstances, even if new information becomes available or other events occur in the future.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">10.3 - Market and industry information and no consent of other persons</div>
                        <p className="mb-2">
                        These T&Cs and any Accompanying Documents includes market and industry information and forecasts that have been obtained from internal surveys, reports and studies, where appropriate, as well as market research, publicly available information and industry publications. Such surveys, reports, studies, market research, publicly available information and publications generally state that the information that they contain has been obtained from sources believed to be reliable, but there can be no assurance as to the accuracy or completeness of such included information.
                        </p>
                        <p className="mb-2">
                        Save for SOLCH and its respective directors, executive officers and employees, no person has provided his or her consent to the inclusion of his or her name and/or other information attributed or perceived to be attributed to such person in connection therewith in this Whitepaper and no representation, warranty or undertaking is or purported to be provided as to the accuracy or completeness of such information by such person and such persons shall not be obliged to provide any updates on the same.
                        </p>
                        <p className="mb-2">
                        While SOLCH has taken reasonable actions to ensure that the information is extracted accurately and in its proper context, SOLCH has not conducted any independent review of the information extracted from third party sources, verified the accuracy or completeness of such information or ascertained the underlying economic assumptions relied upon therein. Consequently, neither SOLCH nor its respective directors, executive officers and employees acting on their behalf makes any representation or warranty as to the accuracy or completeness of such information and shall not be obliged to provide any updates on the same.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">10.4 - Terms used</div>
                        <p className="mb-2">
                        To facilitate a better understanding of the SOLCH being offered for purchase by SOLCH, and the businesses and operations of SOLCH, certain technical terms and abbreviations, as well as, in certain instances, their descriptions, have been used in these T&Cs and any Accompanying Documents. These descriptions and assigned meanings should not be treated as being definitive of their meanings and may not correspond to standard industry meanings or usage. Words importing the singular shall, where applicable, include the plural and vice versa and words importing the masculine gender shall, where applicable, include the feminine and neuter genders and vice versa. References to persons shall include corporations.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">10.5 - No advice</div>
                        <p className="mb-2">
                        No information in these T&Cs and any Accompanying Documents should be considered to be business, legal, financial or tax advice regarding the SOLCH tokens, the SOLCH Token Sale and the future SOLCH Wallet (each as referred to in the T&Cs and any Accompanying Documents). You should consult your own legal, financial, tax or other professional adviser regarding SOLCH and its respective businesses and operations. You should be aware that you may be required to bear the financial risk of any purchase of SOLCH tokens for an indefinite period of time.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">10.6 - No further information or update</div>
                        <p className="mb-2">
                        No person has been or is authorized to give any information or representation not contained in these T&Cs and any Accompanying Documents in connection with SOLCH and its respective businesses and operations, the SOLCH as well as the Token Offering and, if given, such information or representation must not be relied upon as having been authorized by or on behalf of SOLCH. The Token Offering shall not, under any circumstances, constitute a continuing representation or create any suggestion or implication that there has been no change, or development reasonably likely to involve a material change in the affairs, conditions and prospects of SOLCH or in any statement of fact or information contained in these T&Cs and any Accompanying Documents since the date hereof.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">10.7 - Restrictions on distribution and dissemination</div>
                        <p className="mb-2">
                        The distribution or dissemination of these T&Cs and any Accompanying Documents or any part thereof may be prohibited or restricted by the laws, regulatory requirements and rules of any jurisdiction. In the case where any restriction applies, you are to inform yourself about, and to observe, any restrictions which are applicable to your possession of these T&Cs and any Accompanying Documents or such part thereof (as the case may be) at your own expense and without liability to SOLCH. Persons to whom a copy of these T&Cs and any Accompanying Documents has been distributed or disseminated, provided access to or who otherwise have the T&Cs and any Accompanying Documents in their possession shall not circulate it to any other persons, reproduce or other-wise distribute these T&Cs and any Accompanying Documents or any information contained herein for any purpose whatsoever nor permit or cause the same to occur.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">10.8 - No offer of securities or registration</div>
                        <p className="mb-2">
                        These T&Cs and any Accompanying Documents do not constitute a prospectus or offer document of any sort and is not intended to constitute an offer of securities or any other form of investment product or a solicitation for investment in securities or any other form of investment product in any jurisdiction.
                        </p>
                        <p className="mb-2">
                        No person is bound to enter into any contract or binding legal commitment and no cryptocurrency or other form of payment is to be accepted on the basis of these T&Cs and any Accompanying Documents.
                        </p>
                        <p className="mb-2">
                        You are not eligible to purchase any SOLCH in the Token Offering if you are a citizen, resident (tax or otherwise) of the People’s Republic of China or a resident of a country where American embargoes and sanctions are in force, namely Iran, North Korea, Syria, Sudan, or Cuba or any other geographic area in which the purchase of SOLCH is prohibited by applicable law, decree, regulation, treaty, or administrative act. No regulatory authority has examined or approved of any of the information set out in these T&Cs and any Accompanying Documents. No such action has been or will be taken under the laws, regulatory requirements or rules of any jurisdiction. The publication, distribution or dissemination of these T&Cs and any Accompanying Documents does not imply that the applicable laws, regulatory requirements or rules have been complied with.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">10.9 - Risks and uncertainties</div>
                        <p className="mb-2">
                        Prospective purchasers of SOLCH should carefully consider and evaluate all risks and uncertainties associated with SOLCH and its respective businesses and operations, the SOLCH and the ITS, all information set out in these T&Cs and any Accompanying Documents and the T&Cs prior to any purchase of SOLCH. If any of such risks and uncertainties develops into actual events, the business, financial condition, results of operations and prospects of SOLCH could be materially and adversely affected. In such cases, you may lose all or part of the value of the SOLCH.
                        </p>
                    </div>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">11 - Dispute Resolution and Arbitration</div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">11.1 - Binding Arbitration</div>
                        <p className="mb-2">
                        Prospective purchasers of SOLCH should carefully consider and evaluate all risks and uncertainties associated with SOLCH and its respective businesses and operations, the SOLCH and the ITS, all information set out in these T&Cs and any Accompanying Documents and the T&Cs prior to any purchase of SOLCH. If any of such risks and uncertainties develops into actual events, the business, financial condition, results of operations and prospects of SOLCH could be materially and adversely affected. In such cases, you may lose all or part of the value of the SOLCH.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">11.2 - No Class or Representative Arbitrations</div>
                        <p className="mb-2">
                        Any Dispute arising out of or related to these T&Cs is personal to you and SOLCH and will be resolved solely through individual arbitration and will not be brought as a class arbitration, class action or any other type of representative proceeding. There will be no class arbitration or arbitration in which an individual attempt to resolve a Dispute as a representative of another individual or group of individuals. Further, a Dispute cannot be brought as a class or other type of representative action, whether within or outside of arbitration, or on behalf of any other individual or group of individuals.
                        </p>
                    <div className="mb-4 pl-4">
                    </div>
                        <div className="font-bold text-white text-lg mb-2">11.3 - Notice: Informal Dispute Resolution</div>
                        <p className="mb-2">
                        Each Party will notify the other Party in writing of any arbitrable Dispute within thirty (30) days of the date it arises, so that the Parties can attempt in good faith to resolve the Dispute informally. Notice to SOLCH shall be sent by e-mail. Notice to you shall be by email to the email address you provide to us. Your notice must include (i) your name, postal address, email address and telephone number, (ii) a description in reasonable detail of the nature or basis of the Dispute, and (iii) the specific relief that you are seeking. If you and SOLCH cannot agree how to resolve the Dispute within thirty (30) days after the date notice is received by the applicable Party or the Parties do not observe the notification period, then either you or SOLCH may, as appropriate and in accordance with this section, commence an arbitration proceeding.
                        </p>
                    </div>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">12 - Changes to the Terms & Conditions</div>
                    <p className="">
                    We may modify, change, supplement or update these T&Cs and any Accompanying Documents in our sole discretion at any time without advance notice. We suggest that you visit the Website regularly and subscribe to our newsletter to keep up to date with any changes. Your continued use of the website and the SOLCH will confirm your acceptance of these T&Cs and Accompanying Documents as modified, changed, supplemented or updated by us. If you do not agree to such revised T&Cs you must stop using this Website and any information, links or content contained on this website and cease participating in the ITS.
                    </p>
                </div>
                <div className="mb-4 pl-4">
                    <div className="font-bold text-white text-xl mb-2">13 - Final provisions</div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">13.1 - Governing Law</div>
                        <p className="mb-2">
                        These Terms are being governed by, construed and enforced in accordance with the laws of United States of America.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">13.2 - Severability</div>
                        <p className="mb-2">
                        If any term, clause or provision of these T&Cs is held unlawful, void or unenforceable, then that term, clause or provision will be severable from these T&Cs and will not affect the validity or enforceability of any remaining part of that term, clause or provision, or any other term, clause or provision of these T&Cs. In the case of a clause not being valid or in the case that a regulation has not been included therein, this gap shall be replaced by a valid clause that the Parties would have been agreed upon if they would have known the gap.
                        </p>
                    </div>
                    <div className="mb-4 pl-4">
                        <div className="font-bold text-white text-lg mb-2">13.3 - No waiver</div>
                        <p className="mb-2">
                        The failure of SOLCH to enforce any of the provisions of these T&Cs or any Accompanying Document or any rights with respect thereto shall in no way be considered as a waiver of such provisions or rights or in any way affect the validity of these T&Cs.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mb-4 text-left">
                <div className="font-bold text-white text-2xl mb-4">Annex 1: Risk Factors</div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risk of Losing Access. Loss of Private Key(s), Custodial Error or personal Error</div>
                    <p className="">
                    A private key, or a combination of private keys, is necessary to control and dispose of SOLCH stored in your digital wallet or vault. Accordingly, loss of requisite private key(s) associated with your digital wallet or vault storing SOLCH will result in loss of such SOLCH. Moreover, any third party that gains access to such private key(s), including by gaining access to login credentials of a hosted wallet service you use, may be able to misappropriate your SOLCH. Any errors or malfunctions caused by or otherwise related to the digital wallet or vault you choose to receive and store SOLCH, including your own failure to properly maintain or use such digital wallet or vault, may also result in the loss of your SOLCH. Additionally, your failure to precisely follow the procedures set forth for buying and receiving SOLCH, including, for instance, if you provide an incorrect SOLCH Receipt Address, or provide an address that is not Solana compatible, may result in the loss of your SOLCH.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks Relating to Solana Blockchain Protocol</div>
                    <p className="">
                    Because Tokens and the Ecosystem are partially based on the Solana blockchain protocol, any malfunction, breakdown or abandonment of the Solana blockchain protocol may have a material adverse effect on the Ecosystem or SOLCH. Moreover, advances in cryptography, or technical advances such as the development of quantum computing, could present risks to the SOLCH and the Ecosystem, including the utility of the SOLCH for obtaining Services, by rendering ineffective the cryptographic consensus mechanism that underpins the Solana blockchain protocol.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks of Mining Attacks</div>
                    <p className="">
                    As with other decentralized cryptographic tokens based on the Solana blockchain, the Tokens are susceptible to attacks by miners in the course of validating Token transactions on the Solana blockchain, including, but not limited to, double-spend attacks, majority mining power attacks, and selfish-mining attacks. Any successful attacks present a risk to the Ecosystem and the Tokens, including, but not limited to, accurate execution and recording of transactions involving Tokens.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks of Hacking. Security Issues</div>
                    <p className="">
                    Hackers or other malicious groups or organizations may attempt to interfere with the Ecosystem or the Tokens in a variety of ways, including, but not limited to, malware attacks, denial of service attacks, consensus-based attacks, smurfing and spoofing. Furthermore, because the Ecosystem is based on open-source software, there is a risk that a third party or a member of the SOLCH team may intentionally or unintentionally introduce weaknesses into the core infrastructure of the Ecosystem, which could negatively affect the Ecosystem and the Tokens, including the utility of the Tokens for obtaining Services.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks of Theft</div>
                    <p className="">
                    SOLCH will make every effort to ensure that the cryptocurrency funds received from the Token Offering will be securely held in the Escrow Wallet. Notwithstanding such security measures, there is no assurance that there will be no theft of the cryptocurrencies as a result of hacks, sophisticated cyber-attacks, distributed denials of service or errors, vulnerabilities or defects on the Website, in the smart contracts on which the Escrow Wallet and the Token Offering rely, on the Solana blockchain or any other blockchain, or otherwise. Such events may include, for example, flaws in programming or source code leading to exploitation or abuse thereof. In such event, even if the Token Offering is completed, SOLCH may not be able to receive the cryptocurrencies raised and may not be able to utilize such funds for the development of the Ecosystem. As such, distributed SOLCH may hold little worth or value, and this would impact its trading price.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks Associated with Markets for Tokens</div>
                    <p className="">
                    The Tokens are intended to be used solely within the Ecosystem and SOLCH will not support or otherwise facilitate any secondary trading or external valuation of Tokens. This restricts the contemplated avenues for using Tokens to the provision or receipt of Services, and could therefore create illiquidity risk with respect to any Tokens you own. Even if secondary trading of Tokens is facilitated by third-party exchanges, such exchanges may be relatively new and subject to little or no regulatory oversight, making them more susceptible to fraud or manipulation. Furthermore, to the extent that third parties do ascribe an external exchange value to Tokens (e.g., as denominated in a digital or fiat currency), such value may be extremely volatile and diminish to zero.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks of Future Sales. Issuance of Token</div>
                    <p className="">
                    Future sales or issuance of SOLCH could materially and adversely affect the market price of SOLCH. Any future sale or issuance of SOLCH would increase the supply of SOLCH in the market and this may result in a downward price pressure on SOLCH. The sale or distribution of a significant number of SOLCH outside of the Token Offering (including but not limited to the sales of SOLCH undertaken after the Token Offering, issuance of SOLCH to persons other than Participants for purposes of community initiatives, business development, education and market expansion and issuance of SOLCH as a reward to users of the Ecosystem), or the perception that such further sales or issuance may occur, could adversely affect the trading price of the Tokens.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks of Uninsured Losses</div>
                    <p className="">
                    Unlike bank accounts or accounts at some other financial institutions, Tokens are uninsured unless you specifically obtain private insurance to insure them. Thus, in the event of loss or loss of utility value, there is no public insurer or any private insurance arranged by SOLCH, to offer recourse to you.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks Associated with Uncertain Regulations and Enforcement Actions</div>
                    <p className="">
                    The regulatory status of the Tokens and distributed ledger technology is unclear or unsettled in many jurisdictions. It is difficult to predict how or whether regulatory agencies may apply existing regulation with respect to such technology and its applications, including the Ecosystem and the Tokens. It is likewise difficult to predict how or whether legislatures or regulatory agencies may implement changes to law and regulation affecting distributed ledger technology and its applications, including the Ecosystem and the Tokens. Regulatory actions could negatively impact the Ecosystem and the Tokens in various ways, including, for purposes of illustration only, through a determination that the purchase, sale and delivery of the Tokens constitutes unlawful activity or that the Tokens are a regulated instrument that require registration or licensing of those instruments or some or all of the parties involved in the purchase, sale and delivery thereof. SOLCH may cease operations in a jurisdiction in the event that regulatory actions, or changes to law or regulation, make it illegal to operate in such jurisdiction, or commercially undesirable to obtain the necessary regulatory approval(s) to operate in such jurisdiction.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks Arising from Taxation</div>
                    <p className="">
                    The tax characterization of Tokens is uncertain. You must seek your own tax advice in connection with purchasing Tokens, which may result in adverse tax consequences to you, including withholding taxes, income taxes and tax reporting requirements.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks of Competing Ecosystems</div>
                    <p className="">
                    It is possible that alternative Ecosystems could be established that utilize similar protocols underlying the Ecosystem and attempt to facilitate services that are materially similar to the Services. The Ecosystem may compete with these alternatives, which could negatively impact the Ecosystem and Tokens, including the utility of the Tokens for obtaining Services.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks of Insufficient Interest in the Ecosystem</div>
                    <p className="">
                    It is possible that the Ecosystem will not be used by a large number of individuals, companies and other entities or that there will be limited public interest in the creation and development of distributed Ecosystems (such as the Ecosystem) more generally. Such a lack of use or interest could negatively impact the development of the Ecosystem and therefore the potential utility of the Tokens, including the utility of the Tokens for obtaining Services.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks Associated with the Development and Maintenance of the Ecosystem</div>
                    <p className="">
                    The Ecosystem is still under development and may undergo significant changes over time. Although we intend for the Tokens and Ecosystem to function as described in the Whitepaper and intend to take commercially reasonable steps toward those ends, we may have to make changes to any timelines announced in the Whitepaper, on the Website or any other official channel of SOLCH, or the specifications of the Tokens or Ecosystem for any number of legitimate reasons. This could create the risk that the Tokens or Ecosystem, as further developed and maintained, may not meet your expectations at the time of purchase.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risk of Dissolution of SOLCH or the Ecosystem</div>
                    <p className="">
                    It is possible that, due to any number of reasons, including, but not limited to, an unfavorable fluctuation in the value of BTC, ETH, (or other cryptographic and fiat currencies), decrease in the Tokens’ utility (including their utility for obtaining Services), the failure of commercial relationships, intellectual property ownership challenges, insolvency, or the liquidation of SOLCH, the Ecosystem may no longer be viable to operate. The Participants may lose parts or all of their SOLCH purchasing price.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks Arising from Lack of Governance Rights</div>
                    <p className="">
                    Because Tokens confer no governance rights of any kind with respect to the Ecosystem or SOLCH, all decisions involving SOLCH’s products or services within the Ecosystem or SOLCH itself will be made by SOLCH at its sole discretion, including, but not limited to, decisions to discontinue its products or services, to create and sell more Tokens for use on the Ecosystem, or to sell or liquidate SOLCH. These decisions could adversely affect the Ecosystem and the utility of any Tokens you own, including their utility for obtaining Services.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks Relating to the Business Partners</div>
                    <p className="">
                    Especially at the start, SOLCH relies on business partners to provide certain Services, especially for Services that require licenses as financial service institutions. While we have legally-binding agreements with business partners and are already in talks with further potential partners, we cannot guarantee that business partners won’t terminate their cooperation for whatever reason or that we can close new agreements with potential partners. The provision of certain Services might therefore be interrupted, delayed or cannot be offered at all.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks Associated with Markets for SOLCH</div>
                    <p className="">
                    The SOLCH token is intended to be used solely on the Ecosystem, and SOLCH will not support or otherwise facilitate any secondary trading or external valuation of SOLCH. This restricts the contemplated avenues for using SOLCH to obtain Services or access the Ecosystem, and could therefore create illiquidity risk with respect to SOLCH you hold. Even if secondary trading of SOLCH is facilitated by third party exchanges, such exchanges may be relatively new and subject to little or no regulatory oversight, making them more susceptible to market-related risks. Furthermore, to the extent that third-parties do ascribe an external exchange value to SOLCH (e.g., as denominated in a digital or fiat currency), such value may be extremely volatile and diminish to zero.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks of Data Centre Facilities of Third Parties</div>
                    <p className="">
                    SOLCH’s infrastructure is established through servers that it rents at data center facilities of third parties. If SOLCH is unable to renew its data facility lease under commercially reasonable terms or at all, SOLCH may be required to transfer its servers to a new data center facility, and may incur significant costs and possible service interruption in connection with the relocation. These facilities are also vulnerable to damage or interruption from, among others, natural disasters, arson, terrorist attacks, power losses, and telecommunication failures. Additionally, the third-party providers of such facilities may suffer a breach of security as a result of third-party action, employee error, malfeasance or otherwise, and a third party may obtain unauthorized access to the data in such servers. As techniques used to obtain unauthorized access to, or to sabotage systems change frequently and generally are not recognized until launched against a target, SOLCH and the providers of such facilities may be unable to anticipate these techniques or to implement adequate preventive measures. Any such security breaches or damages which occur which impact upon SOLCH’s infrastructure network and/or the Ecosystem may adversely impact the price of SOLCH.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Risks of Economic Conditions</div>
                    <p className="">
                    SOLCH has been and could continue to be affected by general global economic and market conditions. Challenging economic conditions worldwide have from time to time, contributed, and may continue to contribute, to slowdowns in the information technology industry at large. Weakness in the economy could have a negative effect on SOLCH’s business, operations and financial condition, including decreases in revenue and operating cash flows. Additionally, in a down-cycle economic environment, SOLCH may experience the negative effects of increased competitive pricing pressure and a slowdown in commerce and usage of the Ecosystem. Suppliers on which SOLCH relies for servers, bandwidth, location and other services could also be negatively impacted by economic conditions that, in turn, could have a negative impact on SOLCH’s operations or expenses. There can be no assurance, therefore, that current economic conditions or worsening economic conditions or a prolonged or recurring recession will not have a significant adverse impact on MansaTrade’s business, financial condition and results of operations and hence the Ecosystem, which would correspondingly impact the trading price of SOLCH.
                    </p>
                </div>
                <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-2">Unanticipated Risks</div>
                    <p className="">
                    Cryptographic tokens such as SOLCH are a new and untested technology. In addition to the risks included in this Annex. there are other risks associated with your purchase, possession, and use of the Tokens, including unanticipated risks. Such risks may further materialize as unanticipated variations or combinations of the risks discussed in this Annex.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermCondition;