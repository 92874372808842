import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { FiRefreshCcw } from "react-icons/fi";
import { RiDownloadLine } from "react-icons/ri";
import ReactDatePicker from "react-datepicker";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { IoCalendar } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import { useWallet } from '@solana/wallet-adapter-react';
import MyOrderTable from "../components/MyOrderTable";
import MyOrderTableMobile from "../components/MyOrderTableMobile";
import Navbar from "../components/Navbar";
import SelectComponent from "../components/SelectComponent";
import { CRYPTO_VALUES, TYPE_VALUES, STATUS_VALUES } from "../constants/orders";
import { ETH_CRYPTO_FILTER_VALUES } from "../constants/eth/offers";
import useOrder from "../utils/orders";
import useEthOrder from "../utils/eth/orders";
import { FadeLoader } from "react-spinners";
import checkDevice from "../utils/checkDevice";
import { toast } from "react-hot-toast";
import { AppContext } from "../utils/AppContext";

const MyOrderListWrapper = () => {
    const { chainID, chainState } = useContext(AppContext);
    const wallet = useWallet();
    const { isLoading, allOrders, refresh, setRefresh } = useOrder();
    const { isEthLoading, allEthOthers, ethRefresh, setEthRefresh } = useEthOrder();

    return (
        <MyOrderList
            walletAddress={!chainID ? wallet.publicKey : chainState.address}
            cryptoValues={!chainID ? CRYPTO_VALUES : ETH_CRYPTO_FILTER_VALUES[chainID]}
            isLoading={!chainID ? isLoading : isEthLoading}
            allOrders={!chainID ? allOrders : allEthOthers}
            refresh={!chainID ? refresh : ethRefresh}
            setRefresh={!chainID ? setRefresh : setEthRefresh} />
    )
}

const MyOrderList = ({walletAddress, cryptoValues, isLoading, refresh, setRefresh, allOrders}) => {
    const defaultDownloadData = [
        ["Type", "Venders", "Fiat Amount", "Rate", "Crypto Amount", "Stats", "Created Time"]
    ];
    const date = new Date();
    const { t } = useTranslation();
    const yesterday = date.setDate(date.getDate() - 30);
    const [selectedCryptoIndex, setSelectedCryptoIndex] = useState(0);
    const [selectedTypeIndex, setSelectedTypeIndex] = useState(0);
    const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);
    const [orderList, setOrderList] = useState([]);
    const [pageRange, setPageRage] = useState(0);
    const [pageNum, setPageNum] = useState(0);
    const [startDate, setStartDate] = useState(yesterday);
    const [endDate, setEndDate] = useState(new Date());
    const [downloadData, setDownloadData] = useState(defaultDownloadData);

    const handlePage = (e) => {
        setPageNum(e.selected);
    }

    const handleRefresh = () => {
        setRefresh(true);
    }

    const rejectDownload = () => {
        toast.error("This feature is only available in the Mobile App or Desktop version");
    }

    useEffect(() => {
        setOrderList(allOrders);
        const newPageRange = Math.floor(allOrders.length / 10) + 1;
        setPageRage(newPageRange);
    }, [allOrders]);

    useEffect(() => {
        let filterOrders = [...allOrders];
        if (selectedCryptoIndex !== 0) {
            if (selectedCryptoIndex === 1) {
                filterOrders = filterOrders.filter(item => item.main);
            } else {
                filterOrders = filterOrders.filter(item => (item.token.toString() === CRYPTO_VALUES[selectedCryptoIndex].value) && !item.sol);
            }
        }
        if (selectedStatusIndex !== 0) {
            filterOrders = filterOrders.filter(item => item.status === STATUS_VALUES[selectedStatusIndex].value);
        }
        if (selectedTypeIndex !== 0) {
            filterOrders = filterOrders.filter(item => item.type === TYPE_VALUES[selectedTypeIndex].value);
        }
        const startUnixDate = Math.floor(new Date(startDate) / 1000);
        const endUnixDate = Math.floor(new Date(endDate) / 1000);
        filterOrders = filterOrders.filter(item => item.unixTime > startUnixDate);
        filterOrders = filterOrders.filter(item => item.unixTime < endUnixDate);
        filterOrders = filterOrders.filter((item, index) => index >= 10 * pageNum && index < (pageNum + 1) * 10);
        setOrderList(filterOrders);
    }, [cryptoValues, selectedCryptoIndex, selectedStatusIndex, selectedTypeIndex, allOrders, pageNum, startDate, endDate])
    
    useEffect(() => {
        let newDownloadData = [];
        newDownloadData.push(defaultDownloadData[0]);
        orderList.forEach((item) => {
            newDownloadData.push(
                [
                    item.type, 
                    item.name, 
                    item.fiatAmount + item.fiat, 
                    item.rate + item.fiat,
                    item.cryptoAmount + item.tokenName,
                    item.status ? "Completed" : "Pending",
                    item.createdAt
                ]
            )
        });

        setDownloadData(newDownloadData);
    }, [orderList])

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 text-white mt-12">
                {/* <div className="flex border-b-2 border-first-theme text-sm mb-10">
                    <div className="border-b-2 green-border pb-2 mr-8">All Orders</div>
                    <div className="mr-8">Open Orders</div>
                    <div className="mr-8">Completed Orders</div>
                    <div className="mr-8">Cancelled Orders</div>
                </div> */}
                <div className="flex justify-between flex-wrap mb-16">
                    <div className="flex flex-wrap mb-2">
                        <SelectComponent title={t("Filter by Crypto")} values={cryptoValues} value={selectedCryptoIndex} onChange={setSelectedCryptoIndex} />
                        <SelectComponent title={t("Order Type")} values={TYPE_VALUES} value={selectedTypeIndex} onChange={setSelectedTypeIndex} />
                        <SelectComponent title={t("Status")} values={STATUS_VALUES} value={selectedStatusIndex} onChange={setSelectedStatusIndex} />
                        <div className="flex flex-col">
                            <div className="text-sm text-second-theme mb-2 text-left">{t("Date Range")}</div>
                            <div className="px-4 py-2 bg-first-theme rounded-lg flex items-center justify-between">
                                <div className="flex items-center w-32">
                                    <IoCalendar className="text-second-theme mr-2" />
                                    <ReactDatePicker 
                                        className="bg-transparent w-full" 
                                        dateFormat="dd-MM-yyyy" 
                                        selected={startDate} 
                                        onChange={(date) => setStartDate(date)} />
                                </div>
                                <span className="px-2 text-second-theme">-</span>
                                <div className="flex items-center w-32">
                                    <IoCalendar className="text-second-theme mr-2" />
                                    <ReactDatePicker 
                                        className="bg-transparent w-full" 
                                        dateFormat="dd-MM-yyyy" 
                                        selected={endDate} 
                                        minDate={startDate}
                                        onChange={(date) => setEndDate(date)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-wrap justify-end mb-2">
                        <div className="flex flex-col mr-4">
                            <div className="text-sm text-second-theme mb-2 text-left">{t("Download")}</div>
                            {
                                checkDevice()
                                    ? <div 
                                    onClick={rejectDownload}
                                    className="px-4 py-2 border-2 cursor-not-allowed border-first-theme opacity-50 rounded-lg flex items-center justify-center">
                                        <RiDownloadLine size={24} />
                                    </div>
                                    : isLoading
                                        ? <div className="px-4 py-2 border-2 cursor-not-allowed border-first-theme opacity-50 rounded-lg flex items-center justify-center">
                                            <RiDownloadLine size={24} />
                                        </div>
                                        : <CSVLink 
                                            className="px-4 py-2 border-2 cursor-pointer border-first-theme rounded-lg flex items-center justify-center"
                                            filename="order_list"
                                            data={downloadData}
                                            target="_blank"
                                        >
                                            <RiDownloadLine size={24} />
                                        </CSVLink>
                            }
                        </div>
                        <div className="flex flex-col cursor-pointer" onClick={handleRefresh}>
                            <div className="text-sm text-second-theme mb-2 text-left">{t("Refresh")}</div>
                            <div className="px-4 py-2 border-2 border-first-theme rounded-lg flex items-center justify-center">
                                <FiRefreshCcw className={`${refresh && "animate-spin"}`} size={24} />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isLoading
                        ? <div className="w-full flex justify-center">
                            <FadeLoader loading color="white" />
                        </div>
                        : walletAddress
                            ? <>
                                <div className="hidden lg:block"><MyOrderTable isLoading={isLoading} datas={orderList} /></div>
                                <div className="block lg:hidden"><MyOrderTableMobile  datas={orderList} isLoading={isLoading} /></div>
                            </>
                            : <div className="py-16 border-2 border-first-theme rounded-lg text-2xl">
                                {t("Please connect your wallet")}
                            </div>
                }
                {
                    orderList.length > 0 &&
                        <div className="pagination mb-8">
                            <ReactPaginate 
                                breakLabel="..."
                                nextLabel={<AiOutlineRight size={24} />}
                                onPageChange={handlePage}
                                pageRangeDisplayed={3}
                                pageCount={pageRange}
                                previousLabel={<AiOutlineLeft size={24} />}
                                renderOnZeroPageCount={null} />
                        </div>
                }
            </div>
        </>
    );
}

export default MyOrderListWrapper;