import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FadeLoader } from 'react-spinners';
import { useWallet } from '@solana/wallet-adapter-react';
import toast from 'react-hot-toast';
import useCookies from "react-cookie/cjs/useCookies";
import { AiFillFlag } from "react-icons/ai";
import { RiGlobalFill } from "react-icons/ri";
import { SiLetsencrypt } from "react-icons/si";
// import { FaUser } from "react-icons/fa";
import Navbar from "../components/Navbar";
import useAccount from "../utils/account";
import useEthAccount from "../utils/eth/account";
// import NicknameModal from "../components/NicknameModal";
import LanguageModal from "../components/LanguageModal";
import RegionModal from "../components/RegionModal";
import i18n from "../translation/i18n";
import checkDevice from "../utils/checkDevice";
import { AppContext } from "../utils/AppContext";
const FileSaver = require('file-saver');

const AccountWrapper = () => {
    const { chainID, chainState} = useContext(AppContext);
    const { isLoading, userData, updateProfile, createUser, getUser } = useAccount();
    const { isEthLoading, ethUserData, updateEthProfile, createEthUser, getEthUser } = useEthAccount();
    const wallet = useWallet();

    return (
        <Account 
            wallet={!chainID ? wallet : chainState.address}
            walletAddress={!chainID ? wallet.publicKey.toBase58() : chainState.address}
            isLoading={!chainID ? isLoading : isEthLoading}
            userData={!chainID ? userData : ethUserData}
            updateProfile={!chainID ? updateProfile : updateEthProfile}
            createUser={!chainID ? createUser : createEthUser}
            getUser={!chainID ? getUser : getEthUser} />
    );
}

const Account = ({wallet, walletAddress, isLoading, userData, updateProfile, createUser, getUser}) => {
    const [ cookies, setCookie ] = useCookies(['solcash']);
    const { t } = useTranslation();
    // const [showNicknameModal, setShowNicknameModal] = useState(false);
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [showRegionModal, setShowRegionModal] = useState(false);
    const [created, setCreated] = useState(false);
    const [language, setLanguage] = useState("en");
    const [region, setRegion] = useState(0);

    const handleProfile = () => {
        localStorage.setItem(`${walletAddress}_region`, region);
        updateProfile({account: userData.account, nickname: "user", language, region});
    }

    const handleLanguage = () => {
        setCookie("language", language);
        i18n.changeLanguage(language);
        setShowLanguageModal(false);
    }

    const handleCreate = async () => {
        await createUser();
        const newUserData = await getUser(wallet);
        setCreated(true);
        setLanguage(newUserData.language);
        setRegion(newUserData.region);
    }

    const handleImport = (e) => {
        if (checkDevice()) {
            toast.error("This feature is only available in the Mobile App or Desktop version");
            return;
        }
        const reader  = new FileReader();
        const file = e.target.files[0];
        const textFile = /text.*/;
        if (file.type.match(textFile)) {
            reader.onload = function (event) {
                const allFile = event.target.result;
                console.log(allFile)
                const publicStartIndex = allFile.indexOf("-----BEGIN PUBLIC KEY-----");
                const publicEndIndex = allFile.indexOf("-----END PUBLIC KEY-----");
                const publicKey = allFile.substring(publicStartIndex, publicEndIndex) + "-----END PUBLIC KEY-----";
                localStorage.setItem("publicKey", publicKey);
                const privateStartIndex = allFile.indexOf("-----BEGIN RSA PRIVATE KEY-----");
                const privateEndIndex = allFile.indexOf("-----END RSA PRIVATE KEY-----");
                const privateKey = allFile.substring(privateStartIndex, privateEndIndex) + "-----END RSA PRIVATE KEY-----";
                localStorage.setItem("privateKey", privateKey);
                toast.success('Import succeed.');
            }
        } else {
            toast.error('Wrong file.');
            console.log("wrong file")
        }
        reader.readAsText(file);
    }
    
    const handleExport = () => {
        if (checkDevice()) {
            toast.error("This feature is only available in the Mobile App or Desktop version");
            return;
        }
        const solcashKey = new Blob([localStorage.getItem("publicKey").toString() + localStorage.getItem("privateKey").toString()], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(solcashKey, "solcash_key.txt");
    }

    useEffect(() => {
        if (userData.account) {
            setCreated(true);
            setLanguage(cookies.language);
            setRegion(userData.region);
        }
    }, [userData])

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 text-white">
                <div className="text-2xl font-bold mt-12 mb-2 text-left">{t("Account Settings")}</div>
                <div className="text-sm text-second-theme mb-10 text-left">{t("Customize your account here")}</div>
                {
                    isLoading
                        ? <div className="w-full flex items-center justify-center">
                            <FadeLoader loading color='white' />
                        </div>
                        : walletAddress 
                            ? 
                                !created
                                    ? <div className="">
                                        <button onClick={handleCreate} className="px-8 py-1 rounded-lg border-2 border-gray-500 text-white">{t("Create Account")}</button>
                                    </div>
                                    :<>
                                        <div className="px-8 py-4 rounded-lg bg-first-theme flex items-start md:items-center justify-between mb-4 flex-col md:flex-row">
                                            <div className="flex items-center mb-2">
                                                <div className="p-2 bg-black green-font rounded-full text-2xl mr-4"><SiLetsencrypt /></div>
                                                <div className="text-left">
                                                    <div className="mb-2">{t("MansaTrade keys")}</div>
                                                    <div className="text-sm text-second-theme">{t("Export your private and public key in case you want to clear your cookies but still want to keep your buy orders")}</div>
                                                </div>
                                            </div>
                                            <div className="w-full md:w-auto flex justify-end">
                                                <div className="px-8 py-1 mb-2 rounded-lg border-2 border-gray-500 text-white">
                                                    <label htmlFor="files">{t("Import")}</label>
                                                    <input className="hidden" id="files" type="file" onChange={handleImport} />
                                                </div>
                                                <button onClick={handleExport} className="px-8 py-1 mb-2 rounded-lg border-2 border-gray-500 text-white ml-2">{t("Export")}</button>
                                            </div>
                                        </div>
                                        <div className="px-8 py-4 rounded-lg bg-first-theme flex items-start md:items-center justify-between mb-4 flex-col md:flex-row">
                                            <div className="flex items-center mb-2">
                                                <div className="p-2 bg-black green-font rounded-full text-2xl mr-4"><AiFillFlag /></div>
                                                <div className="text-left">
                                                    <div className="mb-2">{t("Change your language")}</div>
                                                    <div className="text-sm text-second-theme">{t("Change your preferred language")}</div>
                                                </div>
                                            </div>
                                            <div className="w-full md:w-auto flex justify-end">
                                                <button onClick={() => setShowLanguageModal(true)} className="px-8 py-1 mb-2 rounded-lg border-2 border-gray-500 text-white">{t("Change")}</button>
                                            </div>
                                        </div>
                                        <div className="px-8 py-4 rounded-lg bg-first-theme flex items-start md:items-center justify-between flex-col md:flex-row mb-8">
                                            <div className="flex items-center mb-1">
                                                <div className="p-2 bg-black green-font rounded-full text-2xl mr-4"><RiGlobalFill /></div>
                                                <div className="text-left">
                                                    <div className="mb-2">{t("Change your region")}</div>
                                                    <div className="text-sm text-second-theme">{t("Change your preferred region")}</div>
                                                </div>
                                            </div>
                                            <div className="w-full md:w-auto flex justify-end mb-1">
                                                <button onClick={() => setShowRegionModal(true)} className="px-8 py-1 rounded-lg border-2 border-gray-500 text-white">{t("Change")}</button>
                                            </div>
                                        </div>
                                    </>
                                    : <div className="py-16 border-2 border-first-theme rounded-lg text-2xl">
                                        {t("Please connect your wallet")}
                                    </div>
                }
                {/* <NicknameModal 
                    isShown={showNicknameModal} 
                    setShown={setShowNicknameModal} 
                    nickname={nickname} 
                    setNickname={setNickname} 
                    handleModal={handleProfile} /> */}
                <LanguageModal 
                    isLoading={isLoading}
                    isShown={showLanguageModal} 
                    setShown={setShowLanguageModal}
                    language={language}
                    setLanguage={setLanguage} 
                    handleModal={handleLanguage} />
                <RegionModal 
                    isLoading={isLoading}
                    isShown={showRegionModal} 
                    setShown={setShowRegionModal}
                    region={region}
                    setRegion={setRegion} 
                    handleModal={handleProfile} />
            </div>
        </>
    );
}

export default AccountWrapper;