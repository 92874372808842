import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FadeLoader } from 'react-spinners';
import { useWallet } from "@solana/wallet-adapter-react";
import Navbar from "../components/Navbar";
import PaymentContainer from "../containers/Order/sell/PaymentContainer";
import ReleaseContainer from "../containers/Order/sell/ReleaseContainer";
import CompleteContainer from "../containers/Order/sell/CompleteContainer";
import CancelContainer from "../containers/Order/sell/CancelContainer";
import TransferContainer from "../containers/Order/buy/TransferContainer";
import PendingContainer from "../containers/Order/buy/PendingContainer";
import BuyCompleteContainer from "../containers/Order/buy/CompleteContainer";
import BuyCancelContainer from "../containers/Order/buy/CancelContainer";
import ChatContainer from "../containers/ChatContainer";
import EthChatContainer from "../containers/ETHChatContainer";
import useOrder from "../utils/orders";
import useEthOrder from "../utils/eth/orders";
import { AppContext } from "../utils/AppContext";

const OrderPageWrapper = () => {
    const { chainID } = useContext(AppContext);
    const { isLoading,  getOrderInfo, confirmPayment, createDispute, thumbUser, cancelOrder, buyerConfirm, setIsLoading } = useOrder();
    const { isEthLoading,  getEthOrderInfo, confirmEthPayment, createEthDispute, ethThumbUser, cancelEthOrder, buyerEthConfirm, setIsEthLoading } = useEthOrder();

    return (
        <OrderPage 
            chainID={chainID}
            isLoading={!chainID ? isLoading : isEthLoading}
            getOrderInfo={!chainID ? getOrderInfo : getEthOrderInfo}
            confirmPayment={!chainID ? confirmPayment : confirmEthPayment}
            createDispute={!chainID ? createDispute : createEthDispute}
            thumbUser={!chainID ? thumbUser : ethThumbUser}
            cancelOrder={!chainID ? cancelOrder : cancelEthOrder}
            buyerConfirm={!chainID ? buyerConfirm : buyerEthConfirm}
            setIsLoading={!chainID ? setIsLoading : setIsEthLoading} />
    );
}

const OrderPage = ({chainID, isLoading,  getOrderInfo, confirmPayment, createDispute, thumbUser, cancelOrder, buyerConfirm, setIsLoading}) => {
    const defaultOrderdata = {
        buyer: "XXXXXXXX",
        thumbsUp: 0,
        thumbsDown: 0,
        rate: 0,
        timeLimit: 0,
        sellAmount: 0,
        fiatAmount: 0,
        fiat: "",
        tokenName: "",
        moneyFee: 0,
        fee: 1,
        status: 0,
        paymentOption: 0
    }
    const path = useLocation().pathname;
    const { t } = useTranslation();
    const pathArr = path.split("/");
    const orderType = pathArr[2];
    const orderAccount = pathArr[3];
    const [orderData, setOrderData] = useState(defaultOrderdata);
    const [step, nextStep] = useState(false);
    const [clockId, setClockId] = useState();
    const [checkId, setCheckId] = useState();
    const [period, setPeriod] = useState([0, 0, 0, 0]);
    const [remainTime, setRemainTime] = useState(10000);

    console.log(isLoading)

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            const newOrderData = await getOrderInfo(orderAccount, orderType);
            setIsLoading(false);
            setOrderData(newOrderData);
            const now = Math.floor(new Date() / 1000);
            let newRemainTime = newOrderData.timeLimit * 60 - (now - newOrderData.createdAt);
            setRemainTime(newRemainTime)
            if (newRemainTime < 0) {
                setPeriod([0, 0, 0, 0]);
            }
            if (newOrderData.status === 0 && newRemainTime > 0 && newRemainTime < newOrderData.timeLimit * 60 && !newOrderData.buyerConfirm) {
                const newClockId = setInterval(() => {
                    if (newRemainTime >= 0) {
                        const newPeriod = getRemainTime(newRemainTime);
                        setPeriod(newPeriod);
                        newRemainTime--;
                        setRemainTime(newRemainTime)
                    } else {
                        clearInterval(clockId);
                    }
                }, 1000);
                setClockId(newClockId)
            }
        })();
    }, [])

    const getRemainTime = (remainTime) => {
        const mins = Math.floor(remainTime / 60);
        const seconds = remainTime % 60;

        return [Math.floor(mins / 10), mins % 10, Math.floor(seconds / 10), seconds % 10];
    }
    
    const releaseToken = async (orderAccount) => {
        const result = await confirmPayment(orderAccount);
        const newOrderData = await getOrderInfo(orderAccount, orderType);
        setOrderData(newOrderData);
        return result;
    }

    const handleThumb = async (thumbUp) => {
        await thumbUser(thumbUp, orderAccount, orderData.userAccount);
        const newOrderData = await getOrderInfo(orderAccount, orderType);
        setOrderData(newOrderData);
    }

    const handleDispute = (reason, explain) => {
        createDispute(reason, explain, orderAccount);
    }

    const handleCancelOrder = async () => {
        await cancelOrder(orderAccount);
        const newOrderData = await getOrderInfo(orderAccount, orderType);
        setOrderData(newOrderData);
    }

    const handleBuyerConfirm = async () => {
        await buyerConfirm(orderAccount);
        const newOrderData = await getOrderInfo(orderAccount, orderType);
        setOrderData(newOrderData);
    };

    useEffect(() => {
        if (orderData.status === 0 && !checkId) {
            const intervalId = setInterval(async () => {
                console.log("getOrderInfo")
                const newOrderData = await getOrderInfo(orderAccount, orderType);
                setOrderData(newOrderData);
            }, 30000); 
            setCheckId(intervalId);
        } 
        if (orderData.status > 0) {
            console.log("cancel check info", checkId)
            clearInterval(checkId);
          }
    }, [orderData]);

    useEffect(() => {
        if (localStorage.getItem("connected") === "false") {
            window.location.href = "/orders";
        }
    }, [localStorage.getItem("connected")]);

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 flex flex-col text-white my-12 ">
                <div className="relative w-full flex flex-col lg:flex-row relative">
                    {
                        isLoading &&
                            <div className="w-full h-full flex items-center justify-center absolute bg-white bg-opacity-20 rounded-lg">
                                <FadeLoader loading color='white' />
                            </div>
                    }
                    {
                        localStorage.getItem("connected")
                            ? <>
                                <div className="flex-1">
                                    {
                                        orderType === "sell" && (
                                            orderData.status === 0
                                                ? !step
                                                    ? <PaymentContainer 
                                                        isLoading={isLoading}
                                                        orderData={orderData} 
                                                        nextStep={nextStep} 
                                                        period={period}
                                                        remainTime={remainTime}
                                                        handleCancelOrder={handleCancelOrder} />
                                                    : <ReleaseContainer 
                                                        isLoading={isLoading} 
                                                        orderData={orderData} 
                                                        releaseToken={releaseToken}
                                                        handleDispute={handleDispute} />
                                                : orderData.status === 1
                                                    ? <CompleteContainer orderData={orderData} handleThumb={handleThumb} />
                                                    : <CancelContainer orderData={orderData} />
                                        )
                                    }
                                    {
                                        orderType === "buy" && (
                                            orderData.status === 0
                                                ? !orderData.buyerConfirm 
                                                    ? <TransferContainer 
                                                        orderData={orderData} 
                                                        handleBuyerConfirm={handleBuyerConfirm} 
                                                        period={period}
                                                        handleCancelOrder={handleCancelOrder} />
                                                    : <PendingContainer 
                                                        isLoading={isLoading} 
                                                        orderData={orderData} 
                                                        period={[0, 0, 0, 0]}
                                                        handleDispute={handleDispute} />
                                                : orderData.status === 1
                                                    ? <BuyCompleteContainer orderData={orderData} />
                                                    : <BuyCancelContainer orderData={orderData} />
                                        )
                                    }
                                </div>
                                {
                                !chainID
                                    ? <ChatContainer data={orderData} />
                                    : <EthChatContainer data={orderData} />
                            }
                            </>
                            : <div className="w-full flex flex-col">
                                <div className="py-8 border-2 border-first-theme rounded-lg text-xl">{t("Please connect your wallet")}</div>
                            </div>
                    }
                </div>
            </div>
        </>
    );
}

export default OrderPageWrapper;