import { useTranslation } from "react-i18next";

const OfferDetailInfo = (props) => {
    const { offerData } = props;
    const { t } = useTranslation();

    return (
        <div className="flex flex-wrap justify-between text-left py-8 border-t-2 border-b-2 border-first-theme mb-8">
            <div className="text-sm text-second-theme mb-2 mr-2">
                <div className="">{t("Trade limit")}</div>
                <div className="">{t("Min")} <span className="text-base text-white">{offerData.minLimit}</span> - {t("Max")}<span className="text-base text-white"> {offerData.maxLimit}</span></div>
            </div>
            <div className="mb-2 mr-2">
                <div className="text-sm text-second-theme">{t("Buyer rate")}</div>
                <div className="">{offerData.rate} {offerData.fiat.toUpperCase()}</div>
            </div>
            <div className="mb-2 mr-2">
                <div className="text-sm text-second-theme">{t("Trade time limit")}</div>
                <div className="">{offerData.timeLimit} min</div>
            </div>
            <div className="mb-2 mr-2">
                <div className="text-sm text-second-theme">{t("Transaction fee")}</div>
                <div className="">{offerData.fee} %</div>
            </div>
        </div>
    );
}

export default OfferDetailInfo;