export const LANGUAGES = [{
    title: "English",
    value: "en"
}, {
    title: "Chinese",
    value: "ch"
}, {
    title: "French",
    value: "fr"
}, {
    title: "Korean",
    value: "ko"
}, {
    title: "Russian",
    value: "ru"
}, {
    title: "Turkish",
    value: "tk"
}, {
    title: "Vietnam",
    value: "vt"
}, {
    title: "Moroccan",
    value: "mr"
}];

export const REGIONS = [
    "Australia",
    "Canada",
    "China (中国)",
    "Ghana (Gaana)",
    "Japan (日本)",
    "Kenya",
    "Nigeria",
    "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    "Singapore",
    "South Africa",
    "Spain (España)",
    "Taiwan (台灣)",
    "Tanzania",
    "Uganda",
    "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    "United Kingdom",
    "United States",
    "Vietnam (Việt Nam)",
    "Yemen (‫اليمن‬‎)",
    "Zimbabwe"
]