import { useContext, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useTranslation } from "react-i18next";
import { FiRefreshCcw } from "react-icons/fi";
import { RiFilter2Fill } from "react-icons/ri";
import { AiOutlineLeft, AiOutlineRight, AiOutlineQuestionCircle } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import useOffer from "../utils/offers";
import GuideComponent from "../containers/GuideComponent";
import SelectComponent from "../components/SelectComponent";
import InputComponent from "../components/InputComponent";
import OfferTable from "../components/OfferTable";
import Footer from '../components/Footer';
import { CRYPTO_FILTER_VALUES, FIAT_FILTER_VALUES, REGION_VALUES, PAYMENT_VALUES } from "../constants/offers";
import { ETH_CRYPTO_FILTER_VALUES } from "../constants/eth/offers";
import useEthOffer from "../utils/eth/offers";
import Navbar from "../components/Navbar";
import OfferTableMobile from "../components/OfferTableMobile";
import { AppContext } from "../utils/AppContext";

const OfferPage = () => {
    const { chainID } = useContext(AppContext);
    const { isLoading, allOffers, refresh, setRefresh } = useOffer();
    const { ethIsLoading, allEthOffers, ethRefresh, setEthRefresh } = useEthOffer();

    return <OfferList
        cryptoFilterValues = {!chainID ? CRYPTO_FILTER_VALUES : ETH_CRYPTO_FILTER_VALUES[chainID]} 
        isLoading = {!chainID ? isLoading : ethIsLoading}
        allOffers = {!chainID ? allOffers : allEthOffers}
        refresh = {!chainID ? refresh : ethRefresh}
        setRefresh = {!chainID ? setRefresh : setEthRefresh} />
}

const OfferList = ({isLoading, allOffers, refresh, setRefresh, cryptoFilterValues}) => {
    const { t } = useTranslation();
    const { chainState, chainID } = useContext(AppContext);
    const [selectedCryptoIndex, setSelectedCryptoIndex] = useState(0);
    const [selectedFiatIndex, setSelectedFiatIndex] = useState(0);
    const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(0);
    const [selectedRegionIndex, setSelectedRegionIndex] = useState(0);
    const [amount, setAmount] = useState();
    const [searchKey, setSearchKey] = useState();
    const [checkStatus, setCheckStatus] = useState(false);
    const [checkOnline, setCheckOnline] = useState(false);
    const [offerList, setOfferList] = useState([]);
    const [pageRange, setPageRage] = useState(0);
    const [pageNum, setPageNum] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const wallet = useWallet();

    const handleChange = (e) => {
        setCheckStatus(e.target.checked);
    }

    const handleOnline = e => {
        setCheckOnline(e.target.checked);
    }

    const handlePage = (e) => {
        setPageNum(e.selected);
    }

    const handleRefresh = () => {
        setRefresh(true);
    }

    useEffect(() => {
        setOfferList(allOffers);
        const newPageRange = Math.floor(allOffers.length / 10) + 1;
        setPageRage(newPageRange);
    }, [allOffers])

    useEffect(() => {
        let filterOffers = [...allOffers];
        console.log(allOffers)
        if (checkStatus) {
            filterOffers = filterOffers.filter(item => item.verified === checkStatus);
        }
        if (checkOnline) {
            filterOffers = filterOffers.filter(item => item.onlineStatus === checkOnline);
        }
        if (selectedCryptoIndex !== 0) {
            if (selectedCryptoIndex === 1) {
                filterOffers = filterOffers.filter(item => item.main);
            } else {
                filterOffers = filterOffers.filter(item => (item.token.toString() === cryptoFilterValues[selectedCryptoIndex].value) && !item.sol);
            }
        }
        if (selectedFiatIndex !== 0) {
            filterOffers = filterOffers.filter(item => item.fiat === FIAT_FILTER_VALUES[selectedFiatIndex].value);
        }
        if (selectedPaymentIndex !== 0) {
            filterOffers = filterOffers.filter(item => item.paymentOptions.indexOf(PAYMENT_VALUES[selectedPaymentIndex].value) > -1);
        }
        if (selectedRegionIndex !== 0) {
            filterOffers = filterOffers.filter(item => item.region === selectedRegionIndex);
        }
        if (searchKey) {
            filterOffers = filterOffers.filter(item => item.owner.toString().toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
        }
        if (amount) {
            filterOffers = filterOffers.filter(item => item.tokenAmount >= amount * 1)
        }
        filterOffers = filterOffers.filter((item, index) => index >= 10 * pageNum && index < (pageNum + 1) * 10);
        setOfferList(filterOffers);
        
    }, [allOffers, selectedCryptoIndex, selectedFiatIndex, selectedPaymentIndex, selectedRegionIndex, checkStatus, pageNum, checkOnline, searchKey, amount]);

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 text-white">
                <div className="flex flex-row justify-between items-center md:items-start md:flex-col">
                    <div className="text-2xl font-bold mt-12 mb-10 text-left">{t("Available Offers")}</div>
                    <div className="w-none md:w-full flex justify-between items-center md:items-start mb-0 md:mb-8">
                        <div className="flex-1 hidden md:flex flex-wrap justify-between">
                            <SelectComponent title={t("Filter by Crypto")} values={cryptoFilterValues} value={selectedCryptoIndex} onChange={setSelectedCryptoIndex} search />
                            <SelectComponent title={t("Filter by Fiat")} values={FIAT_FILTER_VALUES} value={selectedFiatIndex} onChange={setSelectedFiatIndex} search />
                            <SelectComponent title={t("Filter by Payment Method")} values={PAYMENT_VALUES} value={selectedPaymentIndex} onChange={setSelectedPaymentIndex} search/>
                            <InputComponent 
                                type="string"
                                title={<div className="flex items-center">{t("Search")} <AiOutlineQuestionCircle className="ml-2" /></div>}
                                placeHolder={t("Enter text here")} 
                                value={searchKey} 
                                onChange={setSearchKey}/>
                            <InputComponent 
                                title={<div className="flex items-center">{t("Enter Amount")} <AiOutlineQuestionCircle className="ml-2" /></div>}
                                placeHolder={t("Enter amount here")} 
                                value={amount} 
                                onChange={setAmount}/>
                            <SelectComponent title={t("Region")} values={REGION_VALUES} value={selectedRegionIndex} onChange={setSelectedRegionIndex} search/>
                            <label className="flex items-center pt-4 main-checkbox">
                                <input onChange={handleOnline} type="checkbox" className="black-checkbox w-4 h-4 mr-2 border border-gray-300 rounded" />
                                <span className="">{t("Online")}</span>
                            </label>
                            <label className="flex items-center pt-4 main-checkbox">
                                <input onChange={handleChange} type="checkbox" className="black-checkbox w-4 h-4 mr-2 border border-gray-300 rounded" />
                                <span className="">{t("Only verified users")}</span>
                            </label>
                        </div>
                        <div className="flex ml-8">
                            <div className="flex flex-col cursor-pointer mr-2 block md:hidden" onClick={() => setShowFilter(!showFilter)}>
                                <div className="px-4 py-2 border-2 border-first-theme rounded-lg flex items-center justify-between">
                                    <RiFilter2Fill size={24} />
                                </div>
                            </div>
                            <div className="flex flex-col cursor-pointer" onClick={handleRefresh}>
                                <div className="text-sm text-second-theme mb-2 text-left hidden md:block">{t("Refresh")}</div>
                                <div className="px-4 py-2 border-2 border-first-theme rounded-lg flex items-center justify-between">
                                    <FiRefreshCcw className={`${refresh && "animate-spin"}`} size={24} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${showFilter ? "flex" : "hidden"}  md:hidden flex-wrap justify-between mb-4`}>
                    <SelectComponent title={t("Filter by Crypto")} values={cryptoFilterValues} value={selectedCryptoIndex} onChange={setSelectedCryptoIndex} search/>
                    <SelectComponent title={t("Filter by Fiat")} values={FIAT_FILTER_VALUES} value={selectedFiatIndex} onChange={setSelectedFiatIndex} search/>
                    <SelectComponent title={t("Filter by Payment Method")} values={PAYMENT_VALUES} value={selectedPaymentIndex} onChange={setSelectedPaymentIndex} search/>
                    <InputComponent 
                        title={<div className="flex items-center">{t("Enter Amount")} <AiOutlineQuestionCircle className="ml-2" /></div>}
                        placeHolder={t("Enter amount here")} 
                        value={amount} 
                        onChange={setAmount}/>
                    <SelectComponent title={t("Region")} values={REGION_VALUES} value={selectedRegionIndex} onChange={setSelectedRegionIndex} search/>
                    <label className="flex items-center pt-4 main-checkbox">
                        <input onChange={handleOnline} type="checkbox" className="black-checkbox w-4 h-4 mr-2 border border-gray-300 rounded" />
                        <span className="">{t("Online")}</span>
                    </label>
                    <label className="flex items-center pt-4 main-checkbox">
                        <input onChange={handleChange} type="checkbox" className="black-checkbox w-4 h-4 mr-2 border border-gray-300 rounded" />
                        <span className="">{t("Only verified users")}</span>
                    </label>
                </div>
                <div className="">
                    <div className="hidden lg:block">
                        <OfferTable chainID={chainID} walletAddress={!chainID ? wallet.publicKey : chainState.address} datas={offerList} isLoading={isLoading} />
                    </div>
                    <div className="block lg:hidden">
                        <OfferTableMobile chainID={chainID} walletAddress={!chainID ? wallet.publicKey : chainState.address} datas={offerList} isLoading={isLoading} />
                    </div>
                </div>
                {
                    offerList.length > 0 &&
                        <div className="pagination mb-16">
                            <ReactPaginate 
                                breakLabel="..."
                                nextLabel={<AiOutlineRight size={24} />}
                                onPageChange={handlePage}
                                pageRangeDisplayed={3}
                                pageCount={pageRange}
                                previousLabel={<AiOutlineLeft size="24" />}
                                renderOnZeroPageCount={null} />
                        </div>
                }
            </div>
            <GuideComponent />
            <Footer />
        </>
    );
}

export default OfferPage;