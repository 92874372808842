import { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import { AppContext } from "../AppContext";
import { CONTRACT_ADDRESS } from "../../constants/env";
import ABI from "../../constants/abi.json";

const useAccount = () => {
	const { chainID, chainState} = useContext(AppContext);
	const [isEthLoading, setIsEthLoading] = useState(false);
    const [ethUserData, setEthUserData] = useState({});

    const getEthUser = async () => {
		const web3 = new Web3(chainState.provider);
		const contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS[chainID]);
        console.log(chainState.address)
        const userData = await contract.methods.getUser(chainState.address.toString()).call();
        console.log("userData", userData)

        return {...userData, 
			account: userData.user_address !== "0x0000000000000000000000000000000000000000" ? userData.user_address : null,
			region: userData.region * 1};
    }

    const createEthUser = async () => {
        if (!chainID || !chainState.provider) {
			return;
		}
		const web3 = new Web3(chainState.provider);
		const contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS[chainID]);
        await contract.methods.createUser().send({from: chainState.address});
        const userInfo = await contract.methods.getUser(chainState.address).call();

        return userInfo.user_address;
    }
    
    const updateEthProfile = async ({region}) => {
        if (!chainID || !chainState.provider) {
			return;
		}
		const web3 = new Web3(chainState.provider);
		const contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS[chainID]);
        await contract.methods.updateUser(region).send({from: chainState.address});
    }

	useEffect(() => {
		if (!chainID || !chainState.provider) {
			return;
		}
		(
			async () => {
				setIsEthLoading(true);
				const newUserData = await getEthUser();
				setEthUserData(newUserData);
				setIsEthLoading(false);
			}
		)();
	}, [chainID, chainState]);

	return { isEthLoading, ethUserData, updateEthProfile, createEthUser, getEthUser };
}

export default useAccount;