import { useEffect, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import SelectWrapper from "./SelectWrapper";

const SelectComponent = (props) => {
    const { title, value, values, placeHolder, cssClass, onChange, color, disabled, position, search } = props;
    const [showChild, setShowChild ] = useState(false);
    const [searchKey, setSearchKey] = useState();
    const [filterValues, setFilterValues] = useState([]);

    const handleClick = (index) => {
        console.log(index)
        onChange(index);
        setShowChild(false);
    }

    const outSideClickFunc = () => {
        setShowChild(false)
    }

    const handleChange = (e) => {
        setSearchKey(e.target.value)
    }

    useEffect(() => {
        if (searchKey) {
            let newFilterValues = values.filter(item => item.title.toLowerCase().indexOf(searchKey.toLowerCase()) > -1);
            setFilterValues(newFilterValues);
        } else {
            setFilterValues(values)
        }
    }, [searchKey, values])

    return (
        <div className={`mr-2 ${cssClass} mb-2`}>
            {
                title !== undefined && <div className="text-sm text-second-theme mb-2 text-left">{title}</div>
            }
            <SelectWrapper outSideClickFunc={outSideClickFunc}>
                <div className="relative">
                    <div className={`flex ${color === "black" ? "bg-black" : "bg-first-theme"} px-4 py-2 rounded-lg items-center justify-between cursor-pointer`}
                        onClick={() => setShowChild(!showChild)}
                    >
                        <div className="flex items-center mr-16">
                            {
                                value !== -1
                                    ? <>
                                        {
                                            Object.keys(values[value]).indexOf("img") > -1 &&
                                            <img className="w-5 h-5 mr-2" src={values[value].img} alt="crypto" />
                                        }
                                        <div className="" >{values[value].title}</div>
                                    </>
                                    : placeHolder
                                        ? <div className="" >{placeHolder}</div>
                                        : <></>

                            }
                        </div>
                        <div className=""><AiOutlineDown size={12} /></div>
                    </div>
                    <div className={`absolute ${position ? "bottom-full mb-1" : "mt-1"} w-full ${color === "black" ? "bg-black" : "bg-first-theme"} rounded-lg flex flex-col z-50 ${!disabled && showChild ? "block" : "hidden"}`}>
                        {
                            search && <input className="m-2 p-1 bg-transparent border-2 border-gray-500 rounded-lg" onChange={handleChange} />
                        }
                        <div className="max-h-96 overflow-auto">
                            {
                                filterValues.length > 0
                                    ? filterValues.map((item, index) => 
                                        <div key={index} className="flex px-4 py-2 items-center cursor-pointer hover:bg-gray-500" onClick={() => handleClick(values.indexOf(item))}>
                                            {
                                                item.img &&
                                                <img className="w-5 h-5 mr-2" src={item.img} alt="crypto" />
                                            }
                                            <div className="text-left">{item.title}</div>
                                        </div>
                                        )
                                    : <div className="p-2 text-second-theme">No Result</div>
                            }
                        </div>
                    </div>
                </div>
            </SelectWrapper>
        </div>
    );
}

export default SelectComponent;