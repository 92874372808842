import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiFillCheckCircle } from "react-icons/ai";
import { BsChevronCompactRight } from "react-icons/bs";
import { PAYMENT_OFFER_VALUES } from "../../../constants/offers";

const BuyCompleteContainer = (props) => {
    const { orderData } = props;
    const { t } = useTranslation();

    return (
        <>
            <div className="flex flex-col mb-10">
                <div className="flex justify-between">
                    <a href="/orders" className="text-2xl flex items-center mb-2"><AiOutlineArrowLeft className="mr-4"/>{t("Order")} {orderData.id}</a>
                    <div className="p-2 rounded-lg bg-first-theme green-font flex items-center"><AiFillCheckCircle className="mr-2" />{t("Completed")}</div>
                </div>
                <div className="flex justify-between">
                    <div className="text-sm text-second-theme">{t("The seller has marked this order as completed.")}</div>
                    <div className="text-sm text-second-theme"></div>
                </div>
            </div>
            <div className="flex items-center mb-8 md:mb-16 text-second-theme bg-first-theme rounded-tr-lg rounded-tl-lg">
                <div className="flex-1 border-b-2 border-gray-500 py-2">{t("Transfer payment to seller")}</div>
                <BsChevronCompactRight size={24} />
                <div className="flex-1 border-b-2 border-gray-500 py-2">{t("Transaction pending")}</div>
                <BsChevronCompactRight size={24} />
                <div className="flex-1 border-b-2 green-border py-2 text-white">{t("Completed")}</div>
            </div>
            <div className="mb-8">
                <div className="text-left mb-4 md:mb-8">{t("Trade Information")}</div>
                <div className="flex flex-wrap justify-between text-left pt-4 md:pt-8 border-t-2 border-first-theme mb-8">
                    <div className="">
                        <div className="text-sm text-second-theme">{t("Order Number")}</div>
                        <div className="">{orderData.id}</div>
                    </div>
                    <div className="">
                        <div className="text-sm text-second-theme">{t("Date")}</div>
                        <div className="">{orderData.createdAtFormat}</div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between text-left pb-4 md:pb-8 border-b-2 border-first-theme mb-8">
                    <div className="mb-2">
                        <div className="text-sm text-second-theme">{t("Amount to pay")}</div>
                        <div className="">{orderData.fiatAmount.toLocaleString("en-US")}</div>
                    </div>
                    <div className="mb-2">
                        <div className="text-sm text-second-theme">{t("Rate")}</div>
                        <div className="">{orderData.rate.toLocaleString("en-US")} {orderData.fiat.toUpperCase()}</div>
                    </div>
                    <div className="mb-2">
                        <div className="text-sm text-second-theme">{t("Transaction fee %")}</div>
                        <div className="">{orderData.fee}%</div>
                    </div>
                    <div className="mb-2">
                        <div className="text-sm text-second-theme">{t("Transaction fee amount")}</div>
                        <div className="">{orderData.moneyFee} {orderData.tokenName.toUpperCase()}</div>
                    </div>
                    <div className="mb-2">
                        <div className="text-sm text-second-theme">{t("Crypto to receive after fees")}</div>
                        <div className="">{orderData.sellAmount.toLocaleString("en-US")} {orderData.tokenName.toUpperCase()}</div>
                    </div>
                </div>
            </div>
            <div className="text-left  mb-8">
                <div className="mb-2">{t("Payment Method")}</div>
                <div className="text-sm text-second-theme mb-4">{t("Payment method used for this trade")}</div>
                <div className="p-4 rounded-lg bg-first-theme flex flex-wrap mb-4">
                    <div className="text-sm w-6/12  py-2">
                        <div className="text-second-theme mb-2">{t("Payment Method")}</div>
                        <div className="">{PAYMENT_OFFER_VALUES[orderData.paymentOption].title}</div>
                    </div>
                    <div className="text-sm w-6/12  py-2">
                        <div className="text-second-theme mb-2">{t("Account Name")}</div>
                        <div className="">{orderData.accountName}</div>
                    </div>
                    <div className="text-sm w-6/12  py-2">
                        <div className="text-second-theme mb-2">{t("Reference Message")}</div>
                        <div className="">{orderData.fullId}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BuyCompleteContainer;