import { useState, useEffect } from "react";
import * as anchor from "@project-serum/anchor";
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import {
    Keypair,
    PublicKey,
    Transaction,
} from "@solana/web3.js";
import toast from 'react-hot-toast';
import { USERINFO_SIZE, CONTRACT_IDL } from '../constants/contract';
import { NEXT_PUBLIC_SOLANA_RPC_HOST, NEXT_PUBLIC_CONTRACT_ID, NEXT_PUBLIC_POOL_ID } from '../constants/env';

const rpcHost = NEXT_PUBLIC_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);
const programId = new PublicKey(NEXT_PUBLIC_CONTRACT_ID);
const pool = new PublicKey(NEXT_PUBLIC_POOL_ID);
const idl = CONTRACT_IDL;
const confirmOption = {
    commitment : 'finalized',
    preflightCommitment : 'finalized',
    skipPreflight : false
}

async function sendAllTransaction(transactions, wallet, conn, signers){
    try {
        let commitment = "max"
        let unsignedTxns = []
        let block = await conn.getRecentBlockhash(commitment);
        for(let i = 0; i < transactions.length; i++){
            let transaction = transactions[i]
            transaction.recentBlockhash = block.blockhash;
            transaction.setSigners(wallet.publicKey, ...signers.map(s => s.publicKey));
            if(signers.length !== 0) await transaction.partialSign(...signers);
            unsignedTxns.push(transaction);
        }
        const signedTxns = await wallet.signAllTransactions(unsignedTxns)
        for(let i=0;i<signedTxns.length;i++){
            try {
              console.log(i)
              let hash = await conn.sendRawTransaction(await signedTxns[i].serialize())
              await conn.confirmTransaction(hash)
              // console.log(hash)
            } catch(error) {
              console.log(error)
              return {result: false, number: i, kind: 1}
            }
        }
        toast.success('Transaction succeed.');
        return {result: true, number: 0, kind: 0}
    } catch (error) {
        console.log(error);
        toast.error('Transaction failed. Please try again.');
        return {result: false};
    }
}

const _updateProfile = async (props, wallet) => {
    const {account, nickname, language, region } = props;
    console.log(props)
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let transactionSet = [];
    let transaction = [];
    let signers = [];

    transaction.push(
        program.instruction.updateUser(
            nickname,
            new anchor.BN(language),
            new anchor.BN(region),
            {
            accounts:{
                owner : wallet.publicKey,
                pool : pool,
                userInfo: account.toString(),
                systemProgram : anchor.web3.SystemProgram.programId,
            }
        })
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
            console.log(bigTx)
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }

    await sendAllTransaction(transactionSet, wallet, connection, signers)
}

const _createUser = async (wallet) => {
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let transactionSet = [];
    let transaction = [];
    let signers = [];
    const userInfo = Keypair.generate();
    
    signers.push(userInfo);

    transaction.push(
        program.instruction.createUser({
            accounts:{
                owner : wallet.publicKey,
                pool : pool,
                userInfo: userInfo.publicKey,
                user: wallet.publicKey,
                systemProgram : anchor.web3.SystemProgram.programId,
            }
        })
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
            console.log(bigTx)
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }

    await sendAllTransaction(transactionSet, wallet, connection, signers);

    return userInfo.publicKey;
}

const useAccount = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const anchorWallet = useAnchorWallet();

    const createUser = async () => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _createUser(anchorWallet);

        setIsLoading(false);
        return result;
    }

    const updateProfile = async (props) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _updateProfile(props, anchorWallet);

        setIsLoading(false);
        return result;
    }

    const getUser = async (wallet) => {
        const provider = new anchor.Provider(connection, wallet, anchor.Provider.defaultOptions());
        const program = new anchor.Program(idl, programId, provider);
        let user = {};
    
        try {
            const userResp = await connection.getProgramAccounts(programId,
                {
                    dataSlice: {
                        length: 0, 
                        offset: 0
                    },
                    filters: [
                        {
                            dataSize: USERINFO_SIZE
                        },
                        {
                            memcmp: {
                                offset: 8,
                                bytes: wallet.publicKey.toBase58()
                            }
                        },
                        {
                            memcmp: {
                                offset: 40,
                                bytes: pool.toBase58()
                            }
                        }
                    ]
                }
            );
            const userInfo = await program.account.userInfo.fetch(userResp[0].pubkey);
            
            user = {
                account: userResp[0].pubkey,
                nickname: userInfo.nickname,
                language: userInfo.language,
                region: userInfo.region
            }
        } catch (error) {
            console.log(error);
        }
    
        return user;
    }

    useEffect(() => {
        (async () => {
            if (
                !anchorWallet ||
                !anchorWallet.publicKey ||
                !anchorWallet.signAllTransactions ||
                !anchorWallet.signTransaction
            ) {
                setIsLoading(false);
                return;
            }

            setUserData({
                account: anchorWallet.publicKey.toBase58(),
                region: localStorage.getItem(`${anchorWallet.publicKey.toBase58()}_region`)
            })
            const newUserData = await getUser(anchorWallet);
            setUserData(newUserData);
            setIsLoading(false);
        })();
    }, [anchorWallet]);

    return { isLoading, userData, updateProfile, createUser, getUser };

}

export default useAccount;