// import Blockscanchat from "blockscanchat-sdk";
import copy from "copy-to-clipboard";
import { RiThumbUpLine, RiThumbDownLine } from "react-icons/ri";
import { BiCopy } from "react-icons/bi";
import { HiBadgeCheck } from "react-icons/hi";

const EthChatContainer = (props) => {
    const { data } = props;

    const copyToClipboard = () => {
        copy(data.buyer)
    }

    return (
        <div className="w-full lg:w-5/12 right-4 pl-0 lg:pl-16 ">
            {/* <WalletChatWidget widgetState={{chatAddr: data.buyer, isOpen: data.buyer !== "XXXXXXXX"}} /> */}
            <div className="flex justify-between items-start mb-6">
                <div className="flex flex-col">
                    <div className="flex items-center">
                        <div className="mb-2 flex items-center">{data.buyer.slice(0, 7)}...{data.buyer.substring(data.buyer.length - 6)} {data.verified ? <HiBadgeCheck color="#68CEC7" className="ml-2" /> : ""}</div>
                        <BiCopy className="mb-2 ml-2 hover:text-blue-500 cursor-pointer" onClick={copyToClipboard} />
                    </div>
                    <div className="flex items-center">
                        <div className={`w-2 h-2 rounded-full ${data.online ? "green-bg" : "bg-red-500"} mr-2`}></div>
                        <div className="text-xs">{data.online ? "Online" : "Offline"}</div>
                    </div>
                </div>
                <div className="mb-2 flex">
                    <div className="flex items-center mr-4"><RiThumbUpLine color="#68CEC7" className="mr-2" />{data.thumbsUp}</div>
                    <div className="flex items-center"><RiThumbDownLine color="#E03B3B" className="mr-2" />{data.thumbsDown}</div>
                </div>
            </div>
            <iframe title="eth-chat" className="w-full h-auto md:h-80 overflow-auto-chat" src="https://chat.blockscan.com/start" />
        </div>
    );
};

export default EthChatContainer;