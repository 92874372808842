import MobileImg from "../assets/images/mobile.png";

const Mobile = () => {
    return (
        <div className="flex-1 flex flex-col md:flex-row items-center px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 py-8">
            <div className="flex-1 flex flex-col items-start text-white text-left pr-0 md:pr-16 mb-8">
                <div className="flex flex-col text-start font-bold text-4xl md:text-5xl">
                    <p className="mb-8">We Are Launching Our</p>
                    <p>Mobile App Soon</p>
                </div>
                <div className="text-second-theme py-4 mb-8">Get notified once we launch</div>
                <div className="relative w-96 mb-2">
                    <input className="w-full bg-first-theme rounded-lg px-6 py-3 pr-32" />
                    <div className="absolute right-4 ver-middle-img custom-gradient px-4 py-1 rounded-lg font-bold">Notify Me</div>
                </div>
                <div className="green-font italic">Don't worry, we won't spam you.</div>
            </div>
            <div className="">
                <img src={MobileImg} alt="img" />
            </div>
        </div>
    )
}

export default Mobile;