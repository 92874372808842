import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { HiArrowRight } from "react-icons/hi";
import { AiOutlinePlus, AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import { BsXLg } from "react-icons/bs";
import { FadeLoader } from 'react-spinners';
import GreenButton from "../../components/GreenButton";
import InputComponent from "../../components/InputComponent";
import SelectComponent from "../../components/SelectComponent";
import AddPaymentModal from "../../components/AddPaymentModal";
import SelectPaymentModal from "../../components/SelectPaymentModal";
import { CRYPTO_VALUES, FIAT_VALUES, TIME_VALUES, PAYMENT_OFFER_VALUES } from "../../constants/offers";
import { ETH_CRYPTO_VALUES } from "../../constants/eth/offers";
import { SOLANA_API_URL } from "../../constants/wallet";
import { AppContext } from "../../utils/AppContext";

const OptionContainer = (props) => {
    const { 
        changeStatus, 
        setCryptoValue, 
        setFiatValue, 
        setAmount, 
        setMinLimit, 
        setMaxLimit, 
        setPayments, 
        setTime, 
        setRate,
        amount, 
        minLimit, 
        maxLimit, 
        payments,
        rate, 
        time,
        isUpdate,
        cryptoValue,
        fiatValue,
        isLoading } = props;
    const { t } = useTranslation();
    const { chainID } = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const [showSelectModal, setShowSelectModal] = useState(false);
    const [method, setMethod] = useState();
    const [tokenPrice, setTokenPrice] = useState(0);
    const [cryptoValues, setCryptoValues] = useState(CRYPTO_VALUES);

    const handleMinLimit = (e) => {
        if (e.target.value > maxLimit) {
            setMaxLimit(e.target.value)
        }
    }

    const handleTotalAmount = (e) => {
        if (e.target.value > 1000) {
            setAmount(1000)
        }
    }

    const handleAddPayment = () => {
        setShowModal(true);
    }

    const selectGroup = (data) => {
        setShowModal(false);
        setMethod(data);
        setShowSelectModal(true);
    }

    const handleSelect = (value) => {
        if (payments.length < 3) {
            let newPayments = [...payments];
            newPayments.push(value);
            setPayments(newPayments);
        }
        setShowSelectModal(false);
    }
    console.log(payments)

    const goBack = () => {
        setShowModal(true);
        setShowSelectModal(false);
    }

    const removePayment = (value) => {
        console.log(value)
        console.log(payments)
        const newPayments = payments.filter((item, index) => item !== value);
        setPayments(newPayments);
    }

    useEffect(() => {
        (
            async () => {
                const cryptoTitle = cryptoValues[cryptoValue].title === "MATIC" ? "MATICpo" : cryptoValues[cryptoValue].title;
                const res = await axios(`${SOLANA_API_URL}${cryptoTitle}`);
                const tokenPrice = res.data.data.priceUsdt * FIAT_VALUES[fiatValue].price;
                setRate(tokenPrice * 1);
                setTokenPrice(tokenPrice * 1);
            }
        )();
    }, [cryptoValue, setRate, fiatValue, cryptoValues, chainID])

    useEffect(() => {
        if (!chainID) {
            setCryptoValues(CRYPTO_VALUES)
        } else {
            setCryptoValues(ETH_CRYPTO_VALUES[chainID])
        }
    }, [chainID]);

    return (
        <div className="relative">
            {
                isLoading && 
                    <div className="absolute w-full h-full  flex items-center justify-center bg-opacity-40 bg-gray-400 rounded-lg">
                        <FadeLoader loading color='white' />
                    </div>
            }
            <div className="flex justify-between  mb-8">
                <SelectComponent 
                    search
                    cssClass="w-5/12" 
                    title={t("Select Crypto to Buy")} 
                    values={cryptoValues} 
                    placeHolder="Select crypto here" 
                    onChange={setCryptoValue} 
                    value={cryptoValue}
                    disabled={isUpdate} />
                <SelectComponent 
                    search
                    cssClass="w-5/12" 
                    title={t("Select Fiat to Accept")} 
                    values={FIAT_VALUES} 
                    placeHolder="Select fiat currency here" 
                    onChange={setFiatValue} 
                    value={fiatValue} />
            </div>
            <div className="flex flex-wrap justify-between pb-8 border-b-2 border-first-theme mb-10">
                <InputComponent cssClass="w-full md:w-5/12" title={t("Total Trading Amount")} suffix={cryptoValues[cryptoValue].title.toUpperCase()} handleFocus={handleTotalAmount} onChange={setAmount} value={amount} />
                <div className="flex flex-col w-full md:w-5/12 mr-2">
                    <div className="text-left text-sm text-second-theme mb-2">{t("Order Limit")}</div>
                    <div className="flex items-center justify-between">
                        <InputComponent suffix={cryptoValues[cryptoValue].title.toUpperCase()} onChange={setMinLimit} min={0} value={minLimit} handleFocus={handleMinLimit} />
                        <HiArrowRight className="mr-2" />
                        <InputComponent suffix={cryptoValues[cryptoValue].title.toUpperCase()} onChange={setMaxLimit} value={maxLimit} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col border-b-2 border-first-theme mb-10 text-left pb-8">
                <div className="mb-2">{t("Offer Price")}</div>
                <div className="text-sm text-second-theme mb-4">{t("Choose crypto rate you want to use")}</div>
                <div className="flex flex-col md:flex-row justify-between">
                    <div className="two-part">
                        <div className="p-4 border-2 border-first-theme rounded-lg mb-4">
                            <div className="flex items-center mb-2" >
                                <div className="w-8 h-8 rounded-full border-8 green-border mr-4 cursor-pointer" />
                                <div className="" >{t("Fixed Price")}</div>
                            </div>
                            <div className="text-sm text-second-theme">{t("Offer’s selling price is locked once created, and does not fluctuate with the market price.")}</div>
                        </div>
                        <div className="text-sm text-second-theme mb-4">{t("Offer Rate")}</div>
                        <div className="flex justify-between items-center bg-first-theme p-2 mb-2 rounded-lg">
                            <button onClick={() => rate > 0 && setRate(rate - 1)}><AiOutlineMinusSquare size={40} /></button>
                            <input type="number" min={0} className="bg-transparent text-center" value={parseFloat(rate).toFixed(3)} onChange={(e) => setRate(e.target.value * 1)} />
                            <button onClick={() => setRate(rate + 1)}><AiOutlinePlusSquare size={40} /></button>
                        </div>
                        <div className="text-sm text-second-theme mb-4">
                            {t("Current")} {cryptoValues[cryptoValue].title.toUpperCase()} {t("marketplace price")}: 
                            <span className="text-white ml-2">{tokenPrice.toLocaleString("en-US")} {FIAT_VALUES[fiatValue].title.toUpperCase()}</span>
                        </div>
                    </div>
                    <div className="two-part opacity-50">
                        <div className="p-4 border-2 border-first-theme rounded-lg mb-4">
                            <div className="flex items-center mb-2">
                                <div className="w-8 h-8 rounded-full border-2 border-first-theme mr-4 cursor-pointer" />
                                <div className="">{t("Floating Price")} ({t("Coming Soon")})</div>
                            </div>
                            <div className="text-sm text-second-theme">{t("Offer’s selling price will fluctuate with the market price of the crypto.")}</div>
                        </div>
                        <div className="text-sm text-second-theme mb-4">{t("Your Rate")}</div>
                        <div className="font-bold p-4 border-2 border-first-theme rounded-lg">$20,000.00</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-start mb-8">
                <div className="mb-2">{t("Payment Options")}</div>
                <div className="text-sm text-second-theme mb-4">{t("Select your payment option (up to 3)")}</div>
                <div className="flex flex-wrap">
                    {
                        payments.length > 0 && payments.map((item, index) => 
                        <div key={index} className="px-4 py-2 bg-first-theme rounded-lg flex items-center mr-2 mb-2">
                                {PAYMENT_OFFER_VALUES[item].title}
                                <BsXLg className="ml-4 text-gray-400" onClick={() => removePayment(item)} />
                            </div>
                        )
                    }
                    <div 
                        className={`${payments && payments.length === 3 ? "hidden" : "flex"} px-4 py-2 green-font bg-first-theme rounded-lg flex items-center cursor-pointer mb-2`} 
                        onClick={handleAddPayment}
                    >
                        <AiOutlinePlus className="mr-4" />
                        {t("Add New")}
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-end">
                <SelectComponent 
                    title={t("Trade Time Limit")} 
                    position="top"
                    values={TIME_VALUES} 
                    onChange={setTime} 
                    value={time} />
                <GreenButton 
                    cssClass="px-8" 
                    title={t("Next Step")} 
                    onClick={() => changeStatus(1)} 
                    active={amount && minLimit >= 0 && maxLimit && payments.length > 0 && minLimit * 1 <= maxLimit * 1} />
            </div>
            <AddPaymentModal isShown={showModal} setShown={setShowModal} handleModal={selectGroup} />
            <SelectPaymentModal 
                isShown={showSelectModal} 
                setShown={setShowSelectModal} 
                method={method}
                paymentIndexes={payments}
                goBack={goBack}
                handleModal={handleSelect} />
        </div>
    );
}

export default OptionContainer;