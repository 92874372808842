import USDTImg from "../../assets/images/usdt.png";
import ETHImg from "../../assets/images/eth.png";
import USDCImg from "../../assets/images/usdc.png";
import BNBImg from "../../assets/images/bnb.png";
import AVAXImg from "../../assets/images/avax.png";
import MATICImg from "../../assets/images/matic.png";

export const ETH_CRYPTO_VALUES = {
  1: [{
      img: ETHImg,
      title: "ETH",
      value: "eth"
    }, {
      img: USDCImg,
      title: "USDC",
      value: "0x65aFADD39029741B3b8f0756952C74678c9cEC93"
    }, {
      img: USDTImg,
      title: "USDT",
      value: "0x2E8D98fd126a32362F2Bd8aA427E59a1ec63F780"
    }],
  56: [{
    img: BNBImg,
    title: "BNB",
    value: "eth"
  }, {
    img: USDCImg,
    title: "USDC",
    value: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d"
  }, {
    img: USDTImg,
    title: "USDT",
    value: "0x55d398326f99059fF775485246999027B3197955"
  }],
  137: [{
    img: MATICImg,
    title: "MATIC",
    value: "eth"
  }, {
    img: USDCImg,
    title: "USDC",
    value: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
  }, {
    img: USDTImg,
    title: "USDT",
    value: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
  }],
  43114: [{
    img: AVAXImg,
    title: "AVAX",
    value: "eth"
  }, {
    img: USDCImg,
    title: "USDC",
    value: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"
  }, {
    img: USDTImg,
    title: "USDT",
    value: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118"
  }],
}

export const ETH_CRYPTO_FILTER_VALUES = {
  1: [{
    title: "All",
    value: ""
  },{
    img: ETHImg,
    title: "ETH",
    value: "eth"
  }, {
    img: USDCImg,
    title: "USDC",
    value: "0x65aFADD39029741B3b8f0756952C74678c9cEC93"
  }, {
    img: USDTImg,
    title: "USDT",
    value: "0x2E8D98fd126a32362F2Bd8aA427E59a1ec63F780"
  }],
  56: [{
    title: "All",
    value: ""
  },{
    img: BNBImg,
    title: "BNB",
    value: "eth"
  }, {
    img: USDCImg,
    title: "USDC",
    value: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d"
  }, {
    img: USDTImg,
    title: "USDT",
    value: "0x55d398326f99059fF775485246999027B3197955"
  }],
  137: [{
    title: "All",
    value: ""
  },{
    img: MATICImg,
    title: "MATIC",
    value: "eth"
  }, {
    img: USDCImg,
    title: "USDC",
    value: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
  }, {
    img: USDTImg,
    title: "USDT",
    value: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
  }],
  43114: [{
    title: "All",
    value: ""
  },{
    img: AVAXImg,
    title: "AVAX",
    value: "eth"
  }, {
    img: USDCImg,
    title: "USDC",
    value: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"
  }, {
    img: USDTImg,
    title: "USDT",
    value: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118"
  }],
}