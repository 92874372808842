import { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { FadeLoader } from 'react-spinners';
import { AiFillCheckCircle } from 'react-icons/ai';
import { LANGUAGES } from '../constants/account';
import GrayButton from './GrayButton';
import GreenButton from './GreenButton';

const LanguageModal = (props) => {
    const { t } = useTranslation();
    const { isShown, setShown, handleModal, language, setLanguage, isLoading} = props;
    const cancelButtonRef = useRef(null)

    const handleCheck = (index) => {
        setLanguage(LANGUAGES[index].value);
    }

    return (
        <Transition.Root show={isShown} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShown}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-first-theme text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-first-theme px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-white relative">
                                    <div className='text-center green-font text-3xl mb-2'>
                                    {t("Change Language")}
                                    </div>
                                    <div className='text-second-theme text-center mb-8'>
                                    {t("Select your prefered language from the list")}
                                    </div>
                                    <div className='mb-8'>
                                        {
                                            LANGUAGES.map((item, index) => 
                                                <div 
                                                    key={index}
                                                    className='flex items-center justify-between p-2 mb-2 border-b-2 border-first-theme hover:bg-gray-500 rounded-lg' 
                                                    onClick={() => handleCheck(index)}
                                                >
                                                    <span>{item.title}</span>
                                                    <span className='green-font'>
                                                        {
                                                            language === item.value
                                                                ? <AiFillCheckCircle />
                                                                : ""
                                                        }
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='flex justify-between'>
                                        <GrayButton cssClass="w-5/12" title={t("Cancel")} onClick={() => setShown(false)} />
                                        <GreenButton cssClass="w-5/12" title={t("Change Language")} onClick={handleModal} active={language} />
                                    </div>
                                    {
                                        isLoading && 
                                            <div className='w-full h-full z-50 absolute top-0 left-0 flex items-center justify-center bg-opacity-40 bg-gray-400'>
                                                <FadeLoader loading color='white' />
                                            </div>
                                    }
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default LanguageModal;