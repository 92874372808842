export const NEXT_PUBLIC_SOLANA_RPC_HOST = 'https://evocative-capable-bird.solana-mainnet.discover.quiknode.pro/92a5463618b3f6fc352bcbdc012d21c547a37ba1/';
export const NEXT_PUBLIC_SOLANA_NETWORK = 'mainnet-beta';
export const NEXT_PUBLIC_CONTRACT_ID = 'EjhKpVjjhyEPgWeRRgW2MieAcHWRSwV2ZWtBegR95Ews';
export const NEXT_PUBLIC_POOL_ID = "BvgZ1rZxaC6X6Z5AZ5E8FHbE2SvsDj9Y4KA823qY6GQt";
export const TOKEN_ID = "Getgd1QDp783eqrfuDkEfZraWP6Eo5sZE33pVKTPgG4V";
// export const NEXT_PUBLIC_SOLANA_RPC_HOST = 'https://white-cold-silence.solana-devnet.discover.quiknode.pro/f7afc911146bfe8e3ab48a6d70697ef2869572cb/';
// export const NEXT_PUBLIC_SOLANA_NETWORK = 'devnet';
// export const NEXT_PUBLIC_CONTRACT_ID = 'EjhKpVjjhyEPgWeRRgW2MieAcHWRSwV2ZWtBegR95Ews';
// export const NEXT_PUBLIC_POOL_ID = "5i4WgnFqiKhZ9iDomWYT6MUmy8ea3vNnqzZf8qEVmgGf";
// export const TOKEN_ID = "9ZS9bFJar1QgNfUU34GYkX1AWqvfhJJPu2CsGFqPRobS";

export const ETH_CHAIN_ID = 1;
export const BSC_CHAIN_ID = 56;
export const MATIC_CHAIN_ID = 137;
export const AVAX_CHAIN_ID = 43114;
export const CONTRACT_ADDRESS = {
    "1": "0x0F74f7D909cdBcd944362dd3eCED7B7FEe79C400",
    "56": "0x481c1c1e06a54c9ECD6195F1121aA1190b50e856",
    "137": "0x481c1c1e06a54c9ECD6195F1121aA1190b50e856",
    "43114": "0x481c1c1e06a54c9ECD6195F1121aA1190b50e856"
};
export const ETHEREUM_API = {
    "1": "https://api.etherscan.io/api?module=account&action=txlist&address=",
    "56": "https://api.bscscan.com/api?module=account&action=txlist&address=",
    "137": "https://api.polygonscan.com/api?module=account&action=txlist&address=",
    "43114": "https://api.snowtrace.io/api?module=account&action=txlist&address="
}

export const CHAT_CONTRACT = {
    "5": "U2FsdGVkX19SySCN+FExh1mo4plln/4M/HNyETh6zmQ14IHDU1gbdKclxDKKs7y3PdBm8NqF5JQTyLfBWMT4KQ==",
    "97": "0x4E9E4141a0721FEcFfc15d32D1177CCA16Cc3626"
};