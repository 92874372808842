import { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { RiBankLine, RiWalletLine, RiCopperCoinLine, RiArrowRightLine } from 'react-icons/ri';


const AddPaymentModal = (props) => {
    const { t } = useTranslation();
    const { isShown, setShown, handleModal } = props;
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={isShown} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShown}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-first-theme text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-first-theme px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-white relative">
                                    <div className='text-center green-font text-3xl mb-8'>
                                    {t("Add payment methods")}
                                    </div>
                                    <div 
                                        onClick={() => handleModal("all")}
                                        className='flex justify-between items-center mb-2 text-lg p-2 cursor-pointer rounded-lg hover:bg-gray-500'
                                    >
                                        <div className='flex items-center'><RiCopperCoinLine color="#68CEC7" className='mr-2' />All Payments</div>
                                        <div className='flex items-center'>
                                            <span className='w-8 h-8 flex items-center justify-center green-bg rounded-lg text-black mr-2'>39</span>
                                            <RiArrowRightLine />
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => handleModal("bank")} 
                                        className='flex justify-between items-center mb-2 text-lg p-2 cursor-pointer rounded-lg hover:bg-gray-500'
                                    >
                                        <div className='flex items-center'><RiBankLine color="#68CEC7" className='mr-2'/>Bank Transfer</div>
                                        <div className='flex items-center'>
                                            <span className='w-8 h-8 flex items-center justify-center green-bg rounded-lg text-black mr-2'>1</span>
                                            <RiArrowRightLine />
                                        </div>
                                    </div>
                                    <div 
                                        onClick={() => handleModal("wallet")}
                                        className='flex justify-between items-center mb-16 text-lg p-2 cursor-pointer rounded-lg hover:bg-gray-500'
                                    >
                                        <div className='flex items-center'><RiWalletLine color="#68CEC7" className='mr-2'/>Online Wallet</div>
                                        <div className='flex items-center'>
                                            <span className='w-8 h-8 flex items-center justify-center green-bg rounded-lg text-black mr-2'>38</span>
                                            <RiArrowRightLine />
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default AddPaymentModal;