import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { FadeLoader } from 'react-spinners';
import { useNavigate } from "react-router-dom";
import GrayButton from './GrayButton';
import GreenButton from './GreenButton';
import { FIAT_VALUES, TIME_VALUES, PAYMENT_OFFER_VALUES } from "../constants/offers";

const OfferConfirmModal = (props) => {
    const { isShown, setShown, cryptoType, fiatType, amount, minLimit, maxLimit, payments, time, createOffer, isLoading, rate, cryptoValues, txSuccess } = props;
    const [activeButton, setActiveButton] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const cancelButtonRef = useRef(null);

    const handleCheck = (e) => {
        setActiveButton(e.target.checked);
    }

    const goToNext = () => {
        navigate("/offer/my");
    }

    return (
        <Transition.Root show={isShown} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShown}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-first-theme text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-first-theme px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-white relative">
                                    <div className='text-center green-font text-3xl mb-8'>
                                        {t("Offer Confirmation")}
                                    </div>
                                    <div className='text-second-theme text-center mb-8'>
                                        {t("Check the offer terms before proceeding to submit this offer")}
                                    </div>
                                    <div className='p-4 bg-black rounded-lg mb-8'>
                                        <div className='flex justify-between mb-4'>
                                            <span className='text-second-theme'>{t("Rate")}</span>
                                            <span>{rate.toFixed(3)} {FIAT_VALUES[fiatType].title.toUpperCase()}</span>
                                        </div>
                                        <div className='flex justify-between mb-4'>
                                            <span className='text-second-theme'>{t("Total Trading Amount")}</span>
                                            <span>{amount} {cryptoValues[cryptoType].title.toUpperCase()}</span>
                                        </div>
                                        <div className='flex justify-between mb-4'>
                                            <span className='text-second-theme'>{t("Limit")}</span>
                                            <span>{minLimit} - {maxLimit}</span>
                                        </div>
                                        <div className='flex justify-between mb-4'>
                                            <span className='text-second-theme'>{t("Payment Method")}</span>
                                            <span>
                                                {
                                                    payments.map((item, index) => 
                                                        item * 1>= 0 && <div className='text-right' key={index}>  
                                                            {PAYMENT_OFFER_VALUES[item * 1].title}
                                                        </div>
                                                    )
                                                }
                                            </span>
                                        </div>
                                        <div className='flex justify-between mb-4'>
                                            <span className='text-second-theme'>{t("Crypto to Buy")}</span>
                                            <span>{cryptoValues[cryptoType].title.toUpperCase()}</span>
                                        </div>
                                        <div className='flex justify-between mb-4'>
                                            <span className='text-second-theme'>{t("Fiat to Send")}</span>
                                            <span>{FIAT_VALUES[fiatType].title.toUpperCase()}</span>
                                        </div>
                                        <div className='flex justify-between'>
                                            <span className='text-second-theme'>{t("Trade Time Limit")}</span>
                                            <span>{TIME_VALUES[time].title}</span>
                                        </div>
                                    </div>
                                    {
                                        !txSuccess &&
                                            <div className=''>
                                                <div className="flex items-start mb-4">
                                                <input type="checkbox" onChange={handleCheck}  className="green-font w-8 h-8 green-checkbox border border-gray-300 rounded" />
                                                    <label htmlFor="default-checkbox" className="ml-2 text-second-theme">{t("I have read through the offer terms, and want to proceed to create this offer")}</label>
                                                </div>
                                            </div>
                                    }
                                    {
                                        !txSuccess &&
                                            <div className='flex justify-between'>
                                                <GrayButton cssClass="w-5/12" title={t("Cancel")} size="lg" onClick={() => setShown(false)} />
                                                <GreenButton cssClass="w-5/12" title={t("Submit Offer")} size="lg" active={activeButton} onClick={createOffer} />
                                            </div>
                                    }
                                    {
                                        txSuccess &&
                                        <div className='flex justify-center'>
                                            <GreenButton cssClass="w-5/12" title={t("Success")} size="lg" active onClick={goToNext} />
                                        </div>
                                    }
                                    {
                                        isLoading && 
                                            <div className='w-full h-full z-50 absolute top-0 left-0 flex items-center justify-center bg-opacity-40 bg-gray-400'>
                                                <FadeLoader loading color='white' />
                                            </div>
                                    }
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default OfferConfirmModal;