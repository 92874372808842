import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useWallet } from '@solana/wallet-adapter-react';
import { AiFillBell, AiOutlinePlusSquare, AiOutlineDown, AiOutlineArrowRight } from "react-icons/ai"
import { BsFillTagFill } from "react-icons/bs";
import { HiCurrencyDollar } from "react-icons/hi";
import { GoThreeBars } from "react-icons/go";
import useNotification from "../utils/notifications";
import useEthNotification from "../utils/eth/notifications";
import useOrder from "../utils/orders";
import useEthOrder from "../utils/eth/orders";
import SelectWrapper from "./SelectWrapper";
import { AppContext } from "../utils/AppContext";

const NavbarWrapper = () => {
    const { chainID, chainState } = useContext(AppContext);
    const { allNotification, isLoading, unReadNum } = useNotification();
    const { allEthNotification, isEthLoading, unReadEthNum } = useEthNotification();
    const { ordersCount } = useOrder();
    const { ethOrdersCount } = useEthOrder();
    const wallet = useWallet();
    return (
        <Navbar 
            chainID={chainID}
            walletAddress={!chainID ? wallet.publicKey ? wallet.publicKey.toBase58() : "" : chainState.address}
            allNotification={!chainID ? allNotification : allEthNotification}
            isLoading={!chainID ? isLoading : isEthLoading}
            unReadNum={!chainID ? unReadNum : unReadEthNum}
            ordersCount={!chainID ? ordersCount : ethOrdersCount} />
    )
}

const Navbar = ({chainID, walletAddress, allNotification, isLoading, unReadNum, ordersCount}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showStatus, setShowStatus] = useState(false);
    const [showChild, setShowChild] = useState(false);
    const [showNotify, setShowNotify] = useState(false);
    const [badge, setBadge] = useState(false);
    const location = useLocation();
    const path = location.pathname;

    const outSideNotifyClickFunc = () => {
        setShowNotify(false)
    }
    const outSideClickFunc = () => {
        setShowChild(false)
    }

    const handleMark = () => {
        if (!chainID) {
            localStorage.setItem(`${walletAddress}_notify_date`, allNotification[0].createdTime);
        } else {
            localStorage.setItem(`${walletAddress}_${chainID}_notify_date`, allNotification[0].createdTime);
        }
        setBadge(true);
    }

    const handleNavigate = (url) => {
        navigate(url);
    }

    return (
        <div className="bg-first-theme px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex justify-between">
            <div className="hidden lg:flex text-gray-300 py-4">
                <a className={`mr-8 cursor-pointer ${path === "/offers" && "green-font"}`} onClick={() => handleNavigate("/offers")}>{t("Offers")}</a>
                <a className={`mr-8 flex items-center cursor-pointer ${path === "/orders" && "green-font"}`} onClick={() => handleNavigate("/orders")}>
                    {t("My Orders")}
                    <span className="w-6 h-6 rounded-full flex items-center justify-center bg-gray-500 ml-1 text-white text-sm">{ordersCount}</span>
                </a>
                <a className={`mr-8 cursor-pointer ${path === "/wallet" && "green-font"}`} onClick={() => handleNavigate("/wallet")}>{t("Trading Wallet")}</a>
                <a className={`mr-8 cursor-pointer ${path === "/account" && "green-font"}`} onClick={() => handleNavigate("/account")}>{t("Account")}</a>
                <a className={`mr-8 cursor-pointer ${path === "/stats" && "green-font"}`} onClick={() => handleNavigate("/stats")}>{t("Stats")}</a>
            </div>
            <div className="block lg:hidden">
                <div className="relative">
                    <div className="text-white py-4 pl-2 flex items-center cursor-pointer" onClick={() => setShowStatus(!showStatus)}>
                        <GoThreeBars size={24} />    
                    </div>
                    <div className={`absolute ${showStatus ? "flex" : "hidden"} flex-col w-max mt-1 p-2 bg-first-theme rounded-lg z-50 text-white`}>
                        <a className={`p-2 cursor-pointer hover:green-font ${path === "/offers" ? "green-font" : ""}`} onClick={() => handleNavigate("/offers")}>{t("Offers")}</a>
                        <a className={`p-2 cursor-pointer flex items-center hover:green-font ${path === "/orders" ? "green-font" : ""}`} onClick={() => handleNavigate("/orders")}>
                            {t("My Orders")}
                            <span className="w-6 h-6 rounded-full flex items-center justify-center bg-gray-500 ml-1 text-white text-sm">{ordersCount}</span>
                        </a>
                        <a className={`p-2 cursor-pointer hover:green-font ${path === "/wallet" ? "green-font" : ""}`} onClick={() => handleNavigate("/wallet")}>{t("Trading Wallet")}</a>
                        <a className={`p-2 cursor-pointer hover:green-font ${path === "/account" ? "green-font" : ""}`} onClick={() => handleNavigate("/account")}>{t("Account")}</a>
                        <a className={`p-2 cursor-pointer hover:green-font ${path === "/stats" ? "green-font" : ""}`} onClick={() => handleNavigate("/stats")}>{t("Stats")}</a>
                    </div>
                </div>
            </div>
            <div className="flex items-center">
                <a href="https://mansa.referral.qwestive.io/referral/JTSaN12r5zNl35LeTQz8" target="_blank" rel="noreferrer" className="text-white mr-6">Referral Program</a>
                {
                    walletAddress && 
                        <div 
                            onClick={() => setShowNotify(!showNotify)}
                            className="relative"
                        >
                            <SelectWrapper outSideClickFunc={outSideNotifyClickFunc} >
                                <div className="inline-flex relative items-center p-3 text-2xl text-center text-white mr-2 md:mr-8 relative">
                                    <AiFillBell />
                                    {
                                        unReadNum > 0 && !badge && 
                                            <div className="inline-flex absolute top-2 right-2 justify-center items-center w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full">
                                                {unReadNum}
                                            </div>
                                    }
                                </div>
                                <div className={`mt-2 hor-middle-img w-64 md:w-80 absolute bg-first-theme text-white z-10 ${showNotify ? "block" : "hidden"} rounded-lg`}>
                                        {
                                            isLoading
                                                ? <div className="mb-2 py-8">{t("Loading")}...</div>
                                                : allNotification.length === 0
                                                    ? <div className="py-8">
                                                        No notifications
                                                    </div>
                                                    : <div className="flex flex-col p-4">
                                                        <div className="flex justify-between items-end pb-4">
                                                            <span className="text-base md:text-lg">Notification</span>
                                                            <span className="green-font text-sm cursor-pointer" onClick={handleMark}>Mark all as read</span>
                                                        </div>
                                                        <div className="mb-2">
                                                            {
                                                                allNotification.filter((_, index) => index < 5).map((item, index) => 
                                                                    <div key={index} className={`flex items-center pb-2 border-first-theme text-left`}>
                                                                        <div className="w-2 h-2 green-bg rounded-full mr-4" />
                                                                        <div className="flex-1">
                                                                            <div className="flex justify-between mb-2">
                                                                                <p className="text-sm md:text-base">
                                                                                    {
                                                                                        item.type === "create"
                                                                                            && `${t("New order created for")} ${item.amount} ${item.tokenName.toUpperCase()}`
                                                                                    }
                                                                                    {
                                                                                        item.type === "buyer_confirm"
                                                                                            && `${t("Buyer confirmed payment for")} ${item.amount} ${item.tokenName.toUpperCase()}`
                                                                                    }
                                                                                    {
                                                                                        item.type === "seller_confirm"
                                                                                            && `${t("Order completed for")} ${item.amount} ${item.tokenName.toUpperCase()}`
                                                                                    }
                                                                                    {
                                                                                        item.type === "cancel"
                                                                                            && `${t("Order cancelled for")} ${item.amount} ${item.tokenName.toUpperCase()}`
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className="flex flex-col md:flex-row justify-between">
                                                                                <p className="text-xs md:text-sm text-second-theme">
                                                                                    {
                                                                                        item.type === "create"
                                                                                            && `${t("This order is created by")} ${item.user.slice(0, 6)}...${item.user.substring(item.user.length - 7)}`
                                                                                    }
                                                                                    {
                                                                                        item.type === "buyer_confirm"
                                                                                            && `${t("This order is confirmed by")} ${item.user.slice(0, 6)}...${item.user.substring(item.user.length - 7)}`
                                                                                    }
                                                                                    {
                                                                                        item.type === "seller_confirm"
                                                                                            && `${t("This order is completed by")} ${item.user.slice(0, 6)}...${item.user.substring(item.user.length - 7)}`
                                                                                    }
                                                                                    {
                                                                                        item.type === "cancel"
                                                                                            && `${t("This order is cancelled by")} ${item.user.slice(0, 6)}...${item.user.substring(item.user.length - 7)}`
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <a 
                                                            onClick={() => handleNavigate("/notifications")} 
                                                            className="flex items-center justify-center bg-opacity-20 bg-gray-500 rounded-lg p-2 text-sm cursor-pointer"
                                                        >
                                                            View all notifications<AiOutlineArrowRight className="ml-2" color="#68CEC7" size={16}/>
                                                        </a>
                                                    </div>
                                        }
                                </div>
                            </SelectWrapper>
                        </div>
                }
                {
                    walletAddress &&
                        <SelectWrapper outSideClickFunc={outSideClickFunc} >
                            <div className="relative">
                                <div className="flex items-center text-white cursor-pointer" onClick={() => setShowChild(!showChild)}>
                                    <AiOutlinePlusSquare className="mr-2" color="#68CEC7" />
                                    {t("Create New Offer")}
                                    <AiOutlineDown className="ml-2"/>
                                </div>
                                <div className={`absolute mt-1 right-0 w-max text-white bg-first-theme rounded-lg flex flex-col text-left z-50 ${showChild ? "block" : "hidden"}`}>
                                    <a onClick={() => handleNavigate("/create")} className="flex px-4 py-4 items-center cursor-pointer hover:green-font">
                                        <BsFillTagFill size={24} color="#68CEC7" />
                                        <span className="ml-2">{t("Buy Crypto for Cash")}</span>
                                    </a>
                                    <a onClick={() => handleNavigate("/offer/my")} className="flex px-4 py-4 items-center cursor-pointer hover:green-font">
                                        <HiCurrencyDollar size={24} color="#68CEC7" />
                                        <span className="ml-2">{t("View my Offers")}</span>
                                    </a>
                                </div>
                            </div>
                        </SelectWrapper>
                }
            </div>
        </div>
    );
}

export default NavbarWrapper;