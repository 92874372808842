import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FiRefreshCcw } from "react-icons/fi"
import { FadeLoader } from 'react-spinners';
import Navbar from "../components/Navbar";
import SelectComponent from "../components/SelectComponent";
import { DURATION_VALUES } from "../constants/stats";
import useStats from "../utils/stats";
import useEthStats from "../utils/eth/stats";
import { AppContext } from "../utils/AppContext";

const getStatsInfo = (statsData, duration) => {
    const date = new Date();
    const prevDate = date.setDate(date.getDate() - duration);
    console.log(prevDate);
    const minDate = date.setDate(date.getDate() - duration * 2);
    console.log(minDate);
    const prevUnixTime = Math.floor(new Date(prevDate) / 1000);
    const minUnixTime = Math.floor(new Date(minDate) / 1000);
    let newWallets = [];
    let newTransactions = 0;
    let newVolume = 0;
    console.log(statsData)
    const newStatsData = statsData.filter(item => item.createdAt > prevUnixTime);
    newStatsData.forEach(item => {
        if (newWallets.indexOf(item.seller) < 0) {
            newWallets.push(item.seller);
        }
        
        if (newWallets.indexOf(item.buyer) < 0) {
            newWallets.push(item.buyer);
        }

        newVolume += item.price * item.fiatAmount
    });
    newTransactions = newStatsData.length;
    let oldWallets = [];
    let oldTransactions = 0;
    let oldVolume = 0;
    const oldStatsData = statsData.filter(item => item.createdAt < prevUnixTime && item.createdAt > minUnixTime);
    oldStatsData.forEach(item => {
        if (oldWallets.indexOf(item.seller) < 0) {
            oldWallets.push(item.seller);
        }

        if (oldWallets.indexOf(item.buyer) < 0) {
            oldWallets.push(item.buyer);
        }

        oldVolume += item.price * item.fiatAmount
    });
    oldTransactions = oldStatsData.length;

    const volumeChange = oldVolume === 0 ? 100 :  Math.abs(newVolume - oldVolume) * 100 / oldVolume;
    const volumePlus = newVolume < oldVolume ? false : true
    const transactionsChange = oldTransactions === 0 ? 100 :  Math.abs(newTransactions - oldTransactions) * 100 / oldTransactions;
    const transactionsPlus = newTransactions < oldTransactions ? false : true
    const walletsChange = oldWallets.length === 0 ? 100 :  Math.abs(newWallets.length - oldWallets.length) * 100 / oldWallets.length;
    const walletsPlus = newWallets.length < oldWallets.length ? false : true
    
    return [
        [newVolume, volumeChange, volumePlus],
        [newTransactions, transactionsChange, transactionsPlus],
        [newWallets.length, walletsChange, walletsPlus]
    ]
}

const StatsWrapper = () => {
    const { chainID } = useContext(AppContext);
    const { isLoading, statsData, refresh, setRefresh } = useStats();
    const { isEthLoading, ethStatsData, ethRefresh, setEthRefresh } = useEthStats();

    return (
        <Stats
            isLoading={!chainID ? isLoading : isEthLoading}
            statsData={!chainID ? statsData : ethStatsData}
            refresh={!chainID ? refresh : ethRefresh}
            setRefresh={!chainID ? setRefresh : setEthRefresh} />
    );
}

const Stats = ({isLoading, statsData, refresh, setRefresh}) => {
    const { t } = useTranslation();
    const [selectedDurationIndex, setSelectedDurationIndex] = useState(0);
    const [volume, setVolume] = useState([0, 0, true]);
    const [transactions, setTransactions] = useState([0, 0, true]);
    const [wallets, setWallets] = useState([0, 0, true]);

    useEffect(() => {
        const newStats = getStatsInfo(statsData, DURATION_VALUES[selectedDurationIndex].value);
        setVolume(newStats[0]);
        setTransactions(newStats[1]);
        setWallets(newStats[2]);
    }, [selectedDurationIndex, statsData])

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 text-white flex flex-col items-start">
                <div className="text-2xl font-bold mt-12 mb-2 text-left">{t("Stats")}</div>
                <div className="text-sm text-second-theme mb-10 text-left">{t("View live market statistics")}</div>
                <div className="w-full flex justify-between">
                    <SelectComponent cssClass="mb-10" title={t("Duration")} values={DURATION_VALUES} value={selectedDurationIndex} onChange={setSelectedDurationIndex} />
                    <div className="flex flex-col cursor-pointer" onClick={() => setRefresh(true)}>
                        <div className="text-sm text-second-theme mb-2 text-left">{t("Refresh")}</div>
                        <div className="px-4 py-2 border-2 border-first-theme rounded-lg flex items-center justify-center">
                            <FiRefreshCcw className={`${refresh && "animate-spin"}`} size={24} />
                        </div>
                    </div>
                </div>
                <div className="w-full px-8 py-4 border-2 border-first-theme  rounded-lg">
                    {
                        isLoading
                            ? <div className="p-4 w-full flex items-center justify-center ">
                                <FadeLoader className="" loading color='white' />
                            </div>
                            : <div className="flex justify-between text-left flex-col md:flex-row">
                                <div className="flex flex-col flex-1 mr-4 mb-4">
                                    <div className="mb-4 text-sm text-gray-300">{t("Total Volume")}</div>
                                    <div className="w-full flex justify-between items-center bg-first-theme px-2 py-1 rounded-lg">
                                        <div className="">{volume[0].toLocaleString("en-US")}</div>
                                        <div className={`${volume[2] ? "green-font" : "text-red-500"} flex items-center`}>
                                            {
                                                volume[2]
                                                    ? <AiFillCaretUp className="mr-1 green-font" />
                                                    : <AiFillCaretDown className="mr-1 text-red-500" />
                                            }
                                            {volume[1].toFixed(2)}%
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col flex-1 mr-4 mb-4">
                                    <div className="mb-4 text-sm text-gray-300">{t("Transacions")}</div>
                                    <div className="w-full flex justify-between items-center bg-first-theme px-2 py-1 rounded-lg">
                                        <div className="">{transactions[0].toLocaleString("en-US")}</div>
                                        <div className={`${transactions[2] ? "green-font" : "text-red-500"} flex items-center`}>
                                            {
                                                transactions[2]
                                                    ? <AiFillCaretUp className="mr-1" />
                                                    : <AiFillCaretDown className="mr-1" />
                                            }
                                            {transactions[1].toFixed(2)}%
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col flex-1 mr-4 mb-4">
                                    <div className="mb-4 text-sm text-gray-300">{t("Active Wallets")}</div>
                                    <div className="w-full flex justify-between items-center bg-first-theme px-2 py-1 rounded-lg">
                                        <div className="">{wallets[0].toLocaleString("en-US")}</div>
                                        <div className={`${wallets[2] ? "green-font" : "text-red-500"} flex items-center`}>
                                            {
                                                wallets[2]
                                                    ? <AiFillCaretUp className="mr-1" />
                                                    : <AiFillCaretDown className="mr-1" />
                                            }
                                            {wallets[1].toFixed(2)}%
                                        </div>
                                    </div>
                                </div>
                            </div>

                    }
                </div>
            </div>
        </>
    );
}

export default StatsWrapper;