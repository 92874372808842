import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import OfferList from "./pages/OfferList";
import Notifications from "./pages/Notifications";
import OfferSeller from "./pages/OfferSeller";
import CreateOffer from "./pages/CreateOffer";
import MyOfferList from "./pages/MyOfferList";
import MyOrderList from "./pages/MyOrderList";
import Wallet from "./pages/Wallet";
import Account from "./pages/Account";
import Stats from "./pages/Stats";
import OrderPage from "./pages/OrderPage";
import EditOffer from "./pages/EditOffer";
import Risk from "./pages/Risk";
import TermCondition from "./pages/TermCondition";
import Mobile from "./pages/Mobile";
import Header from "./components/Header";

const MyRoutes = ({chainState, setChainState}) => {
    return (
    <Router>
        <Header chainState={chainState} setChainState={setChainState} />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/create" element={<CreateOffer />} />
            <Route path="/offers" element={<OfferList />} />
            <Route path="/notifications" element={<Notifications />} /> 
            <Route path="/offer/edit/:id" element={<EditOffer />} /> 
            <Route path="/offer/seller/:id" element={<OfferSeller />} /> 
            <Route path="/order/buy/:id" element={<OrderPage />} /> 
            <Route path="/order/sell/:id" element={<OrderPage />} /> 
            <Route path="/offer/my" element={<MyOfferList />} /> 
            <Route path="/orders" element={<MyOrderList />} /> 
            <Route path="/wallet" element={<Wallet />} /> 
            <Route path="/account" element={<Account />} /> 
            <Route path="/stats" element={<Stats />} />
            <Route path="/mobile" element={<Mobile />} />
            <Route path="/risk-disclosure-policy" element={<Risk />} />
            <Route path="/terms-conditions" element={<TermCondition />} />
        </Routes>
    </Router>
    );
  };
  
  export default MyRoutes;