const GrayButton = (props) => {
    const { title, size, cssClass, onClick } = props;
    return (
        <button 
            onClick={onClick}
            className={`${size === "lg" ? "px-12 py-4" : "px-6 py-2"} ${cssClass} bg-transparent text-white rounded-lg border-2 border-gray-500`}>
            {title}
        </button>
    );
}

export default GrayButton;