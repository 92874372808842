import { useTranslation } from "react-i18next";
import { MdOutlineCancel } from "react-icons/md";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsChevronCompactRight } from "react-icons/bs";
import { PAYMENT_OFFER_VALUES } from "../../../constants/offers";

const CancelContainer = (props) => {
    const { orderData } = props;
    const { t } = useTranslation();

    return (
        <>
            <div className="flex flex-col mb-10">
                <div className="flex justify-between">
                    <a href="/orders" className="text-2xl flex items-center mb-2"><AiOutlineArrowLeft className="mr-4"/>{t("Order")} {orderData.id}</a>
                    <div className="p-2 rounded-lg bg-first-theme text-red-500 flex items-center"><MdOutlineCancel className="mr-2" />Cancelled</div>
                </div>
                <div className="flex justify-between">
                    <div className="text-sm text-second-theme">{t("The order has been marked as cancelled")}</div>
                    <div className="text-sm text-second-theme"></div>
                </div>
            </div>
            <div className="flex items-center mb-8 md:mb-16 text-second-theme bg-first-theme rounded-tr-lg rounded-tl-lg border-b-2 border-red-500">
                <div className="flex-1 py-2 rounded-tl-lg">{t("Waiting for payment")}</div>
                <BsChevronCompactRight size={24} />
                <div className="flex-1 py-2">{t("Release crypto")}</div>
                <BsChevronCompactRight size={24} />
                <div className="flex-1 py-2 rounded-tr-lg">{t("Completed")}</div>
            </div>
            <div className="mb-8">
                <div className="text-left mb-4 md:mb-8">{t("Trade Information")}</div>
                <div className="flex flex-wrap justify-between text-left py-8 border-t-2 border-b-2 border-first-theme mb-8">
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Amount to receive")}</div>
                        <div className="">{orderData.fiatAmount}</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Rate")}</div>
                        <div className="">{orderData.rate} {orderData.fiat.toUpperCase()}</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Transaction fee %")}</div>
                        <div className="">{orderData.fee}%</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Transaction fee amount")}</div>
                        <div className="">{orderData.moneyFee} {orderData.tokenName.toUpperCase()}</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Crypto to send")}</div>
                        <div className="">{orderData.sellAmount} {orderData.tokenName.toUpperCase()}</div>
                    </div>
                </div>
            </div>
            <div className="text-left">
                <div className="mb-2">{t("Your Payment Information")}</div>
                <div className="text-sm text-second-theme mb-4">{t("This is your selected payment method")}</div>
                <div className="p-4 rounded-lg bg-first-theme flex flex-wrap mb-4">
                    <div className="text-sm w-6/12 py-2">
                        <div className="text-second-theme mb-2">Seller's {PAYMENT_OFFER_VALUES[orderData.paymentOption].title} Email</div>
                        <div className="">{orderData.emailAddress}</div>
                    </div>
                    <div className="text-sm w-6/12 py-2">
                        <div className="text-second-theme mb-2">{t("Account Name")}</div>
                        <div className="">{orderData.accountName}</div>
                    </div>
                    <div className="text-sm w-6/12 py-2">
                        <div className="text-second-theme mb-2">{t("Reference Message")}</div>
                        <div className="">{orderData.fullId}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CancelContainer;