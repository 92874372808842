import { Fragment, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react'
import GrayButton from './GrayButton';
import GreenButton from './GreenButton';
import LockImg from "../assets/images/lock.png";

const TransferNotifyModal = (props) => {
    const { isShown, setShown, handleModal } = props;
    const cancelButtonRef = useRef(null);
    const { t } = useTranslation();

    return (
        <Transition.Root show={isShown} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShown}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-first-theme text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-first-theme px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-white">
                                    <div className='flex flex-col items-center'>
                                        <img className='py-8' src={LockImg} alt="" />
                                        <div className='text-center green-font text-3xl mb-4'>
                                        {t("Transfer Crypto to Trading Wallet")}
                                        </div>
                                        <div className='text-second-theme text-center mb-4'>
                                        {t("Your crypto would be transferred to a secured wallet in our smart contract for this trade")}
                                        </div>
                                        <div className='text-second-theme text-center mb-8'>
                                        {t("Note: Your crypto will not be released to the buyer until you release the fund after getting your payment")}
                                        </div>
                                    </div>
                                    <div className='flex justify-between'>
                                        <GrayButton cssClass="w-5/12" title={t("Cancel")} size="lg" onClick={() => setShown(false)} />
                                        <GreenButton cssClass="w-5/12" title={t("Transfer")} size="lg" onClick={handleModal} active />
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default TransferNotifyModal;