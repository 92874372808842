const InputComponent = (props) => {
    const { title, placeHolder, cssClass, suffix, onChange, value, border, min, max, handleFocus, type } = props;

    const changeValue = (e) => {
        if (!type) {
            if (e.target.value >= 0) {
                onChange(e.target.value)
            }
        } else {
            onChange(e.target.value)
        }
    }

    return (
        <div className={`mr-2 ${cssClass} mb-2`}>
            {
                title && 
                    <div className="text-sm text-second-theme mb-2 text-left">{title}</div>
            }
            <div className="relative">
                <input 
                    type={type ? "string" : "number"}
                    min={min}
                    max={max}
                    className={`w-full rounded-lg px-4 py-2 bg-first-theme  ${border ? "border-2 border-white" : ""} pr-12`}
                    placeholder={placeHolder} 
                    onBlur={handleFocus}
                    value={value}
                    onChange={changeValue} />
                <div className="absolute right-4 ver-middle-img text-sm text-gray-300">{suffix}</div>
            </div>
        </div>
    );
}

export default InputComponent;