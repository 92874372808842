import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWallet } from "@solana/wallet-adapter-react";
import { BsChevronCompactRight } from "react-icons/bs";
import OptionContainer from "../containers/CreateOffer/OptionContainer";
import TermContainer from "../containers/CreateOffer/TermContainer";
import Navbar from "../components/Navbar";

const CreateOffer = () => {
    const { t } = useTranslation();
    const [status, setStatus] = useState(0);
    const [cryptoValue, setCryptoValue] = useState(0);
    const [fiatValue, setFiatValue] = useState(85);
    const [amount, setAmount] = useState(0);
    const [minLimit, setMinLimit] = useState(0);
    const [maxLimit, setMaxLimit] = useState(0);
    const [payments, setPayments] = useState([]);
    const [rate, setRate] = useState(0);
    const [time, setTime] = useState(0);
    const wallet = useWallet();

    useEffect(() => {
        console.log(maxLimit)
        if (minLimit * 1 > amount * 1) {
            setMinLimit(amount);
        } 
        if (maxLimit * 1 > amount * 1) {
            setMaxLimit(amount)
        }
        if (minLimit * 1 > maxLimit * 1) {
            setMinLimit(maxLimit)
        }
    }, [maxLimit, amount])

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 flex flex-col text-white my-12">
                <div className="text-2xl text-left">{t("Create A Buy Offer")}</div>
                <div className="text-sm text-second-theme mb-10 text-left">{t("Specify your sell order requirments")}</div>
                {
                    localStorage.getItem("connected")
                        ? <>
                            <div className="flex items-center text-second-theme mb-8 bg-first-theme rounded-tl-lg rounded-tr-lg">
                                <div 
                                    className={`flex-1 border-b-2 ${status === 0 ? "green-border" : "border-gray-500"} py-2`}
                                    onClick={() => setStatus(0)}
                                >
                                    {t("Offer Options")}
                                </div>
                                <BsChevronCompactRight size={24} />
                                <div className={`flex-1 border-b-2 ${status === 1 ? "green-border" : "border-gray-500"} py-2`}>{t("Offer Terms")}</div>
                            </div>
                            {
                                status === 0 &&
                                    <OptionContainer 
                                        changeStatus={setStatus}
                                        setCryptoValue={setCryptoValue}
                                        setFiatValue={setFiatValue}
                                        setAmount={setAmount}
                                        setMinLimit={setMinLimit}
                                        setMaxLimit={setMaxLimit}
                                        setPayments={setPayments}
                                        setTime={setTime}
                                        setRate={setRate}
                                        amount={amount}
                                        minLimit={minLimit}
                                        maxLimit={maxLimit}
                                        payments={payments}
                                        rate={rate}
                                        time={time}
                                        cryptoValue={cryptoValue}
                                        fiatValue={fiatValue} />
                            }
                            {
                                status === 1 &&
                                    <TermContainer 
                                        changeStatus={setStatus}
                                        cryptoType={cryptoValue}
                                        fiatType={fiatValue}
                                        amount={amount}
                                        minLimit={minLimit}
                                        maxLimit={maxLimit}
                                        payments={payments}
                                        time={time}
                                        rate={rate} />
                            }
                        </>
                        : <div className="py-16 border-2 border-first-theme rounded-lg text-2xl">
                            {t("Please connect your wallet")}
                        </div>
                }
            </div>
        </>
    );
}

export default CreateOffer;