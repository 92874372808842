import { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { FadeLoader } from 'react-spinners';
import GreenButton from './GreenButton';

const CancelOrderModal = (props) => {
    const { t } = useTranslation();
    const { isShown, setShown, handleModal, isLoading } = props;
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={isShown} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShown}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-first-theme text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-first-theme px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-white relative">
                                    <div className='text-center green-font text-3xl mb-8'>
                                    {t("Order is cancelled")}
                                    </div>
                                    <div className='text-second-theme text-center mb-8'>
                                    {t("Wait time expired, sell order was cancelled. Your Crypto will be returned to your Wallet")}
                                    </div>
                                    <div className='flex justify-center'>
                                        <GreenButton cssClass="w-5/12" title="Yes, Withdraw" size="lg" onClick={handleModal} active />
                                    </div>
                                </div>
                                {
                                    isLoading && 
                                        <div className='w-full h-full z-50 absolute top-0 left-0 flex items-center justify-center bg-opacity-40 bg-gray-400'>
                                            <FadeLoader loading color='white' />
                                        </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default CancelOrderModal;