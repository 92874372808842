import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { BsTwitter, BsTelegram, BsInstagram } from "react-icons/bs"
import { SiMedium } from "react-icons/si"

const Footer = () => {
    return (
        <div className="bg-first-theme text-white">
            <div className="w-11/12 md:w-6/12 m-auto pt-10 pb-8 flex flex-wrap justify-between text-left text-sm">
                <div className="mr-2 mb-4 flex flex-col">
                    <div className="text-xl font-bold mb-4">Quick Links</div>
                    <a href="https://solana.com/ecosystem/solanacash" target="_blank" rel="noreferrer" className="mb-4">Solana <br />Ecosystem</a>
                    <a href="https://www.certik.com/projects/solana-cash" target="_blank" rel="noreferrer" className="">Certick Audit</a>
                </div>
                <div className="mr-2 mb-4 flex flex-col">
                    <div className="text-xl font-bold mb-4">Explorer</div>
                    <a href="https://solscan.io/token/EKSM2sjtptnvkqq79kwfAaSfVudNAtFYZSBdPe5jeRSt" target="_blank" rel="noreferrer" className="mb-4">Solscan</a>
                    <a href="https://polygonscan.com/address/0x97de33d03e5ebab173c4eb5a7114303d6c5a3b66" target="_blank" rel="noreferrer" className="mb-4">Polygonscan</a>
                    <a href="https://etherscan.io/address/0x4946DB03b7E7bDA0383612AfbC4119A4E8464642" target="_blank" rel="noreferrer" className="mb-4">Etherscan</a>
                    <a href="https://bscscan.com/address/0x7c8acc8803c5a9637f31713adab5b4cca5525037" target="_blank" rel="noreferrer" className="">Bscscan</a>
                </div>
                <div className="mr-2 mb-4 flex flex-col">
                    <div className="text-xl font-bold mb-4">Legal</div>
                    <a href="/terms-conditions" rel="noreferrer" target="_blank" className="mb-4">Terms & <br />Conditions</a>
                    <a href="/risk-disclosure-policy" target="_blank" className="">Risk <br />Disclosure</a>
                </div>
                <div className="mr-2 mb-4 flex flex-col">
                    <div className="text-xl font-bold mb-4">Product</div>
                    <a href="/mobile" target="_blank" className="mb-4">P2P Mobile <br />App</a>
                    <a href="https://staking.solanacash.io/" target="_blank" rel="noreferrer" className="">Staking <br />Platform</a>
                </div>
                <div className="flex flex-col">
                    <div className="text-xl font-bold mb-4">Community</div>
                    <a href="https://twitter.com/mansa_Trade" target="_blank" rel="noreferrer" className="mb-4 flex items-center"><BsTwitter className="mr-2" />Twitter</a>
                    <a href="https://www.linkedin.com/company/solanacash-io/" target="_blank" rel="noreferrer" className="mb-4 flex items-center"><FaLinkedinIn className="mr-2" />LinkedIn</a>
                    <a href="https://instagram.com/solanacash_io" target="_blank" rel="noreferrer" className="mb-4 flex items-center"><BsInstagram className="mr-2" />Instagram</a>
                    <a href="https://medium.com/@solanacashapp" target="_blank" rel="noreferrer" className="mb-4 flex items-center"><SiMedium className="mr-2" />Medium</a>
                    <a href="https://web.facebook.com/Solana-Cash-104634308709386" target="_blank" rel="noreferrer" className="mb-4 flex items-center"><FaFacebookF className="mr-2" />Facebook</a>
                    <a href="https://t.me/solanacashappchat" target="_blank" rel="noreferrer" className="flex items-center"><BsTelegram className="mr-2" />Telegram</a>
                </div>
                {/* <div className="text-4xl py-16 font-bold">Join Our Community</div>
                <div className="text-second-theme px-2">We are always looking forward to your feedback in our community, join our community so you can help contribute in making MansaTrade better. We’d love to hear from you!</div>
                <div className="flex py-10">
                    <div className="bg-black rounded-lg p-2 mr-2"><AiOutlineInstagram size={24} /></div>
                    <div className="bg-black rounded-lg p-2 mr-2"><FaTelegramPlane size={24} /></div>
                    <div className="bg-black rounded-lg p-2 mr-2"><AiOutlineTwitter size={24} /></div>
                    <div className="bg-black rounded-lg p-2"><AiFillGithub size={24} /></div>
                </div> */}
            </div>
            <div className="border-t-2 border-gray-600 py-8">
                © 2023 MansaTrade. All Rights Reserved.
            </div>
        </div>
    );
}

export default Footer;