import { useTranslation } from "react-i18next";

const OfferTermComponent = (props) => {
    const { data } = props;
    const { t } = useTranslation();
    return (
        <div className="text-left mb-16">
            <div className="text-lg mb-8">{t("Offer Terms")}</div>
            <div className="p-4 border-2 border-first-theme rounded-lg text-gray-300">{data}</div>
        </div>
    );
}

export default OfferTermComponent;