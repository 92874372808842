export const CRYPTO_VALUES = [{
    title: "All",
    value: ""
},{
    title: "SOL",
    value: "sol"
}, {
    title: "USDC",
    value: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
}, {
    title: "USDT",
    value: "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
}, {
    title: "WETH",
    value: "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk"
}, {
    title: "WBTC",
    value: "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E"
}, {
    title: "SOLAR",
    value: "2wmKXX1xsxLfrvjEPrt2UHiqj8Gbzwxvffr9qmNjsw8g"
}, {
    title: "SOLCH",
    value: "EKSM2sjtptnvkqq79kwfAaSfVudNAtFYZSBdPe5jeRSt"
}, {
    title: "ZEBEC",
    value: "zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF"
}];

export const TYPE_VALUES = [{
    title: "Buy/Sell",
    value: ""
}, {
    title: "Buy",
    value: "Buy"
}, {
    title: "Sell",
    value: "Sell"
}]

export const STATUS_VALUES = [{
    title: "All Status",
    value: -1
}, {
    title: "Completed",
    value: 1
}, {
    title: "Cancelled",
    value: 2
},  {
    title: "Pending",
    value: 0
}]

export const DISPUTE_VALUES = [{
    title: "Unresponsive Seller",
    value: 0
}, {
    title: "Unresponsive Seller",
    value: 1
}]