import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import GrayButton from "../../components/GrayButton";
import GreenButton from "../../components/GreenButton";
import InputComponent from "../../components/InputComponent";
import OfferDetailInfo from "../../components/OfferDetailInfo";
import OfferTermComponent from "../../components/OfferTermComponent";
import useOrder from "../../utils/orders";
import useEthOrder from "../../utils/eth/orders";
import { PAYMENT_OFFER_VALUES } from "../../constants/offers";
import TransferNotifyModal from "../../components/TransferNotifyModal";
import ConfirmTransferModal from "../../components/ConfirmTransferModal";
import PaymentModal from "../../components/PaymentModal";
import { AppContext } from "../../utils/AppContext";

const BeginContainer = (props) => {
    const { t } = useTranslation();
    const { offerData, offerAccount, tokenBalance } = props;
    const { chainID } = useContext(AppContext);
    const [sellAmount, setSellAmount] = useState(0);
    const [receiveAmount, setReceiveAmount] = useState(0);
    const [paymentIndex, setPaymentIndex] = useState(-1);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [accountName, setAccountName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [check, setCheck] = useState(false);
    const [txSuccess, setTxSuccess] = useState(false);
    const [orderIndex, setOrderIndex] = useState();
    const { isLoading, createOrder } = useOrder();
    const { isEthLoading, createEthOrder} = useEthOrder();
    const navigate = useNavigate();

    const handleNotifyModal = () => {
        setShowNotifyModal(false);
        setShowConfirmModal(true)
    }

    const handleConfirmModal = async () => {
        let result = {success: false};
        if (!chainID) {
            result = await createOrder({...offerData, paymentOption: paymentIndex, sellAmount, receiveAmount, offerAccount, accountName, emailAddress});
        } else {
            result = await createEthOrder({...offerData, paymentOption: paymentIndex, sellAmount, receiveAmount, offerAccount, accountName, emailAddress});
        }
        if (result.success) {
            setTxSuccess(true);
            setOrderIndex(result.index);
            navigate(`/order/sell/${result.index}`, {replace: true});
        }
    }

    const handleSellAmount = value => {
        console.log(offerData.tokenAmount);
        if (value * 1 > offerData.maxLimit) {
            setSellAmount(offerData.maxLimit);
        } else if (value * 1 < offerData.minLimit) {
            setSellAmount(offerData.minLimit)
        } else {
            setSellAmount(value)
        }
    }

    const handlePayment = (item) => {
        setPaymentIndex(item);
        const paymentMethod = PAYMENT_OFFER_VALUES[item].title;
        if (localStorage.getItem(`${paymentMethod}-remember`) !== "false") {
            console.log(localStorage.getItem(`${paymentMethod}-remember`))
            setAccountName(localStorage.getItem(`${paymentMethod}-name`));
            setEmailAddress(localStorage.getItem(`${paymentMethod}-email`));
            setCheck(localStorage.getItem(`${paymentMethod}-remember`));
        } else {
            setAccountName("");
            setEmailAddress("");
            setCheck(false);
        }
        setShowPaymentModal(true);
    }

    const handleFocusForSell = (e) => {
        if (e.target.value >= offerData.tokenAmount) {
            setSellAmount(offerData.tokenAmount);
        } else {
            if (e.target.value > tokenBalance) {
                setSellAmount(tokenBalance);
            }
        }
    }
    
    const handleFocusForAmount = (e) => {
        if (e.target.value / offerData.rate / (1 - offerData.fee) < offerData.minLimit || 
        e.target.value / offerData.rate / (1 - offerData.fee) > tokenBalance) {
            offerData.minLimit > tokenBalance ? setSellAmount(0) : setSellAmount(offerData.minLimit);
        }
    }

    const setAmountByBalance = (value) => {
        const amount = (tokenBalance * value).toFixed(2);
        if (amount < offerData.minLimit) {
            setSellAmount(offerData.minLimit);
        } else if (amount > offerData.maxLimit) {
            setSellAmount(offerData.maxLimit);
        } else {
            setSellAmount(amount);
        }

    }
    
    useEffect(() => {
        setReceiveAmount(parseFloat((sellAmount * (1 - offerData.fee / 100) * offerData.rate).toFixed(2)))
    }, [sellAmount, offerData])

    useEffect(() => {
        setSellAmount(parseFloat((receiveAmount / offerData.rate / (1 - offerData.fee / 100)).toFixed(2)));
    }, [receiveAmount, offerData])

    return (
        <>
            <a href="/offers">
                <div className="text-2xl text-left flex items-center mb-10">
                    <AiOutlineArrowLeft className="mr-4"/>{t("View Offer")}
                </div>
            </a>
            <div className="flex justify-between mb-8">
                <div className="flex flex-col w-4/12">
                    <InputComponent 
                        min={offerData.minLimit}
                        max={offerData.maxLimit}
                        title={t("Amount to sell")} 
                        placeHolder="Enter amount here" 
                        onChange={handleSellAmount} 
                        suffix={offerData.tokenName.toUpperCase()}
                        value={sellAmount}
                        handleFocus={handleFocusForSell} />
                    <div className="flex flex-wrap items-center justify-between text-sm green-font">
                        <div className="flex-1 flex justify-between mr-4">
                            <span className="px-2 py-1 bg-gray-700 rounded-lg cursor-pointer" onClick={() => setAmountByBalance(0.25)}>25%</span>
                            <span className="px-2 py-1 bg-gray-700 rounded-lg cursor-pointer" onClick={() => setAmountByBalance(0.5)}>50%</span>
                            <span className="px-2 py-1 bg-gray-700 rounded-lg cursor-pointer" onClick={() => setAmountByBalance(0.75)}>75%</span>
                            <span className="px-2 py-1 bg-gray-700 rounded-lg cursor-pointer" onClick={() => setAmountByBalance(1)}>100%</span>
                        </div>
                        <div>Balance: {tokenBalance.toFixed(2)}</div>
                    </div>
                </div>
                <InputComponent 
                    cssClass="w-4/12"
                    title={t("Amount to receive")} 
                    placeHolder="" 
                    onChange={setReceiveAmount} 
                    suffix={offerData.fiat.toUpperCase()}
                    value={receiveAmount}
                    handleFocus={handleFocusForAmount}  />
            </div>
            <div className="flex flex-col items-start mb-8">
                <div className="text-sm text-second-theme mb-2 text-left">{t("Payment option")}</div>
                <div className="flex">
                    {
                        offerData.paymentOptions.toString().split(",").map((item, index) => 
                            item * 1 >= 0 &&
                                <div 
                                    key={index} 
                                    onClick={() => handlePayment(item)}
                                    className={`bg-first-theme flex items-center justify-center rounded-lg green-font px-4 py-2 mr-2 cursor-pointer ${paymentIndex === item ? "border-2 green-border" : "border-2 border-transparent"}`}
                                >
                                    {PAYMENT_OFFER_VALUES[item * 1].title}
                                </div>
                        )
                    }
                </div>
            </div>
            <OfferDetailInfo offerData={offerData} />
            <OfferTermComponent data={offerData.offerTerms.toString()} />
            <div className="flex items-start justify-between md:justify-start mb-8">
                <a href="/offers">
                    <GrayButton title={t("Cancel")} size="lg" />    
                </a>
                <GreenButton 
                    title={t("Begin Trade")} 
                    size="lg" 
                    cssClass="ml-8 px-8" 
                    active={sellAmount && receiveAmount && paymentIndex !== -1 && sellAmount >= offerData.minLimit && sellAmount <= offerData.maxLimit && accountName && emailAddress} 
                    onClick={() => setShowNotifyModal(true)} />
            </div>
            <PaymentModal
                isShown={showPaymentModal} 
                setShown={setShowPaymentModal}
                setAccountName={setAccountName}
                setEmailAddress={setEmailAddress}
                setCheck={setCheck}
                accountName={accountName}
                emailAddress={emailAddress}
                check={check}
                paymentMethod={paymentIndex > -1 ? PAYMENT_OFFER_VALUES[paymentIndex].title : "" } />
            <TransferNotifyModal 
                isShown={showNotifyModal} 
                setShown={setShowNotifyModal} 
                handleModal={handleNotifyModal} />
            <ConfirmTransferModal 
                isLoading={!chainID ? isLoading : isEthLoading}
                isShown={showConfirmModal} 
                setShown={setShowConfirmModal} 
                handleModal={handleConfirmModal} 
                sellAmount={sellAmount}
                tokenName={offerData.tokenName}
                txSuccess={txSuccess}
                orderIndex={orderIndex} />
        </>
    );
}

export default BeginContainer;