import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from '@solana/wallet-adapter-react';
import { GoThreeBars } from "react-icons/go";
import useCookies from "react-cookie/cjs/useCookies";
import { AppContext } from "../utils/AppContext";
import LogoImg from "../assets/images/logo.png";
import MobileLogoImg from "../assets/images/logo-mobile.png";
import { CookiesProvider } from "react-cookie";
import SelectWrapper from "./SelectWrapper";
import Web3 from "web3";
import { ETH_CHAIN_ID, BSC_CHAIN_ID, AVAX_CHAIN_ID, MATIC_CHAIN_ID } from "../constants/env";
import checkDevice from "../utils/checkDevice";

const Header = (props) => {
    const [ cookies, setCookie ] = useCookies(['solcash']);
    const [ethAddress, setEthAddress] = useState("");

    const { chainState, setChainState, web3Modal, chainID, setChainID } = useContext(AppContext);
    const url = window.location.pathname;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showDropDown, setShowDropDown] = useState(false);
    const [showNotify, setShowNotify] = useState(false);
    
    const wallet = useWallet();

    const handleBtn = () => {
        navigate("/");
    }


    const outSelectChainClickFunc = () => {
        setShowDropDown(false)
    }

    const disconnectWallet = async () => {
        const { web3 } = chainState;
        if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
        }
        await web3Modal.clearCachedProvider();
        setChainState({
            fetching: false,
            address: "",
            web3: null,
            provider: null,
            connected: false,
            chainId: 1,
            networkId: 1,
            assets: [],
            showModal: false,
            pendingRequest: false,
            result: null
        })
    }

    const connectWallet = async () => {
        const provider = await web3Modal.connect();
        await subscribeProvider(provider);
        await provider.enable();
        const web3 = initWeb3(provider);
        const accounts = await web3.eth.getAccounts();
        const address = accounts[0];
        const networkId = await web3.eth.net.getId();
        const chainId = await web3.eth.chainId();
        setCookie("ethConnected", true);

        await setChainState({
            web3,
            provider,
            connected: true,
            address,
            chainId,
            networkId
        })
    }

    const initWeb3 = (provider) => {
        const web3 = new Web3(provider);
        web3.eth.extend({
            methods: [
              {
                name: "chainId",
                call: "eth_chainId",
                outputFormatter: web3.utils.hexToNumber
              }
            ]
        });

        return web3;
    }

    const subscribeProvider = async (provider) => {
        if (!provider.on) {
            return;
        }
        provider.on("close", () => disconnectWallet());
        provider.on("accountsChanged", async (accounts) => {
            setEthAddress(accounts[0])
        });
        provider.on("chainChanged", async (chainId) => {
            setChainID(parseInt(chainId, 16));
            // await connectWallet();
        });
    };

    const handleChainID = (id) => {
        setChainID(id);
        setCookie("chainID", id)
    }

    useEffect(() => {
        (
            async () => {
                if (chainID && chainState.connected) {
                    await chainState.provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{chainId: `0x${chainID.toString(16)}`}]
                    });
                    const { web3 } = chainState;
                    const networkId = await web3.eth.net.getId();
                    await setChainState({...chainState, chainId: chainID, networkId});
                } else if (chainID && !chainState.connected && web3Modal) {
                    await connectWallet();
                }
            }
        )();
    }, [chainID, web3Modal]);

    useEffect(() => {
        if (chainID) {
            if (!window.ethereum) {
                setShowNotify(true);
            } else {
                setShowNotify(false);
            }
        } else {
            setShowNotify(false);
        }
    }, [chainID])

    useEffect(() => {
        setChainState({...chainState, address: ethAddress})
    }, [ethAddress])

    useEffect(() => {
        (
            async () => {
                if (chainState.provider && chainState.chainId !== chainID) {
                    await chainState.provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{chainId: `0x${chainID.toString(16)}`}]
                    });
                    const { web3 } = chainState;
                    const networkId = await web3.eth.net.getId();
                    await setChainState({...chainState, chainId: chainID, networkId});
                }
            }
        )()
    }, [chainState])
    
    useEffect(() => {
        console.log(wallet)
        if ((!chainID && wallet.connecting) || (chainID && chainState.connected)) {
            localStorage.setItem("connected", true);
        } 
        if ((!chainID && wallet.disconnecting) || (chainID && !chainState.connected)) {
            localStorage.setItem("connected", false);
        }
    }, [wallet, chainState])

    return (
        <div className="flex justify-between items-center py-8 px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58">
            <a className="hidden lg:block w-52" onClick={handleBtn}><img src={LogoImg} alt="logo" /></a>
            <a className="block lg:hidden w-10" onClick={handleBtn}><img src={MobileLogoImg} alt="logo" /></a>
            {
                url !== "/mobile" &&
                    <div className="flex flex-row">
                        {
                            showNotify && !cookies.ethConnected &&
                                <a href='https://metamask.io/download.html' target="_blank" className='border-2 rounded-lg border-white font-bold text-white mr-2 flex items-center'>
                                    <span className="hidden md:block px-4 py-3">Please install MetaMask</span>
                                    <span className="block md:hidden px-4 py-2 text-xs">Please install <br/>MetaMask</span>
                                </a>
                        }
                        <SelectWrapper outSideClickFunc={outSelectChainClickFunc}>
                            <div className="relative text-white font-bold">
                                <div 
                                    onClick={() => setShowDropDown(!showDropDown)}
                                    className="px-4 py-3 border-2 rounded-lg border-white mr-2 cursor-pointer"
                                >
                                    <span className="hidden md:block">Select Network</span>
                                    <GoThreeBars className="block md:hidden text-xl" />
                                </div>
                                <div className={`absolute flex-col mt-2  md:right-0 w-max dropdown-bg p-2 z-10 ${showDropDown ? "flex" : "hidden"}`}>
                                    <div 
                                        className={`px-4 py-2 cursor-pointer ${!chainID ? "custom-gradient" : "dropdown-item"} mb-2 rounded-lg`}
                                        onClick={() => handleChainID(null)}
                                    >
                                        Solana
                                    </div>
                                    <div 
                                        // className={`px-4 py-2 cursor-pointer opacity-30 dropdown-item mb-2 rounded-lg`}
                                        className={`px-4 py-2 cursor-pointer ${chainID === ETH_CHAIN_ID ? "custom-gradient" : "dropdown-item"} mb-2 rounded-lg`}
                                        onClick={() => handleChainID(ETH_CHAIN_ID)}
                                        >
                                        Ethereum
                                    </div>
                                    <div 
                                        className={`px-4 py-2 cursor-pointer ${chainID === BSC_CHAIN_ID ? "custom-gradient" : "dropdown-item"} mb-2 rounded-lg`}
                                        onClick={() => handleChainID(BSC_CHAIN_ID)}
                                        >
                                        Binance Smart Chain
                                    </div>
                                    <div 
                                        className={`px-4 py-2 cursor-pointer ${chainID === AVAX_CHAIN_ID ? "custom-gradient" : "dropdown-item"} mb-2 rounded-lg`}
                                        onClick={() => handleChainID(AVAX_CHAIN_ID)}
                                        >
                                        Avalanche
                                    </div>
                                    <div 
                                        className={`px-4 py-2 cursor-pointer ${chainID === MATIC_CHAIN_ID ? "custom-gradient" : "dropdown-item"} mb-2 rounded-lg`}
                                        onClick={() => handleChainID(MATIC_CHAIN_ID)}
                                    >
                                        Polygon
                                    </div>
                                </div>
                            </div>
                        </SelectWrapper>
                        {
                            !chainID
                                ? <WalletMultiButton className={`custom-gradient wallet-button show-button`} />
                                : chainState.connected 
                                    ? <div className="connect-wallet-button" onClick={disconnectWallet}>
                                        {chainState.address.slice(0, 4) + "..." + chainState.address.substring(chainState.address.toString().length - 4)}
                                    </div>
                                    : <div className="connect-wallet-button" onClick={connectWallet}>Select Wallet</div>
                        }
                        
                    </div>
            }
            <CookiesProvider />
        </div>
    );
}

export default Header;