import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsThreeDotsVertical } from "react-icons/bs";
import CancelOfferModal from "./CancelOfferModal";
import { PAYMENT_OFFER_VALUES } from "../constants/offers";
import useOffer from "../utils/offers";

const MyOfferTableMobile = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { datas, setOfferList } = props;
    const [showModal, setShowModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedAccount, setSelectedAccount] = useState();

    const { discontinueOffer } = useOffer();

    const handleCollapse = (index) => {
        if (index === selectedIndex) {
            setSelectedIndex(-1)
        } else {
            setSelectedIndex(index)
        }
    }

    const handleCancel = (offerAccount) => {
        setSelectedAccount(offerAccount);
        setShowModal(true);
    }

    const cancelOffer = async () => {
        const newMyOfferList = await discontinueOffer(selectedAccount);
        setOfferList(newMyOfferList);
    }


    return (
        <div className="mb-16">
            {
                datas.length === 0
                    ? <div className="text-2xl mt-16 border-2 border-first-theme rounded-lg py-16">{t("There is no data.")}</div>
                    : datas.map((data, index) =>
                        <div key={index} className="flex flex-col p-2 text-left border-2 border-first-theme rounded-lg mb-2">
                            <div className="flex justify-between mb-2">
                                <div className="flex">
                                    <div className="text-xs bg-first-theme rounded-lg green-font px-2 py-1 mr-2">{t("Sell")}</div>
                                    <div className="">{data.tokenName.toUpperCase()} {data.bought.toString()}</div>
                                </div>
                                {
                                        data.status && 
                                            <div className="relative" onClick={() => handleCollapse(index)}>
                                                <BsThreeDotsVertical className="cursor-pointer" />
                                                <div className={`absolute mt-1 right-0 w-max text-white bg-first-theme rounded-lg flex flex-col text-left z-50 ${selectedIndex === index ? "block" : "hidden"}`}>
                                                    <a
                                                        onClick={() => navigate(`/offer/edit/${data.offer}`)} 
                                                        className="px-4 py-4 items-center cursor-pointer hover:green-font"
                                                    >
                                                        {t("Edit Offer")}
                                                    </a>
                                                    <div 
                                                        className="px-4 py-4 items-center cursor-pointer hover:green-font"
                                                        onClick={() => handleCancel(data.offer)}
                                                    >
                                                        {t("Discontinued Offer")}
                                                    </div>
                                                </div>
                                            </div>
                                    }
                            </div>
                            <div className="flex mb-2 justify-between">
                                <div className="mb-2 flex flex-col">
                                    <span className="mb-1 text-sm text-second-theme">Rate</span>
                                    <span>{data.rate} {data.fiat.toString().toUpperCase()}</span>
                                </div>
                                <div className="flex ">
                                    <div className="flex flex-col mr-2">
                                        <span>{t("Buying")}</span>
                                        <span>{t("Limit")}</span>
                                    </div>
                                    <div className="flex flex-col mb-2">
                                        <div className="">{data.tokenName.toUpperCase()} {data.tokenAmount}</div>
                                        <div className="">{data.minLimit.toLocaleString("en-US")} - {data.maxLimit.toLocaleString("en-US")}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-2">
                                {
                                    data.paymentOptions.toString().split(",").map((item, index) => 
                                        item * 1 >= 0 && <div key={index} className="text-xs bg-first-theme rounded-lg green-font p-2 mr-2">{PAYMENT_OFFER_VALUES[item * 1].title}</div>
                                    )
                                }
                            </div>
                            <span className="text-sm">{data.createdAt}</span>
                        </div>
                    )
            }
            <CancelOfferModal isShown={showModal} setShown={setShowModal} handleModal={cancelOffer} />
        </div>
    );
}

export default MyOfferTableMobile;