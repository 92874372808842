import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useWallet as solanaWallet } from '@solana/wallet-adapter-react';
import { BiHelpCircle } from "react-icons/bi";
import Navbar from "../components/Navbar";
import { AppContext } from "../utils/AppContext";
import useWallet from "../utils/wallets";
import useEthWallet from "../utils/eth/wallets";
import { SOLANA_API_URL } from "../constants/wallet";
import { FadeLoader } from "react-spinners";

const WallerWrapper = () => {
    const { chainID, chainState } = useContext(AppContext);
    const wallet = solanaWallet();
    const { isLoading, allTokens } = useWallet();
    const { isEthLoading, allEthTokens } = useEthWallet();

    return (
        <Wallet 
            walletAddress={!chainID ? wallet.publicKey : chainState.address}
            isLoading={!chainID ? isLoading : isEthLoading}
            allTokens={!chainID ? allTokens : allEthTokens} />
    );
}

const Wallet = ({walletAddress, isLoading, allTokens}) => {
    const { t } = useTranslation();
    const [tokenName, setTokenName] = useState("");
    const [selectedIndex, setSelectedIndex ] = useState(0);
    const [balance, setBalance] = useState(0);
    const [price, setPrice] = useState(0);

    const changeToken = (index) => {
        setSelectedIndex(index);
    };
    
    useEffect(() => {
        if (allTokens.length > 0) {
            (
                async () => {
                    setTokenName(allTokens[selectedIndex].tokenName);
                    const res = await axios(`${SOLANA_API_URL}${allTokens[selectedIndex].tokenName}`);
                    const tokenPrice = res.data.data.priceUsdt;
                    setPrice(tokenPrice);
                    setBalance(allTokens[selectedIndex].balance);
                }
            )();
        }
    }, [selectedIndex, allTokens])

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 text-white">
                <div className="text-2xl font-bold mt-12 mb-2 text-left">{t("Trading Wallet")}</div>
                <div className="text-sm text-second-theme mb-10 text-left">{t("This is your trade locked fund")}</div>
                {
                    isLoading
                        ? <div className="w-full flex justify-center">
                            <FadeLoader loading color="white" />
                        </div>
                        : walletAddress
                            ? <div className="px-8 py-4 bg-first-theme rounded-lg">
                                    <div className="flex justify-between  mb-10 flex-col md:flex-row">
                                        <div className="text-left mb-2">{t("Locked Balance")}</div>
                                        <div className="flex text-sm items-start lg:items-center">
                                            <div className=" text-second-theme mr-4 mb-2">{t("Token:")}</div>
                                            <div className="flex flex-wrap">
                                                {
                                                    allTokens.length > 0
                                                        ? allTokens.map((item, index) => 
                                                            <div 
                                                                key={index}
                                                                onClick={() => changeToken(index)}
                                                                className={`${index === selectedIndex ? "custom-gradient" : "bg-gray-500"} cursor-pointer text-gray-300 px-2 py-1 mr-2 rounded-lg mb-2`}
                                                            >
                                                                {item.tokenName.toUpperCase()}
                                                            </div>
                                                        )
                                                        : <div className="mb-2">{t("There is no locked token")}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className=""></div>
                                    </div>
                                    <div className="text-left text-3xl font-bold mb-2">{balance} {tokenName.toUpperCase()}</div>
                                    <div className="text-left text-second-theme mb-4">${(price * balance).toFixed(3)}</div>
                                    <div className="text-second-theme text-sm flex items-center"><BiHelpCircle className="mr-4" />{t("This is fund from your pending orders")}</div>
                                </div>
                            : <div className="py-16 border-2 border-first-theme rounded-lg text-2xl">
                                {t("Please connect your wallet")}
                            </div>
                }
            </div>
        </>
    );
}

export default WallerWrapper;