import { useTranslation } from 'react-i18next';
import useStats from "../../utils/stats";
import useEthStats from '../../utils/eth/stats';
import MoneyImg from "../../assets/images/money.png";
import WalletImg from "../../assets/images/wallet.png";
import ClockImg from "../../assets/images/clock.png";
import { useEffect, useState, useContext } from "react";
import { AppContext } from '../../utils/AppContext';
import CountUp from 'react-countup';

const InfoContainer = () => {
    const { chainID } = useContext(AppContext);
    const { t } = useTranslation();
    const { statsData } = useStats();
    const { ethStatsData } = useEthStats();
    const [volume, setVolume] = useState(0);
    const [startVolume, setStartVolume] = useState(0);
    const [endVolume, setEndVolume] = useState(0);
    const [startTransactions, setStartTransactions] = useState(0);
    const [endTransactions, setEndTransactions] = useState(0);
    const [startWallets, setStartWallets] = useState(0);
    const [endWallets, setEndWallets] = useState(0);
    const [transactions, setTransactions] = useState(0);
    const [wallets, setWallets] = useState(0);
    
    useEffect(() => {
        let newVolume = 0;
        let newWallets = [];
        const newStatsData = !chainID ? statsData : ethStatsData;

        newStatsData.forEach(item => {
            newVolume += item.fiatAmount * item.price;
            if (newWallets.indexOf(item.seller) < 0) {
                newWallets.push(item.seller);
            }
            
            if (newWallets.indexOf(item.buyer) < 0) {
                newWallets.push(item.buyer);
            }
        })
        setStartVolume(endVolume);
        setEndVolume(newVolume);
        setStartTransactions(endTransactions);
        setEndTransactions(newStatsData.length)
        setStartWallets(endWallets);
        setEndWallets(newWallets.length);
    }, [statsData, ethStatsData, chainID]);

    return (
        <div className="my-8 md:my-16 lg:my-32 flex flex-col lg:flex-row justify-between px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58">
            <div className="third-one-part mb-2 p-8 bg-first-theme flex items-center rounded-xl">
                <img className="mr-6" src={MoneyImg} alt="money" />
                <div className="flex flex-col text-left">
                    <span className=" text-xl xl:text-3xl font-bold mb-4">
                        <CountUp start={startVolume} end={endVolume} decimals={2} duration={5} />
                    </span>
                    <span className="text-second-theme">{t("Total Volume")}</span>
                </div>
            </div>
            <div className="third-one-part mb-2 p-8 bg-first-theme flex items-center rounded-xl">
                <img className="mr-6" src={ClockImg} alt="clock" />
                <div className="flex flex-col text-left">
                    <span className="text-xl xl:text-3xl font-bold mb-4">
                        <CountUp start={startTransactions} end={endTransactions} duration={5} />
                    </span>
                    <span className="text-second-theme">{t("Transactions")}</span>
                </div>
            </div>
            <div className="third-one-part mb-2 p-8 bg-first-theme flex items-center rounded-xl">
                <img className="mr-6" src={WalletImg} alt="wallet" />
                <div className="flex flex-col text-left">
                    <span className="text-xl xl:text-3xl font-bold mb-4">
                        <CountUp start={startWallets} end={endWallets} duration={5} />
                    </span>
                    <span className="text-second-theme">{t("Active Wallets")}</span>
                </div>
            </div>
        </div>
    );
}

export default InfoContainer;