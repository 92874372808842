import { useTranslation } from "react-i18next";
import { AiOutlineArrowRight, AiOutlineLink } from "react-icons/ai";
import { FadeLoader } from 'react-spinners';

const NotifyList = (props) => {
    const { datas, isLoading } = props;
    const { t } = useTranslation();

    return (
        <div className="w-full mb-16 md:mb-24">
            {
                isLoading
                    ?  <div className="w-full h-full  flex items-center justify-center border-2 border-first-theme py-8 rounded-lg">
                            <FadeLoader loading color='white' />
                        </div>
                    : datas.length > 0
                        ? <div className="flex flex-col border-2 border-first-theme rounded-lg">
                            {
                                datas.map((item, index) =>
                                    <div key={index} className={`flex items-center p-2 md:p-4 ${index === (datas.length - 1) ? "" : "border-b-2"}  border-first-theme text-left`}>
                                        <div className="hidden md:block w-2 h-2 green-bg rounded-full mr-4" />
                                        <div className="flex-1">
                                            <div className="flex justify-between mb-2">
                                                <p className="text-sm md:text-lg">
                                                    {
                                                        item.type === "create"
                                                            && `${t("New order is created for")} ${item.amount} ${item.tokenName.toUpperCase()}`
                                                    }
                                                    {
                                                        item.type === "buyer_confirm"
                                                            && `${t("Buyer confirm payment for")} ${item.amount} ${item.tokenName.toUpperCase()}`
                                                    }
                                                    {
                                                        item.type === "seller_confirm"
                                                            && <div className="flex items-center">
                                                                {t("Order is completed for")} {item.amount} {item.tokenName.toUpperCase()} 
                                                                <a className="ml-2" href={`https://solscan.io/tx/${item.tx}`} target="_blank" rel="noreferrer"><AiOutlineLink /></a>
                                                            </div>
                                                    }
                                                    {
                                                        item.type === "cancel"
                                                            && `${t("Order is cancelled for")} ${item.amount} ${item.tokenName.toUpperCase()}`
                                                    }
                                                </p>
                                                <p className="text-xs md:text-base text-second-theme text-right">{item.createdAt}</p>
                                            </div>
                                            <div className="flex flex-col md:flex-row justify-between">
                                                <p className="text-xs md:text-base text-second-theme">
                                                    {
                                                        item.type === "create"
                                                            && `${t("This order is created by")} ${item.user.slice(0, 6)}...${item.user.substring(item.user.length - 7)}`
                                                    }
                                                    {
                                                        item.type === "buyer_confirm"
                                                            && `${t("This order is confirmed by")} ${item.user.slice(0, 6)}...${item.user.substring(item.user.length - 7)}`
                                                    }
                                                    {
                                                        item.type === "seller_confirm"
                                                            && `${t("This order is completed by")} ${item.user.slice(0, 6)}...${item.user.substring(item.user.length - 7)}`
                                                    }
                                                    {
                                                        item.type === "cancel"
                                                            && `${t("This order is cancelled by")} ${item.user.slice(0, 6)}...${item.user.substring(item.user.length - 7)}`
                                                    }
                                                </p>
                                                <a href={item.link} className="flex items-center justify-end mt-2 md:mt-0 cursor-pointer">
                                                    <span className="mr-4">View</span><AiOutlineArrowRight color="#68CEC7" size={20}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            )
                            }
                        </div>
                        : <div className="py-16 border-2 border-first-theme rounded-lg text-2xl">
                            You currently have no notifications
                        </div>
            }
        </div>
    );
}

export default NotifyList;