import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { BsChevronCompactRight } from "react-icons/bs"
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import OptionContainer from "../containers/CreateOffer/OptionContainer";
import TermContainer from "../containers/CreateOffer/TermContainer";
import Navbar from "../components/Navbar";
import useOffer from "../utils/offers";
import useEthOffer from "../utils/eth/offers";
import { CRYPTO_VALUES, FIAT_VALUES, TIME_VALUES } from "../constants/offers";
import { ETH_CRYPTO_VALUES } from "../constants/eth/offers";
import { AppContext } from "../utils/AppContext";

const EditOfferWrapper = () => {
    const { chainID, chainState } = useContext(AppContext);
    const { isLoading, getOfferData } = useOffer();
    const { isEthLoading, getEthOfferData } = useEthOffer();
    const wallet = useWallet();

    return (
        <EditOffer 
            walletAddress={!chainID ? wallet.publicKey : chainState.address}
            cryptoValues={!chainID ? CRYPTO_VALUES : ETH_CRYPTO_VALUES[chainID]}
            isLoading={!chainID ? isLoading : isEthLoading}
            getOfferData={!chainID ? getOfferData : getEthOfferData} />
    )
}

const EditOffer = ({walletAddress, cryptoValues, isLoading, getOfferData}) => {
    const { t } = useTranslation();
    const location = useLocation().pathname;
    const offerAccount = location.substring(location.lastIndexOf('/') + 1);
    const [status, setStatus] = useState(0);
    const [cryptoValue, setCryptoValue] = useState(0);
    const [fiatValue, setFiatValue] = useState(0);
    const [amount, setAmount] = useState(0);
    const [minLimit, setMinLimit] = useState(0);
    const [maxLimit, setMaxLimit] = useState(0);
    const [payments, setPayments] = useState([]);
    const [rate, setRate] = useState(20000);
    const [time, setTime] = useState(0);
    const [terms, setTerms] = useState();

    useEffect(() => {
        (async () => {
            const offerData = await getOfferData(offerAccount);
            console.log(offerData)
            setAmount(offerData.tokenAmount);
            setMinLimit(offerData.minLimit);
            setMaxLimit(offerData.maxLimit);
            setRate(offerData.rate);
            setPayments(offerData.paymentOptions.split(",").map(e => e * 1));
            setTime(TIME_VALUES.map(item => item.value).indexOf(offerData.timeLimit));
            setTerms(offerData.offerTerms);
            setCryptoValue(offerData.main ? 0 : cryptoValues.map(item => item.value).indexOf(offerData.token.toString()));
            setFiatValue(FIAT_VALUES.map(item => item.value).indexOf(offerData.fiat));
        })()
    }, [])

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 flex flex-col text-white my-12 relative">
                <div className="text-2xl text-left">{t("Edit A Buy Offer")}</div>
                <div className="text-sm text-second-theme mb-10 text-left">{t("Specify your sell order requirments")}</div>
                {
                    walletAddress
                        ? <>
                            <div className="flex items-center text-second-theme mb-8 bg-first-theme rounded-tl-lg rounded-tr-lg">
                                <div className={`flex-1 border-b-2 ${status === 0 ? "green-border" : "border-gray-500"} py-2`}>{t("Offer Options")}</div>
                                <BsChevronCompactRight size={24} />
                                <div className={`flex-1 border-b-2 ${status === 1 ? "green-border" : "border-gray-500"} py-2`}>{t("Offer Terms")}</div>
                            </div>
                            {
                                status === 0 &&
                                    <OptionContainer 
                                        changeStatus={setStatus}
                                        setCryptoValue={setCryptoValue}
                                        setFiatValue={setFiatValue}
                                        setAmount={setAmount}
                                        setMinLimit={setMinLimit}
                                        setMaxLimit={setMaxLimit}
                                        setPayments={setPayments}
                                        setTime={setTime}
                                        setRate={setRate}
                                        amount={amount}
                                        minLimit={minLimit}
                                        maxLimit={maxLimit}
                                        payments={payments}
                                        rate={rate}
                                        time={time}
                                        cryptoValue={cryptoValue}
                                        fiatValue={fiatValue}
                                        isLoading={isLoading}
                                        isUpdate />
                            }
                            {
                                status === 1 &&
                                    <TermContainer 
                                        cryptoType={cryptoValue}
                                        fiatType={fiatValue}
                                        amount={amount}
                                        minLimit={minLimit}
                                        maxLimit={maxLimit}
                                        payments={payments}
                                        time={time}
                                        rate={rate}
                                        oldTerms={terms}
                                        isUpdate
                                        offerAccount={offerAccount} />
                            }
                        </>
                        : <div className="py-16 border-2 border-first-theme rounded-lg text-2xl">
                            Please connect your wallet
                        </div>
                }
            </div>
        </>
    );
}

export default EditOfferWrapper;