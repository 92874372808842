import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { RiArrowLeftLine } from 'react-icons/ri';
import { PAYMENT_OFFER_VALUES } from '../constants/offers';
import SelectWrapper from './SelectWrapper';


const SelectPaymentModal = (props) => {
    const { t } = useTranslation();
    const { isShown, setShown, handleModal, method, paymentIndexes, goBack } = props;
    const [filterItems, setFilterItems] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const cancelButtonRef = useRef(null)

    const outSideClickFunc = () => {
        setShown(false)
    }

    useEffect(() => {
        if (searchKey) {
            const newFilterItems = PAYMENT_OFFER_VALUES.filter(item => item.title.toLowerCase().indexOf(searchKey.toLowerCase()) > -1);
            setFilterItems(newFilterItems);
        } else {
            setFilterItems(PAYMENT_OFFER_VALUES)
        }
    }, [searchKey])

    return (
        <Transition.Root show={isShown}>
            <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
            </Transition.Child>

                <div className="fixed inset-0 z-10 ">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <SelectWrapper outSideClickFunc={outSideClickFunc}>
                                <div className="bg-first-theme px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-white relative flex flex-col">
                                    <div className='flex justify-between items-center text-3xl mb-8'>
                                        <RiArrowLeftLine onClick={goBack} />
                                        <span className='green-font '>
                                            {
                                                method === "all"
                                                    ? t("All Payments")
                                                    : method === "bank"
                                                        ? t("Bank Transfer")
                                                        : t("Online Wallet")
                                            }   
                                        </span>
                                        <div />
                                    </div>
                                    <input className='m-2 p-1 w-full bg-transparent border-2 border-gray-500 rounded-lg' value={searchKey} onChange={e => setSearchKey(e.target.value)} />
                                    <div className='text-xl max-h-96 overflow-auto overflow-ios'>
                                        {
                                            method === "all" &&
                                                filterItems.map((item, index) => 
                                                    paymentIndexes.indexOf(item.value) > -1 
                                                        ? <div key={index} className='p-2 cursor-pointer rounded-lg text-gray-500'>{item.title}</div>
                                                        : <div key={index} className='p-2 cursor-pointer rounded-lg hover:bg-gray-500' onClick={() => handleModal(item.value)}>{item.title}</div>
                                                    
                                                )
                                        }
                                        {
                                            method === "bank" &&
                                                filterItems.filter((item) => item.value === 5).map((item, index) => 
                                                    paymentIndexes.indexOf(item.value) > -1 
                                                        ? <div key={index} className='p-2 cursor-pointer rounded-lg text-gray-500'>{item.title}</div>
                                                        : <div key={index} className='p-2 cursor-pointer rounded-lg hover:bg-gray-500' onClick={() => handleModal(item.value)}>{item.title}</div>
                                                )
                                        }
                                        {
                                            method === "wallet" &&
                                                filterItems.filter((item) => item !== 5).map((item, index) => 
                                                    paymentIndexes.indexOf(item.value) > -1 
                                                        ? <div key={index} className='p-2 cursor-pointer rounded-lg text-gray-500'>{item.title}</div>
                                                        : <div key={index} className='p-2 cursor-pointer rounded-lg hover:bg-gray-500' onClick={() => handleModal(item.value)}>{item.title}</div>
                                                )
                                        }
                                    </div>
                                </div>
                            </SelectWrapper>    
                        </Transition.Child>
                    </div>
                </div>
        </Transition.Root>
    )
}

export default SelectPaymentModal;