import { useState, useMemo, useEffect, useRef } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
    Backend,
    Dialect,
    EncryptionKeysStore,
    TokenStore,
    ThreadMemberScope
} from '@dialectlabs/sdk';
import { FadeLoader } from 'react-spinners';
import { NEXT_PUBLIC_SOLANA_RPC_HOST } from '../constants/env';
import { RiThumbUpLine, RiThumbDownLine } from "react-icons/ri";
import { HiBadgeCheck } from "react-icons/hi";
import { BsChatLeftText } from "react-icons/bs";
import { FiArrowDownCircle } from "react-icons/fi";
import { AiOutlineSend } from "react-icons/ai";
import { PublicKey } from "@solana/web3.js";

const convertWalletForDialect = (wallet) => ({...wallet});

const ChatContainer = (props) => {
    const { data } = props;
    const [showChatHistory, setShowChatHistory] = useState(false);
    const [connected, setConnected] = useState(false);
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState("");
    const [thread, setThread] = useState();
    const [getStatus, setGetStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const wallet = useWallet();
    const bottomRef = useRef(null);
    const backends = [Backend.DialectCloud, Backend.Solana];
    const dialectCloud = {
        url: 'https://dialectapi.to',
        tokenStore: TokenStore.createInMemory(),
    };
    const environment = 'production';
    const encryptionKeysStore = EncryptionKeysStore.createInMemory();
    const solana = {
        rpcUrl: NEXT_PUBLIC_SOLANA_RPC_HOST,
    };
    const dialectWallet = useMemo(() => convertWalletForDialect(wallet), [wallet]);
    

    const handleKey = async (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            setText("");
            setGetStatus(false);
            await thread.send({text});
            const newMessages = await thread.messages();
            setMessages(newMessages.reverse());
        }
        if (!getStatus) {
            const newMessages = await thread.messages();
            setGetStatus(true);
            setMessages(newMessages.reverse());
        }
    }

    const handleSend = async () => {
        setText("");
        await thread.send({text});
        const newMessages = await thread.messages();
        setMessages(newMessages.reverse());
    }

    useEffect(() => {
        if (wallet.publicKey && data.buyer !== "XXXXXXXX" && showChatHistory && !connected) {
            (
                async () => {
                    try {
                        setIsLoading(true);
                        const sdk = Dialect.sdk({
                            backends,
                            dialectCloud,
                            environment,
                            encryptionKeysStore,
                            solana,
                            wallet: dialectWallet,
                        });
                        const threads = await sdk.threads.findAll();
                        const selectedThread = threads.filter(item => item.otherMembers[0].publicKey.toString() === data.buyer);
                        let newThread;
                        if (selectedThread.length > 0) {
                            const query = {
                                id: selectedThread[0].id
                            }
                            newThread = await sdk.threads.find(query)
                        } else {
                            const recipient = new PublicKey(data.buyer) // Make this arbitrary
                            const command = {
                              encrypted: false,
                              me: {
                                scopes: [ThreadMemberScope.ADMIN, ThreadMemberScope.WRITE],
                              },
                              otherMembers: [
                                {
                                  publicKey: recipient,
                                  scopes: [ThreadMemberScope.ADMIN, ThreadMemberScope.WRITE],
                                },
                              ],
                            };
                            // Uses the default backend.DialectCloud offchain. The command above may optionally take a preferred backend.
                            newThread = await sdk.threads.create(command);
                        }
                        setThread(newThread)
                        const newMessages = await newThread.messages();
                        console.log(newMessages)
                        setMessages(newMessages.reverse());
                        setConnected(true);
                    } catch (error) {
                        console.log(error);
                    }
                    setIsLoading(false);
                }
            )();
        }
    }, [wallet, data, showChatHistory])

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [messages]);

    const convertDate = (date) => {
        return date.toLocaleDateString() + " " + date.toLocaleTimeString()
    }

    return (
        <div className="w-full lg:w-5/12 right-4 pl-0 lg:pl-16 ">
                <div className="flex justify-between items-start mb-6">
                    <div className="flex flex-col">
                        <div className="mb-2 flex items-center">{data.buyer.slice(0, 7)}...{data.buyer.substring(data.buyer.length - 6)} {data.verified ? <HiBadgeCheck color="#68CEC7" className="ml-2" /> : ""}</div>
                        <div className="flex items-center">
                            <div className={`w-2 h-2 rounded-full ${data.online ? "green-bg" : "bg-red-500"} mr-2`}></div>
                            <div className="text-xs">{data.online ? "Online" : "Offline"}</div>
                        </div>
                    </div>
                    <div className="mb-2 flex">
                        <div className="flex items-center mr-4"><RiThumbUpLine color="#68CEC7" className="mr-2" />{data.thumbsUp}</div>
                        <div className="flex items-center"><RiThumbDownLine color="#E03B3B" className="mr-2" />{data.thumbsDown}</div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex justify-between p-4 rounded-lg bg-first-theme border-b-2 green-border mb-2">
                        <div className="flex items-center">
                            <div className="inline-flex relative items-center text-2xl text-center green-font mr-8">
                                <BsChatLeftText />
                                <div className="inline-flex absolute -top-1 -right-1 justify-center items-center w-3 h-3 text-xs font-bold text-white bg-red-500 rounded-full"></div>
                            </div>
                            <div className="">{data.buyer.slice(0, 7)}...{data.buyer.substring(data.buyer.length - 6)}</div>
                        </div>
                        <button className="flex items-center green-font " onClick={() => setShowChatHistory(!showChatHistory)}>
                            <span className="hidden xl:block mr-8 text-white">Click to chat</span>
                            <FiArrowDownCircle size={24} />
                        </button>
                    </div>
                    <div className={`p-4 bg-first-theme rounded-lg ${showChatHistory ? "block" : "hidden"}`}>
                        <div className="flex flex-col h-auto md:h-80 overflow-auto-chat">
                            {
                                isLoading
                                    ? <div className="p-4 w-full flex items-center justify-center ">
                                        <FadeLoader className="" loading color='white' />
                                    </div>
                                    : messages.length === 0
                                        ? <div className="">There is no message.</div>
                                        : messages.map((message, index) => 
                                            message.author.publicKey.toString() === data.buyer
                                                ? <div key={index} className="flex items-start text-left mb-2 ">
                                                    <div className="flex flex-col px-4 py-2 border-2 border-gray-500 rounded-lg max-w-chat">
                                                        <p dangerouslySetInnerHTML={{__html: message.text.replace("\n", "<br />")}} />
                                                        <span className="text-right text-xs">{convertDate(message.timestamp)}</span>
                                                    </div>
                                                </div>
                                                : <div key={index} className="flex justify-end text-black text-right mb-2">
                                                    <div className="flex flex-col px-4 py-2 bg-green-400 rounded-lg max-w-chat">
                                                        <p className="mb-1" dangerouslySetInnerHTML={{__html: message.text.replace("\n", "<br />")}} />
                                                        <span className="text-right text-xs">{convertDate(message.timestamp)}</span>
                                                    </div>
                                                </div>
                                        )
                            }
                            <div ref={bottomRef} />
                        </div>
                        <div className="relative my-8">
                            <textarea 
                                className="w-full p-4 pr-16 border-2 border-first-theme bg-black rounded-lg" 
                                rows={1}
                                value={text}
                                onChange={e => setText(e.target.value)}
                                onKeyUp={handleKey}
                                placeholder="Write a message..."/>
                            <button 
                                className="absolute right-4 green-bg p-2 rounded-full -top-1 ver-middle-img text-2xl"
                                onClick={handleSend}>
                                <AiOutlineSend />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default ChatContainer;