import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsThreeDotsVertical } from "react-icons/bs";
import useOffer from "../utils/offers";
import { PAYMENT_OFFER_VALUES } from "../constants/offers";
import CancelOfferModal from "./CancelOfferModal";

const MyOfferTable = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, datas, setOfferList, discontinueOffer } = props;
    const [showModal, setShowModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedAccount, setSelectedAccount] = useState();

    const handleCollapse = (index) => {
        if (index === selectedIndex) {
            setSelectedIndex(-1)
        } else {
            setSelectedIndex(index)
        }
    }

    const handleCancel = (offerAccount) => {
        setSelectedAccount(offerAccount);
        setShowModal(true);
    }

    const cancelOffer = async () => {
        const newMyOfferList = await discontinueOffer(selectedAccount);
        setOfferList(newMyOfferList);
        setShowModal(false);
    }

    return (
        <div className="border-2 rounded-lg border-first-theme mb-16">
            <table className="w-full text-left ">
                <thead >
                    <tr className="font-thin text-sm text-second-theme">
                        <th className="border-b-2 rounded-t-lg border-first-theme py-2 pl-8">{t("Type")}</th>
                        <th className="border-b-2 border-first-theme py-2">{t("Crypto Bought")}</th>
                        <th className="border-b-2 border-first-theme py-2">{t("Rate")}</th>
                        <th className="border-b-2 border-first-theme py-2">{t("Payment Method")}</th>
                        <th className="border-b-2 border-first-theme py-2 pr-8">{t("Limit / Available")}</th>
                        <th className="border-b-2 border-first-theme py-2 pr-8">{t("Status")}</th>
                        <th className="border-b-2 border-first-theme py-2 pr-8">{t("Action")}</th>
                    </tr>
                </thead>
                <tbody>
                {
                    datas.length === 0
                        ? <tr><td colSpan="7"><div className="p-4 w-full flex items-center justify-center">
                            {t("There is no data.")}
                        </div></td></tr>
                        : datas.map((data, index) =>
                        <>
                            <tr key={index}>
                                <td className={`pl-8 py-4`}>
                                    <div className="flex items-start">
                                        <div className="text-xs bg-first-theme rounded-lg green-font px-2 py-1 mr-2">Sell</div>
                                    </div>
                                </td>
                                <td>
                                    {data.tokenName.toUpperCase()} {data.bought.toLocaleString("en-US")}
                                </td>
                                <td>
                                    {data.rate.toLocaleString("en-US")} {data.fiat.toUpperCase()}
                                </td>
                                <td>
                                    <div className="flex flex-wrap">
                                        {
                                            data.paymentOptions.toString().split(",").map((item, index) => 
                                                item * 1 >= 0 && <div key={index} className="text-xs bg-first-theme rounded-lg green-font p-2 mr-2">{PAYMENT_OFFER_VALUES[item * 1].title}</div>
                                            )
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        <div className="flex">
                                            <span className="w-24">{t("Buying")}</span>
                                            <div className="">{data.tokenName.toUpperCase()} {data.tokenAmount.toLocaleString("en-US")}</div>
                                        </div>
                                        <div className="flex ">
                                            <span className="w-24">{t("Limit")}</span>
                                            <div className="text-left">{data.minLimit.toLocaleString("en-US")} - {data.maxLimit.toLocaleString("en-US")}</div>
                                        </div>
                                    </div>
                                </td>
                                <td >
                                    {
                                        data.status
                                            ? <div className="flex items-center"><div className="w-2 h-2 green-bg rounded-full mr-2" />{t("Online")}</div>
                                            : <div className="flex items-center"><div className="w-2 h-2 bg-gray-500 rounded-full mr-2" />{t("Offline")}</div>
                                    }
                                </td>
                                <td className={`pr-8`}>
                                    {
                                        data.status && 
                                            <div className="relative" onClick={() => handleCollapse(index)}>
                                                <BsThreeDotsVertical className="cursor-pointer" />
                                                <div className={`absolute mt-1 right-0 w-max text-white bg-first-theme rounded-lg flex flex-col text-left z-50 ${selectedIndex === index ? "block" : "hidden"}`}>
                                                    <a
                                                        onClick={() => navigate(`/offer/edit/${data.offer}`)} 
                                                        className="px-4 py-4 items-center cursor-pointer hover:green-font"
                                                    >
                                                        {t("Edit Offer")}
                                                    </a>
                                                    <div 
                                                        className="px-4 py-4 items-center cursor-pointer hover:green-font"
                                                        onClick={() => handleCancel(data.offer)}
                                                    >
                                                        {t("Discontinued Offer")}
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className={`pl-8 pb-4 ${index !== (datas.length - 1) && "border-b-2 border-first-theme"}`} colSpan={7}>
                                    <span className="text-sm text-second-theme">created:</span> <span className="text-sm">{data.createdAt}</span>
                                </td>
                            </tr>
                        </>
                    )
                }
                </tbody>
            </table>
            <CancelOfferModal isLoading={isLoading} isShown={showModal} setShown={setShowModal} handleModal={cancelOffer} />
        </div>
    );
}

export default MyOfferTable;