import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreateOfferModal from "../../components/CreateOfferModal";
import GrayButton from "../../components/GrayButton";
import GreenButton from "../../components/GreenButton";
import OfferConfirmModal from "../../components/OfferConfirmModal";
import useOffer from "../../utils/offers";
import useEthOffer from "../../utils/eth/offers";
import { CRYPTO_VALUES, FIAT_VALUES, TIME_VALUES} from "../../constants/offers";
import { ETH_CRYPTO_VALUES } from "../../constants/eth/offers";
import { AppContext } from "../../utils/AppContext";

const TermContainer = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {cryptoType, fiatType, amount, minLimit, maxLimit, payments, time, rate, oldTerms, isUpdate, offerAccount, changeStatus } = props;
    const { chainID } = useContext(AppContext);
    const [isCreateModalShown, setCreateModalShown] = useState(false);
    const [isConfirmModalShown, setConfirmModalShown] = useState(false);
    const [txSuccess, setTxSuccess] = useState(false);
    const [terms, setTerms] = useState(oldTerms);
    const { isLoading, createOffer, updateOffer } = useOffer();
    const { ethIsLoading, createEthOffer, updateEthOffer } = useEthOffer();

    const handleTerms = (e) => {
        setTerms(e.target.value)
    }

    const create = async () => {
        let result = false;
        const offerData = {
            token: !chainID ? CRYPTO_VALUES[cryptoType].value : ETH_CRYPTO_VALUES[chainID][cryptoType].value, 
            fiat: FIAT_VALUES[fiatType].value, 
            amount, 
            minLimit, 
            maxLimit, 
            payments: payments.toString(), 
            time: TIME_VALUES[time].value,
            rate,
            terms: terms ? terms : ""
        }
        if (!chainID) {
            result = await createOffer(offerData);
        } else {
            console.log("eth")
            result = await createEthOffer(offerData)
        }

        if (result) {
            setTxSuccess(true);
            navigate("/offer/my", {replace: true});
        }
    }

    const update = async () => {
        let result = false;
        const offerData = {
            fiat: FIAT_VALUES[fiatType].value, 
            offerAccount,
            amount, 
            minLimit, 
            maxLimit, 
            payments, 
            time: TIME_VALUES[time].value,
            rate,
            terms
        }
        if (!chainID) {
            result = await updateOffer(offerData);
        } else {
            result = await updateEthOffer(offerData);
        }

        if (result) {
            navigate("/offer/my", {replace: true});
            setTxSuccess(true);
        }
    }

    return (
        <div className="mt-12 flex flex-col">
            <div className="text-sm text-second-theme mb-2 text-left">{t("Offer Terms")}</div>
            <textarea onChange={handleTerms} value={terms} className="bg-first-theme p-4 rounded-lg mb-8" rows={5} placeholder="Enter full term of the trade here" />
            <div className="flex justify-end">
                <GrayButton title={t("Cancel")} size="lg" onClick={() => changeStatus(0)} />
                <GreenButton title={t("Post")} size="lg" cssClass="ml-4 px-8" onClick={() => setConfirmModalShown(true)} active />
            </div>
            <CreateOfferModal isShown={isCreateModalShown} setShown={setCreateModalShown} />
            <OfferConfirmModal 
                {...props} 
                cryptoValues={!chainID ? CRYPTO_VALUES : ETH_CRYPTO_VALUES[chainID]}
                isShown={isConfirmModalShown} 
                setShown={setConfirmModalShown} 
                createOffer={isUpdate ? update : create} 
                isLoading={!chainID ? isLoading : ethIsLoading}
                txSuccess={txSuccess} />
        </div>
    );
}

export default TermContainer;