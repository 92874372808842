import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useWallet } from "@solana/wallet-adapter-react";
import { useLocation } from "react-router-dom";
import { FadeLoader } from 'react-spinners';
import useOffer from "../utils/offers";
import useEthOffer from "../utils/eth/offers";
import Navbar from "../components/Navbar";
import BeginContainer from "../containers/OfferSeller/BeginContainer";
import ChatContainer from "../containers/ChatContainer";
import EthChatContainer from "../containers/ETHChatContainer";
import { AppContext } from "../utils/AppContext";

const OfferSellerWrapper = () => {
    const defaultOfferData = {
        buyer: "XXXXXXXX",
        thumbsUp: 0,
        thumbsDown: 0,
        minLimit: 0,
        maxLimit: 0,
        rate: 1,
        timeLimit: 0,
        fiat: "",
        tokenName: "usd",
        offerTerms: "",
        fee: 1,
        paymentOptions: ""
    }
    const { chainState, chainID } = useContext(AppContext);
    const location = useLocation().pathname;
    const offerAccount = location.substring(location.lastIndexOf('/') + 1);
    const { isLoading, getOfferData, getTokenBalance } = useOffer();
    const { ethIsLoading, getEthOfferData, getEthTokenBalance } = useEthOffer();
    const [tokenBalance, setTokenBalance] = useState(0);
    const [offerData, setOfferData] = useState(defaultOfferData);
    const wallet = useWallet();

    useEffect(() => {
        (async () => {
            let newOfferData
            if (!chainID) {
                newOfferData = await getOfferData(offerAccount);
            } else {
                newOfferData = await getEthOfferData(offerAccount);
            }
            console.log(newOfferData);
            setOfferData(newOfferData);
        })()
    }, [chainID]);

    useEffect(() => {
        (
            async () => {
                if ((wallet.publicKey || chainState.address) && offerData.maxLimit) {
                    let newTokenBalance = 0;
                    if (!chainID) {
                        newTokenBalance = await getTokenBalance(offerData.main ? "sol" : offerData.token);
                    } else {
                        newTokenBalance = await getEthTokenBalance(offerData.main ? "eth" : offerData.token_address);
                    }
                    setTokenBalance(newTokenBalance);
                }
            }
        )();
    }, [chainState.address, offerData, chainID])

    return (
        <OfferSeller
            chainID={chainID}
            isLoading={!chainID ? isLoading : ethIsLoading}
            walletAddress = {!chainID ? wallet.publicKey : chainState.address }
            offerData={offerData}
            offerAccount={offerAccount}
            tokenBalance={tokenBalance} />
    )
}

const OfferSeller = ({chainID, offerAccount, offerData, tokenBalance, isLoading, walletAddress}) => {
    const { t } = useTranslation();

    useEffect(() => {
        console.log(localStorage.getItem("connected") )
        if (localStorage.getItem("connected") === "false") {
            window.location.href = "/offers";
        }
    }, [localStorage.getItem("connected")])

    return (
        <>
            <Navbar />
            <div className="px-2 md:px-24 lg:px-38 xl:px-48 2xl:px-58 flex-1 flex flex-col md:flex-row text-white mt-12">
                {
                    walletAddress
                        ? <>
                            <div className="flex-1 relative">
                                {
                                    isLoading && 
                                        <div className="absolute w-full h-full -top-4 flex items-center justify-center bg-opacity-40 bg-gray-400 rounded-lg">
                                            <FadeLoader loading color='white' />
                                        </div>
                                }
                                <BeginContainer offerData={offerData} offerAccount={offerAccount} tokenBalance={tokenBalance} />
                            </div>
                            {
                                !chainID
                                    ? <ChatContainer data={offerData} />
                                    : <EthChatContainer data={offerData} />
                            }
                        </>
                        : <div className="w-full flex flex-col">
                            <div className="py-8 border-2 border-first-theme rounded-lg text-xl">{t("Please connect your wallet")}</div>
                        </div>
                }
            </div>
        </>
    );
}

export default OfferSellerWrapper;