import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiFillCheckCircle } from "react-icons/ai";
import { BsChevronCompactRight } from "react-icons/bs";
import GreenButton from "../../../components/GreenButton";
import { PAYMENT_OFFER_VALUES } from "../../../constants/offers";
import CancelOrderModal from "../../../components/CancelOrderModal";

const PaymentContainer = (props) => {
    const { orderData, nextStep, period, isLoading, handleCancelOrder, remainTime } = props;
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showCancelBtn, setShowCancelBtn] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        console.log(remainTime)
        if (remainTime < 0 && !orderData.buyerConfirm) {
            console.log(remainTime)
            setShowCancelBtn(true);
            setShowCancelModal(true);
        }
    }, [remainTime, orderData])

    return (
        <>
            <div className="flex flex-col mb-10">
                <div className="flex justify-between">
                    <a href="/orders" className="text-2xl flex items-center mb-2"><AiOutlineArrowLeft className="mr-4"/>{t("Sell")} {orderData.tokenName.toUpperCase()}</a>
                    {
                        orderData.buyerConfirm
                            ? <div className="p-2 rounded-lg bg-first-theme green-font flex items-center"><AiFillCheckCircle className="mr-2" />{t("Paid")}</div>
                            : <div className="text-xl flex items-center mb-2">
                                <div className="px-2 py-1 bg-first-theme rounded-lg mr-1">{period[0]}</div>
                                <div className="px-2 py-1 bg-first-theme rounded-lg mr-2">{period[1]}</div>
                                <div className="mr-2">:</div>
                                <div className="px-2 py-1 bg-first-theme rounded-lg mr-1">{period[2]}</div>
                                <div className="px-2 py-1 bg-first-theme rounded-lg">{period[3]}</div>
                            </div>
                    }
                </div>
                <div className="flex justify-between">
                    <div className="text-sm text-second-theme">{t("Wait for buyer confirmation")}</div>
                    {
                        orderData.buyerConfirm
                            ? <div className="text-sm text-second-theme"></div>
                            : <div className="text-sm text-second-theme">{t("Payment wait time")}</div>
                    }
                </div>
            </div>
            <div className="flex items-center mb-8 md:mb-16 text-second-theme bg-first-theme rounded-tr-lg rounded-tl-lg">
                <div className="flex-1 border-b-2 green-border py-2 text-white">{t("Waiting for payment")}</div>
                <BsChevronCompactRight size={24} />
                <div className="flex-1 border-b-2 border-gray-500 py-2">{t("Release crypto")}</div>
                <BsChevronCompactRight size={24} />
                <div className="flex-1 border-b-2 border-gray-500 py-2">{t("Completed")}</div>
            </div>
            <div className="mb-8">
                <div className="text-left mb-4 md:mb-8">{t("Trade Information")}</div>
                <div className="flex flex-wrap justify-between text-left py-4 md:py-8 border-t-2 border-b-2 border-first-theme mb-8">
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Amount to receive")}</div>
                        <div className="">{orderData.fiatAmount.toLocaleString("en-US")}</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Rate")}</div>
                        <div className="">{orderData.rate.toLocaleString("en-US")} {orderData.fiat.toUpperCase()}</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Transaction fee %")}</div>
                        <div className="">{orderData.fee}%</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Transaction fee amount")}</div>
                        <div className="">{orderData.moneyFee} {orderData.tokenName.toUpperCase()}</div>
                    </div>
                    <div className="mb-2 mr-2">
                        <div className="text-sm text-second-theme">{t("Crypto to send")}</div>
                        <div className="">{orderData.sellAmount.toLocaleString("en-US")} {orderData.tokenName.toUpperCase()}</div>
                    </div>
                </div>
            </div>
            <div className="text-left mb-8">
                <div className="mb-2">{t("Your Payment Information")}</div>
                <div className="text-sm text-second-theme mb-4">{t("This is your selected payment method")}</div>
                <div className="p-4 rounded-lg bg-first-theme flex flex-wrap mb-4">
                    <div className="text-sm w-6/12 py-2">
                        <div className="text-second-theme mb-2">Seller's {PAYMENT_OFFER_VALUES[orderData.paymentOption].title} Email</div>
                        <div className="">{orderData.emailAddress}</div>
                    </div>
                    <div className="text-sm w-6/12 py-2">
                        <div className="text-second-theme mb-2">{t("Account Name")}</div>
                        <div className="">{orderData.accountName}</div>
                    </div>
                    <div className="text-sm w-6/12 py-2">
                        <div className="text-second-theme mb-2">{t("Reference Message")}</div>
                        <div className="">{orderData.fullId}</div>
                    </div>
                </div>
                {
                    orderData.buyerConfirm 
                        ? <div className="text-sm text-second-theme">{t("Payment has been completed by the buyer. Please confirm you have received funds before releasing crypto to the buyer.")}</div>
                        : <div className="text-sm text-second-theme">{t("You will be notified once buyer have sent the payment. Release Crypto button will be activated once buyer confirms payments")}</div>
                        
                }
            </div>
            <div className="flex mb-8">
                <GreenButton cssClass="px-8 mr-4" title={t("Release Crypto")} active={orderData.buyerConfirm} onClick={() => nextStep(true)} />
                {
                    showCancelBtn &&
                        <GreenButton cssClass="px-8" title={t("Cancel Order")} active onClick={() => setShowCancelModal(true)} />
                }
            </div>
            <CancelOrderModal 
                isShown={showCancelModal}
                setShown={setShowCancelModal}
                handleModal={handleCancelOrder}
                isLoading={isLoading}
            />
        </>
    );
}

export default PaymentContainer;